import styled from 'styled-components';

import { wtwLightGrey, wtwBlack } from '../styles';
import loginImage from '../assets/Cityscape_Large.jpg';

export const StyledDiv = styled.div`
  @media (max-width: 767px) {
    position: relative;
    background-color: ${wtwLightGrey};
  };
  @media (min-width: 768px) {
    position: relative;
    height: 360px;
    top: 0;
    left: 0;
    margin-top: 70px;
    margin-bottom: 70px;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url(${loginImage});
  }
`;

export const StyledMobileBannerDiv = styled.div`
  height: 125px;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(${loginImage});
  @media (min-width: 768px) {
    display: none;
  }
`;

export const StyledTitleDiv = styled.div`
  padding-top: 5px;
  padding-bottom: 5px;
  font-weight: bold;
  color: ${wtwBlack};
  font-size: 26;
  @media (min-width: 336px) {
    font-size: 28px;
  };
  @media (min-width: 768px) {
    position: absolute;
    bottom: 20px;
    width: 180px;
    font-size: 20px;
    background-color: black;
    color: white;
    padding: 20px;
    font-weight: normal;
  };
  @media (min-width: 1024px) {
    font-size: 34px;
    width: 340px;
  }
`;

export const StyledFormTitle = styled.div`
  font-size: 22px;
  font-weight: bold;
  margin-top: 25px;
  margin-bottom: 30px;
`;

export const StyledForm = styled.form`
  width: 100%;
  @media (min-width: 768px) {
    padding-left: 30px;
    padding-right: 30px;
    position: relative;
    top: -40px;
    background-color: ${wtwLightGrey};
    box-sizing: border-box;
    min-height: 440px;
    width: ${props => props.type === 'password-reset' ? '460px' : '380px'};
    float: right;
  };
  @media (max-width: 336px) {
    padding-left: 0;
    padding-right: 0;
  };
`;

export const StyledLoginButtonDiv = styled.div`
  text-align: right;
`;

export const StyledLink = styled.a`
  color: ${wtwBlack};
`;

export const StyledLinkContainerDiv = styled.div`
  font-size: 11;
  color: ${wtwBlack};
  text-align: right;
  float: right;
  margin-top: ${props => props.type === 'forgot-password' ? '5px' : '30px'};
  @media (min-width: 375px) {
    font-size: small;
  };
`;

export const StyledFetchErrorNotificationDiv = styled.div`
  padding-top: 15px;
  clear: right;
`;
