import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { LinkButton } from 'es-components';
import { PastCertificationsContext } from './provider/PastCertificationsProvider';

const StyledLinkButton = styled(LinkButton)`
color: var(--wtwViolet);
`;

export default function CertificationDetailLink({certification}) {
  const { goToPastCertDetail } = React.useContext(PastCertificationsContext);
  return (
    <StyledLinkButton onClick={() => goToPastCertDetail(certification.agentCertificationTaskId)}>
      View certification
    </StyledLinkButton>
  );
}

CertificationDetailLink.propTypes = {
  certification: PropTypes.object.isRequired
};
