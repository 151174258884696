import React from 'react';

const useRefreshToDashboard = ({ history, location }) => {
  React.useEffect(
    () => {
      const { state: { fromDashboard } = {} } = location;
      if (!fromDashboard) {
        history.replace('tasks');
      }
    },
    [history, location]
  );
};

export default useRefreshToDashboard;
