import React from 'react';
import { Button, Icon } from 'es-components';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { DashboardContext } from './provider/DashboardProvider';
import dayjs from 'dayjs';
import { vbSoftwareGray6 } from '../styles';

const StyledIcon = styled(Icon)`
  color: ${vbSoftwareGray6};
`;

const StyledTaskRow = styled.div`
  display: flex;
  width: 94%;
  margin: 0 auto;
  padding: 10px 15px;
  justify-content: flex-end;
  align-items: center;
  font-size: 1.1em;
`;

const StyledRowText = styled.div`
  margin-left: 10px;
  flex: 5;
`;

const StyledButton = styled(Button)`
  width: 135px;
`;

const StyledRejectedText = styled.div`
  color: red;
  margin-right: 10px;
  display: flex;
  align-items: center;
`;

const StyledRejectedTextSpan = styled.span`
  text-decoration: underline;
  text-decoration-style: dashed;
`;

export default function CertificationTaskRow({ text, taskItemId, taskStatus, agentCertificationTaskStatusChangedDate }) {
  const { startAction } = React.useContext(DashboardContext);
  return (
    <StyledTaskRow data-testid="task-row">
      <StyledIcon name="certificate" size={34} />
      <StyledRowText>{text}</StyledRowText>
      {taskStatus === 'Rejected' &&
        <>
          <StyledRejectedText>
            <Icon name="exclamation-sign" size={32} />&nbsp;
            <StyledRejectedTextSpan>
              Rejected {dayjs(agentCertificationTaskStatusChangedDate).format('MM/DD/YYYY')}
            </StyledRejectedTextSpan>
          </StyledRejectedText>

          <StyledButton styleType="danger" onClick={() => startAction(taskItemId)}>Retry</StyledButton>
        </>
      }
      {taskStatus === 'Pending' &&
        <StyledButton styleType="success" onClick={() => startAction(taskItemId)}>Start</StyledButton>
      }
    </StyledTaskRow>
  );
}

CertificationTaskRow.propTypes = {
  text: PropTypes.string.isRequired,
  taskItemId: PropTypes.string.isRequired,
  taskStatus: PropTypes.string.isRequired,
  agentCertificationTaskStatusChangedDate: PropTypes.string.isRequired,

};
