import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Modal, SlidingPane, Fieldset } from 'es-components';

import { Button, Notification, TextInput } from '../common';

const UPDATE_CREDENTIALS = 'UPDATE_CREDENTIALS';

const StyledSlidingPane = styled(SlidingPane)`
  width: 600px;
`;

const SlidingPaneContentStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

const FormStyled = styled.div`
  flex-grow: 1;
`;

const StyledModalFooter = styled(Modal.Footer)`
  display: flex;
  align-self: flex-end;
  flex-direction: column;
  position: absolute;
  bottom: 0px;
  right: 0;
  left: 0;
  flex-wrap: wrap;
`;

const StyledModalFooterButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-self: flex-end;
`;

const StyledButton = styled(Button)`
  margin: 1rem 0rem 1rem 1rem;
`;
const FieldSetStyled = styled(Fieldset)`
  max-width: 100%;
`;
const StyledErrorNotificationContainer = styled.div`
  position: relative;
  width: 100%;
  text-align: left;
`;

function AdjustCredentialsSlideout({ adjustCredentialsSlideoutObjs }) {
  const {
    putNewCredentials,
    isPuttingNewCredentials,
    showAdjustCredentialsSlideout,
    closeAdjustCredentialsSlideout,
    agentCertificationIdForAdjustCredential,
    putNewCredentialsStatus,
    certificationDetail,
    dispatch,
    currentUser
  } = adjustCredentialsSlideoutObjs;

  const [username, setUsername] = React.useState(certificationDetail.username);
  const [password, setPassword] = React.useState(certificationDetail.password);
  const [showErrorNotification, setShowErrorNotification] = React.useState(false);

  const title = `Adjust Credentials for ${currentUser.firstName} ${currentUser.lastName}`;
  const resetToDefault = () => {
    setUsername(certificationDetail.username);
    setPassword(certificationDetail.password);
  };
  const closeHandler = () => {
    putNewCredentialsStatus.code = null;
    setShowErrorNotification(false);
    closeAdjustCredentialsSlideout();
    resetToDefault();
  };

  React.useEffect(() => {
    if (!putNewCredentialsStatus.code) return;
    if (putNewCredentialsStatus.code === 200) {
      closeAdjustCredentialsSlideout();
      putNewCredentialsStatus.code = null;

      dispatch({
        type: UPDATE_CREDENTIALS,
        agentCertificationTaskId: agentCertificationIdForAdjustCredential,
        username,
        password
      });

      if (!username) { setUsername(certificationDetail.username); }
      if (!password) { setPassword(certificationDetail.password); }
    } else {
      setShowErrorNotification(true);
    }
  }, [agentCertificationIdForAdjustCredential, closeAdjustCredentialsSlideout, password, putNewCredentialsStatus.code, certificationDetail.username, username, dispatch, certificationDetail.password, putNewCredentialsStatus]);

  const isSaveCredentialsDisabled = (username, password) => {
    const isUsernameChanged = (username !== certificationDetail.username) && username !== '';
    const isPasswordChanged = (password !== certificationDetail.password) && password !== '';
    const isUsernameAndPasswordBlank = username === '' && password === '';
    return isUsernameAndPasswordBlank || (!isUsernameChanged && !isPasswordChanged);
  };

  return (
    <StyledSlidingPane isOpen={showAdjustCredentialsSlideout} onRequestClose={closeHandler}
      title={title}
    >
      <SlidingPaneContentStyled>
        <FormStyled>
          <strong>
            <span>Enter a username and/or password.</span>
          </strong>
          <br />
          <br />
          <FieldSetStyled>
            <TextInput
              label="Username"
              name="userName"
              orientation="stacked"
              type="text"
              onChange={({ target }) => setUsername(target.value)}
              maxLength={300}
              value={username}
              data-testid="username-input"
            />
            <TextInput
              label="Password"
              name="password"
              orientation="stacked"
              type="text"
              onChange={({ target }) => setPassword(target.value)}
              maxLength={300}
              value={password}
              data-testid="password-input"
            />
          </FieldSetStyled>
        </FormStyled>
        <Notification
          header="Notice!"
          message="Saving your credentials here does not change them on the certification website, it only saves them here for reference."
          type="info"
          isLightNotification
        />
        <StyledModalFooter>
          <StyledErrorNotificationContainer>
            {showErrorNotification &&
              <Notification
                header="Sorry, there was an error."
                message="Please try again later."
                type="danger"
                isInline
              />
            }
          </StyledErrorNotificationContainer>
          <StyledModalFooterButtonContainer>
            <StyledButton
              label="Cancel"
              onClick={closeHandler}
              data-testid="cancel-button"
            />
            <StyledButton
              label="Save"
              onClick={() => {
                putNewCredentials({
                  username: username?.trim(),
                  password: password?.trim(),
                  agentCertificationTaskId: agentCertificationIdForAdjustCredential
                });
              }}
              disabled={isSaveCredentialsDisabled(username, password)}
              isSubmitting={isPuttingNewCredentials}
              styleType="primary"
              data-testid="save-button"
            />
          </StyledModalFooterButtonContainer>
        </StyledModalFooter>
      </SlidingPaneContentStyled>
    </StyledSlidingPane>
  );
}

export default AdjustCredentialsSlideout;

AdjustCredentialsSlideout.propTypes = {
  certificationDetail: PropTypes.object,
  adjustCredentialsSlideoutObjs: PropTypes.object,
  dispatch: PropTypes.func
};
