import React from 'react';
import styled from 'styled-components';

import logo from '../assets/logo.png';
import { wtwLightGrey, cpLightGrey } from '../styles';
import Logout from './Logout';

const StyledDiv = styled.div`
  @media (max-width: 767px) {
    padding-top: 7px;
    padding-bottom: 5px;
    background-color: ${wtwLightGrey};
  };
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  height: 80px;
  z-index: 1;
  background-color: var(--cpBackgroundBeige);
  box-shadow: ${props => props.showBorder ? `0px 1px 4px ${cpLightGrey};` : null}
`;

const StyledImage = styled.img`
  max-width: 350px;
  @media (max-width: 767px) {
    height: 10px;
  };
  @media (min-width: 768px) {
    width: 100%;
    height: auto;
    margin-top: 10px;
  }
`;

export default function Header() {
  const [showBorder, setShowBorder] = React.useState(false);

  React.useEffect(() => {
    const handleScroll = () => setShowBorder(window.pageYOffset > 0);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <StyledDiv showBorder={showBorder}>
      <div className="container">
        <StyledImage
          data-testid="logo"
          src={logo}
          alt="Colleague Portal"
          title="Colleague Portal"
        />
        <Logout />
      </div>
    </StyledDiv>
  );
}
