import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './disclosure-questions-styles';

const Header = ({ text }) => <Styled.Header data-testid="header">{text}</Styled.Header>;

Header.propTypes = {
  text: PropTypes.string.isRequired
};

export default Header;
