import React from 'react';
import styled from 'styled-components';
import { HorizontalNav } from 'es-components';

import { FetchIndicator } from '../../common';
import { DashboardContext } from '../provider/DashboardProvider';
import { ContentHeader } from '../../header';
import BackBar from '../BackBar';
import useRefreshToDashboard from '../useRefreshToDashboard';
import { QuestionGrid } from './QuestionsTable';

const StyledDiv = styled.div`
  background-color: var(--wtwWhite);
`;

const StyledHeaderRow = styled.div`
  margin-top: 20px;
  font-weight: bold;
`;

const StyledNavText = styled.div`
  text-align: center;
  padding: 8px 0;
`;

const StyledNavContainer = styled.div`
  li {
    width: 50%;
    padding: 0
  }
  li:hover {
    padding: 0;
  }
`;

const LICENSING_DISCLOSURES = 'licensing-disclosures';
const APPOINTMENT_DISCLOSURES = 'appointment-disclosures';

export default function CurrentYearDisclosuresSummary(props) {
  const [selectedTab, setSelectedTab] = React.useState(LICENSING_DISCLOSURES);
  const { completedDisclosureQuestionObjs, fetchCompletedDisclosureQuestionsStatus } = React.useContext(DashboardContext);
  const { completedLicensingDisclosureQuestions, completedAppointmentDisclosureQuestions } = completedDisclosureQuestionObjs;
  useRefreshToDashboard(props);

  return (
    <StyledDiv className="container">
      <ContentHeader title="Colleague Portal Dashboard" />
      <FetchIndicator
        status={fetchCompletedDisclosureQuestionsStatus}
        render={() =>
          <React.Fragment>
            <BackBar />
            <StyledNavContainer>
              <HorizontalNav useAltStyle selected={selectedTab}>
                <HorizontalNav.Item id={LICENSING_DISCLOSURES} >
                  <StyledNavText onClick={() => setSelectedTab(LICENSING_DISCLOSURES)}>Licensing Disclosures</StyledNavText>
                </HorizontalNav.Item>
                <HorizontalNav.Item id={APPOINTMENT_DISCLOSURES} >
                  <StyledNavText onClick={() => setSelectedTab(APPOINTMENT_DISCLOSURES)}>Appointment Disclosures</StyledNavText>
                </HorizontalNav.Item>
              </HorizontalNav>
            </StyledNavContainer>
            <StyledHeaderRow className="row">
              <div className="col-6">Question</div>
              <div className="col-3">Response</div>
              <div className="col-3">Correction Reason</div>
            </StyledHeaderRow>
            <QuestionGrid disclosureQuestions={selectedTab === LICENSING_DISCLOSURES ? completedLicensingDisclosureQuestions : completedAppointmentDisclosureQuestions} />
          </React.Fragment>
        }
      />
    </StyledDiv>
  );
}
