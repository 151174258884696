import React from 'react';
import styled from 'styled-components';
import { Icon } from 'es-components';

import { Typography } from '../../common';

const StyledIcon = styled(Icon)`
  margin-right: 10px;
`;

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
`;

export default function HeaderSection() {
  return (
    <React.Fragment>
      <div className="row">
        <StyledDiv className="col-12">
          <StyledIcon size={34} name="ok-circle" />
          <div>Everything looks good on our end</div>
        </StyledDiv>
      </div>
      <div className="row">
        <div className="col-12">
          <Typography variant="header" text="Please review your information below." />
        </div>
      </div>
    </React.Fragment>
  );
}
