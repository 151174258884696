export default {
  profileId: null,
  workHistoryId: 0,
  isCurrentEmployer: false,
  employerName: '',
  positionHeld: '',
  startDate: null,
  endDate: null,
  city: '',
  stateCode: '',
  provinceId: '',
  countryId: '',
  phoneNumber: '',
  createdDateTime: new Date(),
  workHistoryTypeId: null,
  panelId: null,  // panelId is needed for es-components drawer
};
