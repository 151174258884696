import React, { useContext } from 'react';
import styled from 'styled-components';

import { FetchIndicator, DateTimeDisplay } from '../../common';
import { DashboardContext } from '../provider/DashboardProvider';
import { ContentHeader } from '../../header';
import { wtwWhite } from '../../styles';
import BackBar from '../BackBar';
import useRefreshToDashboard from '../useRefreshToDashboard';

const adBankerLink = 'https://www.adbanker.com/login.aspx';

const StyledContainer = styled.div`
  background-color: ${wtwWhite};
  margin-bottom: 4em;
`;
const StyledContentHeader = styled(ContentHeader)`
  background-color: #d9e6dc;
`;
const StyledThinPurpleHr = styled.hr`
  border: 1px solid #702082;
  width: 100%;
`;

export default function DisclosureTaskSchedule(props) {
  const { adBankerUserData, fetchADBankerUserData } = useContext(DashboardContext);

  useRefreshToDashboard(props);

  return (
    <StyledContainer className="container">
      <StyledContentHeader title="Credentials & Information" />
      <FetchIndicator
        status={fetchADBankerUserData}
        render={() => {
          if (!adBankerUserData) return null;

          return (
            <React.Fragment>
              <BackBar />
              <div className="row">
                <div className="col-12">
                  <h1>AD Banker Credentials & Information</h1>
                  <StyledThinPurpleHr />
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-4">
                  AD Banker Account Registered:
                </div>
                <div className="col-6">
                  {DateTimeDisplay(adBankerUserData.adBankerRegisterDate)}
                </div>
              </div>
              <div className="row">
                <div className="col-4">
                  AD Banker Username:
                </div>
                <div className="col-6">
                  {adBankerUserData.adBankerUserName}
                </div>
              </div>
              <div className="row">
                <div className="col-4">
                  AD Banker Password:
                </div>
                <div className="col-6">
                  {adBankerUserData.adBankerPassword}
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  To log on to AD Banker or reset your AD Banker password, use the following link:
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <a href={adBankerLink}>{adBankerLink}</a>
                </div>
              </div>
              <br />
            </React.Fragment>
          );
        }}
      />
    </StyledContainer>
  );
}
