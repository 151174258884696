import React from 'react';
import styled from 'styled-components';
import { Icon } from 'es-components';

const StyledTriangleBadge = styled(Icon)`
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: ${props => props.backgroundColor};
  color: ${props => props.textColor};
  text-align: center;
  line-height: 18px;
  background-color: white;
  clip-path: polygon(50% 0, 5% 95%, 95% 95%);
`;

const WarningBadge = () =>
  <StyledTriangleBadge
    name="exclamation-sign"
    textColor="red"
    size={26}
  />;

export { WarningBadge };
