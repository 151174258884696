import { defaultValidationState, updateValidationResult, isValidPassword, isValidConfirmPassword } from '../common/validations';

export default function () {
  const initialValidationResult = {
    password: { ...defaultValidationState },
    confirmPassword: { ...defaultValidationState },
  };

  const update = updateValidationResult(initialValidationResult);

  function setValidation(password) {
    const validate = value => ({
      password: () => {
        const errorMessage = isValidPassword(value, '');
        return update(errorMessage, 'password');
      },
      confirmPassword: () => {
        const errorMessage = isValidConfirmPassword(password, value);
        return update(errorMessage, 'confirmPassword');
      }
    });

    return validate;
  }

  return {
    initialValidationResult,
    validate: null,
    setValidation
  };
}
