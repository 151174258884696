import React from 'react';

const DateTimeDisplay = (datetime) => {
  if (!datetime || datetime === '0001-01-01T00:00:00') {
    return <div />;
  }

  const date = new Date(datetime);
  const options =
  {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
    year: 'numeric'
  };
  const formattedDate = date.toLocaleDateString('en-US', options);

  return <div>{formattedDate}</div>;
};

export default DateTimeDisplay;
