import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Control, AdditionalHelp, Checkbox as ESCheckbox } from 'es-components';

const StyledControl = styled(Control)`
  margin-bottom: 0px;
`;

const Checkbox = ({
  text,
  errorMessage,
  validationState = 'default',
  ...props
}) => {
  return (
    <StyledControl validationState={validationState}>
      <ESCheckbox {...props}>
        {text}
      </ESCheckbox>
      {errorMessage && <AdditionalHelp data-testid="checkbox-additional-help">{errorMessage}</AdditionalHelp>}
    </StyledControl>
  );
};

Checkbox.propTypes = {
  text: PropTypes.string,
  errorMessage: PropTypes.string,
  validationState: PropTypes.oneOf(['default', 'success', 'warning', 'danger']),
};

export default Checkbox;
