import React from 'react';
import TaskRow from './TaskRow';
import ExamInfoTaskRow from './ExamInfoTaskRow';
import CertificationTaskRow from './CertificationTaskRow';
import YearlyDisclosuresTaskRow from './YearlyDisclosuresTaskRow';
import TaskRowInfo from './TaskRowInfo';

const factories = {
  Question: task => <TaskRow key={task.taskItemId} {...task} />,
  Certification: task => <CertificationTaskRow key={task.taskItemId} {...task} />,
  YearlyDisclosures: task => <YearlyDisclosuresTaskRow key={task.taskItemId} {...task} />,
  ExamInfo: task => <ExamInfoTaskRow key={task.taskItemId} {...task} />,
  ScheduleInfo: task => <TaskRowInfo key={task.taskItemId} {...task} />,
};

export default task => factories[task.taskSource](task);
