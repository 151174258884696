import dayjs from 'dayjs';

import {
  defaultSubmissionValidation,
  defaultValidationState,
  updateValidationResult,
  isRequired,
  isValidDate,
  isValidText,
  isValidPhoneNumber
} from '../common/validations';

export default function (agentProfileError = { ...defaultSubmissionValidation.agentProfileError }) {
  const parseFieldError = fieldName => {
    if (Object.hasOwn(agentProfileError.fieldError, fieldName)) {
      return {
        validationState: 'danger',
        errorMessage: agentProfileError.fieldError[fieldName],
      };
    }
    return defaultValidationState;
  };

  let initialValidationResult = {
    isUSCitizen: parseFieldError('isUSCitizen'),
    countryOfCitizenshipId: parseFieldError('countryOfCitizenshipId'),
    dateOfBirth: parseFieldError('dateOfBirth'),
    genderId: parseFieldError('genderId'),
    ethnicityId: parseFieldError('ethnicityId'),
    countryOfBirthId: parseFieldError('countryOfBirthId'),
    stateOfBirth: parseFieldError('stateOfBirth'),
    countyOfBirth: parseFieldError('countyOfBirth'),
    governmentIssuedIdTypeId: parseFieldError('governmentIssuedIdTypeId'),
    governmentIssuedIdCountryId: parseFieldError('governmentIssuedIdCountryId'),
    governmentIssuedIdNumber: parseFieldError('governmentIssuedIdNumber'),
    governmentIssuedIdExpirationDate: parseFieldError('governmentIssuedIdExpirationDate'),
    addressLine1: parseFieldError('addressLine1'),
    city: parseFieldError('city'),
    stateCode: parseFieldError('stateCode'),
    zipCode: parseFieldError('zipCode'),
    mailingAddressLine1: parseFieldError('mailingAddressLine1'),
    mailingCity: parseFieldError('mailingCity'),
    mailingStateCode: parseFieldError('mailingStateCode'),
    mailingZipCode: parseFieldError('mailingZipCode'),
    primaryPhoneNumber: parseFieldError('primaryPhoneNumber')
  };

  const updateValidation = updateValidationResult(initialValidationResult);

  const setValidation = (mailingAddressSameAsHome = false) => {
    const validate = value => ({
      isUSCitizen: () => {
        const errorMessage = isRequired(value)('a citizenship status');
        return updateValidation(errorMessage, 'isUSCitizen');
      },
      countryOfCitizenshipId: () => {
        const errorMessage = isRequired(value)('a country');
        return updateValidation(errorMessage, 'countryOfCitizenshipId');
      },
      dateOfBirth: () => {
        let errorMessage = isValidDate(value, 'date', true);
        const eighteenYearsAgo = new Date(dayjs()
          .subtract(18, 'year')
          .startOf('day'));
        if (value && value > eighteenYearsAgo) errorMessage = 'Date of birth cannot be less than 18 years in the past';
        return updateValidation(errorMessage, 'dateOfBirth');
      },
      genderId: () => {
        const errorMessage = isValidText(value, 'gender');
        return updateValidation(errorMessage, 'genderId');
      },
      ethnicityId: () => {
        const errorMessage = isValidText(value, 'ethnicity');
        return updateValidation(errorMessage, 'ethnicityId');
      },
      countryOfBirthId: () => {
        const errorMessage = isRequired(value)('a country');
        return updateValidation(errorMessage, 'countryOfBirthId');
      },
      stateOfBirth: () => {
        const errorMessage = isValidText(value, 'state');
        return updateValidation(errorMessage, 'stateOfBirth');
      },
      countyOfBirth: () => {
        const errorMessage = isValidText(value, 'county');
        return updateValidation(errorMessage, 'countyOfBirth');
      },
      governmentIssuedIdTypeId: () => {
        const errorMessage = isValidText(value, 'ID type', 20, 'an');
        return updateValidation(errorMessage, 'governmentIssuedIdTypeId');
      },
      governmentIssuedIdCountryId: () => {
        const errorMessage = isValidText(value, 'state');
        return updateValidation(errorMessage, 'governmentIssuedIdCountryId');
      },
      governmentIssuedIdNumber: () => {
        const errorMessage = isValidText(value, 'ID number', 100, 'an');
        return updateValidation(errorMessage, 'governmentIssuedIdNumber');
      },
      governmentIssuedIdExpirationDate: () => {
        const errorMessage = isValidDate(value, 'date', false, true);
        return updateValidation(errorMessage, 'governmentIssuedIdExpirationDate');
      },
      addressLine1: () => {
        const errorMessage = isValidText(value, 'street number');
        return updateValidation(errorMessage, 'addressLine1');
      },
      city: () => {
        const errorMessage = isValidText(value, 'city');
        return updateValidation(errorMessage, 'city');
      },
      stateCode: () => {
        const errorMessage = isValidText(value, 'state');
        return updateValidation(errorMessage, 'stateCode');
      },
      zipCode: () => {
        const errorMessage = isValidText(value, 'zip code');
        return updateValidation(errorMessage, 'zipCode');
      },
      mailingAddressLine1: () => {
        const errorMessage = mailingAddressSameAsHome ? null : isValidText(value, 'street address');
        return updateValidation(errorMessage, 'mailingAddressLine1');
      },
      mailingCity: () => {
        const errorMessage = mailingAddressSameAsHome ? null : isValidText(value, 'city');
        return updateValidation(errorMessage, 'mailingCity');
      },
      mailingStateCode: () => {
        const errorMessage = mailingAddressSameAsHome ? null : isValidText(value, 'state');
        return updateValidation(errorMessage, 'mailingStateCode');
      },
      mailingZipCode: () => {
        const errorMessage = mailingAddressSameAsHome ? null : isValidText(value, 'zip code');
        return updateValidation(errorMessage, 'mailingZipCode');
      },
      primaryPhoneNumber: () => {
        const errorMessage = isValidPhoneNumber(value);
        return updateValidation(errorMessage, 'primaryPhoneNumber');
      },
    });
    return validate;
  };

  return {
    initialValidationResult,
    validate: null,
    setValidation
  };
}
