import React from 'react';
import JwtDecode from 'jwt-decode';

import isUserAuthenticated from './isUserAuthenticated';
import { bearerTokenKey } from './setTokens';
import removeTokens from './removeTokens';
import { useSaveProgressProvider } from '../save-progress';
import { numberOfDashboardTasksKey } from '../dashboard/provider/DashboardProvider';
import { submissionCompleted } from '../onboarding-steps/provider/StepperProvider';

export const CurrentUserContext = React.createContext();

export const initialCurrentUser = {
  profileId: null,
  firstName: null,
  lastName: null,
  onboardingTasks: []
};

export const getCurrentUserFromLocalStorage = (storage = window.localStorage) => {
  if (!isUserAuthenticated()) return null;

  const { profileId, firstName, lastName, isFinishedWithStepper, onboardingTasks } = JwtDecode(storage.getItem(bearerTokenKey));
  return {
    profileId: parseInt(profileId, 10),
    firstName,
    lastName,
    isFinishedWithStepper,
    onboardingTasks: JSON.parse(onboardingTasks)
  };
};

const removeItemFromLocalStorage = (itemKey, storage = window.localStorage) => {
  storage.removeItem(itemKey);
};

export default function CurrentUserProvider(props) {
  const [currentUser, setCurrentUser] = React.useState(initialCurrentUser);
  const { forceSaveProgress, saveProgressFetchStatus, isSavingProgress } = useSaveProgressProvider();

  React.useEffect(
    () => {
      if (saveProgressFetchStatus.code) {
        removeTokens();
        setCurrentUser(initialCurrentUser);
        window.location.href = '/';
      }
    },
    [saveProgressFetchStatus.code]
  );

  const initCurrentUser = React.useCallback(
    () => {
      setCurrentUser(getCurrentUserFromLocalStorage());
    },
    []
  );

  const removeCurrentUser = React.useCallback(
    () => {
      removeItemFromLocalStorage(submissionCompleted);
      removeItemFromLocalStorage(numberOfDashboardTasksKey); 
      forceSaveProgress();     
    },
    [forceSaveProgress]
  );

  const clearExpiredUser = React.useCallback(
    () => {
      removeItemFromLocalStorage(submissionCompleted);
      removeItemFromLocalStorage(numberOfDashboardTasksKey);
      removeTokens();
      setCurrentUser(initialCurrentUser);      
    },
    []
  );

  const value = React.useMemo(
    () => ({
      currentUser,
      setCurrentUser,
      initCurrentUser,
      removeCurrentUser,
      isSavingProgress,
      clearExpiredUser
    }),
    [currentUser, initCurrentUser, isSavingProgress, removeCurrentUser, clearExpiredUser]
  );

  return <CurrentUserContext.Provider value={value} {...props} />;
}
