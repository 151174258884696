import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Icon } from 'es-components';
import wtwTheme from 'es-components-wtw-theme';
import { ExpandableRowsContext } from './Table';
import { wtwViolet } from '../styles';


const StyledLink = styled.a`
  cursor: pointer;
  color: ${wtwViolet}
`;

const StyledNumberOfProductsDiv = styled.div`
  background-color: ${wtwTheme.colors.gray7};
  width: 20px;
  height: 20px;
  border-radius: 100%;
  color: white;
  text-align: center;
  font-weight: bold;
  vertical-align: middle;
  line-height: 1.4;
  font-family: monospace;
  font-size: 14px;
  margin: 0px 0 0 10px;
  display: inline-block;
`;

const StyledIcon = styled(Icon)`
  margin-right: 5px;
  margin-bottom: 3px;
`;

const StyledProductListDiv = styled.div`
  padding: 10px 15px;
`;

export const productRowHeight = 35;

function ProductExpander({ rowData }) {
  const { handleRowExpansion } = React.useContext(ExpandableRowsContext);
  if (rowData.products && rowData.products.length > 1) {
    return (
      <div>
        <StyledLink onClick={() => handleRowExpansion(rowData)}>
          <StyledIcon
            data-testid={`product-expander-${rowData.isRowExpanded ? 'minus' : 'add'}-icon`}
            name={rowData.isRowExpanded ? 'minus' : 'add'}
            size={18}
          />
          <span>Products</span>
        </StyledLink>
        <StyledNumberOfProductsDiv>{rowData.products?.length}</StyledNumberOfProductsDiv>

        {rowData.isRowExpanded &&
          <StyledProductListDiv>
            {rowData.products.map(p => <div key={p.productId}>{p.productName}</div>)}
          </StyledProductListDiv>
        }
      </div>
    );
  }
  return <div>{rowData.products ? rowData.products[0]?.productName : ''}</div>;
}

ProductExpander.propTypes = {
  rowData: PropTypes.object.isRequired,
  idKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default ProductExpander;
