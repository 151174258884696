import React from 'react';

import { useFetch } from '../common';

const agentCertificationResource = 'certifications';

export default function useAdjustCredentialsSlideout(selectedCertDetail, dispatch, currentUser) {
  const {
    request: { put: putNewCredentials },
    fetchStatus: putNewCredentialsStatus
  } = useFetch('put', `${agentCertificationResource}/adjust-credentials`, null, false, true);

  const [showAdjustCredentialsSlideout, setShowAdjustCredentialsSlideout] = React.useState(false);

  const [agentCertificationIdForAdjustCredential, setAgentCertificationIdForAdjustCredentials] =
    React.useState(null);

  const closeAdjustCredentialsSlideout = React.useCallback(() => {
    setAgentCertificationIdForAdjustCredentials(null);
    setShowAdjustCredentialsSlideout(false);
  }, []);

  const openAdjustCredentialsSlideout = (agentCertificationId) => {
    setAgentCertificationIdForAdjustCredentials(agentCertificationId);
    setShowAdjustCredentialsSlideout(true);
  };

  const adjustCredentialsSlideoutObjs = {
    putNewCredentials,
    putNewCredentialsStatus,
    showAdjustCredentialsSlideout,
    agentCertificationIdForAdjustCredential,
    closeAdjustCredentialsSlideout,
    setShowAdjustCredentialsSlideout,
    openAdjustCredentialsSlideout,
    certificationDetail: selectedCertDetail,
    dispatch,
    currentUser
  };

  return { adjustCredentialsSlideoutObjs };
}
