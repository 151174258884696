import React from 'react';

import { AgentProfileContext } from '../provider/AgentProfileProvider';
import { StyledHorizontalLine } from '../../styles';
import { Typography, TextInput, DateInput, RadioGroup } from '../../common';

export default React.memo(function GovernmentIssuedID() {
  const {
    state,
    governmentIssuedIdTypes,
    governmentIssuedIdNumberLabel,
    validationResult,
    handleChange,
    handleBlur
  } = React.useContext(AgentProfileContext);

  if (state.isUSCitizen) return null;

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-12">
          <StyledHorizontalLine />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <Typography variant="sectionHeader" text="Government issued ID" />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <RadioGroup
            name="governmentIssuedIdTypeId"
            data={governmentIssuedIdTypes}
            dataTextField="governmentIssuedIdTypeName"
            dataValueField="governmentIssuedIdTypeId"
            onChange={handleChange}
            onBlur={handleBlur}
            selectedValue={parseInt(state.governmentIssuedIdTypeId, 10)}
            validationState={validationResult.governmentIssuedIdTypeId.validationState}
            errorMessage={validationResult.governmentIssuedIdTypeId.errorMessage}
          />
        </div>
      </div>
      {state.governmentIssuedIdTypeId &&
        <React.Fragment>
          <div className="row">
            <div className="col-5">
              <TextInput
                data-testid="government-issued-id-number-input"
                name="governmentIssuedIdNumber"
                label={governmentIssuedIdNumberLabel}
                value={state.governmentIssuedIdNumber}
                onChange={handleChange}
                onBlur={handleBlur}
                validationState={validationResult.governmentIssuedIdNumber.validationState}
                errorMessage={validationResult.governmentIssuedIdNumber.errorMessage}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <DateInput
                data-testid="government-issued-id-expiration-date-input"
                showYearDropdown
                name="governmentIssuedIdExpirationDate"
                label="Expiration Date"
                defaultValue={state.governmentIssuedIdExpirationDate && new Date(state.governmentIssuedIdExpirationDate)}
                onChange={handleChange}
                onBlur={handleBlur}
                validationState={validationResult.governmentIssuedIdExpirationDate.validationState}
                errorMessage={validationResult.governmentIssuedIdExpirationDate.errorMessage}
              />
            </div>
          </div>
        </React.Fragment>
      }
    </React.Fragment>
  );
});
