import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import wtwTheme from 'es-components-wtw-theme';
import { Heading as ESHeading } from 'es-components';

const StyledHeading = styled(ESHeading)`
  margin-top: 0px;
  margin-bottom: 10px;
  font-weight: normal;
  color: ${wtwTheme.colors.gray7};
`;

const levelMap = {
  header: { lvl: 1 },
  sectionHeader: { lvl: 2 },
  description: {
    lvl: 2,
    size: 6
  },
};

const Typography = React.memo(({ variant, text, secondaryText, underlineHex }) => {
  const map = levelMap[variant];
  return (
    <StyledHeading
      level={map.lvl}
      size={map.size || map.lvl + 3}
      underlineColor={underlineHex}
    >
      {text}{secondaryText && <small> {secondaryText}</small>}
    </StyledHeading>
  );
});

Typography.propTypes = {
  variant: PropTypes.oneOf(['header', 'description', 'sectionHeader']),
  text: PropTypes.string.isRequired,
  secondaryText: PropTypes.string,
  underlineHex: PropTypes.string
};

export default Typography;
