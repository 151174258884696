import React from 'react';
import PropTypes from 'prop-types';

import useAuthTimeout from './useAuthTimeout';

export default function RenderWithAuthTimeout({ history, render }) {
  const authTimeout = useAuthTimeout(history);
  return (
    <React.Fragment>
      {render()}
      {authTimeout()}
    </React.Fragment>
  );
}

RenderWithAuthTimeout.propTypes = {
  history: PropTypes.object.isRequired,
  render: PropTypes.func.isRequired,
};
