/**
 * Converts an object to option shape for dropdown
 * @param {String} textProp Name of the text property
 * @param {String} valueProp Name of the value property
 */
const toOptionShape = (textProp, valueProp) => data => ({
  text: data[textProp],
  value: data[valueProp]
});

export default toOptionShape;
