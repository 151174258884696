import React from 'react';
import fetch from 'isomorphic-fetch';

import {apiUrl} from '../common';

const FETCH_INIT = 'FETCH_INIT';
const FETCH_SUCCESS = 'FETCH_SUCCESS';
const FETCH_ERROR = 'FETCH_ERROR';
export const FETCH_RESET = 'FETCH_RESET';

const initializeFetchResult = data => ({
  data,
  isFetching: false,    // for get requests
  isSubmitting: false,  // for put/post requests
  isError: false,       // can be use to display error messages
  fetchStatus: {
    code: null,
    text: null,
  }
});

const fetchResultReducer = (state, action) => {
  switch (action.type) {
    case FETCH_RESET:
      return {
        ...state,
        ...initializeFetchResult(action.initialData),
      };
    case FETCH_INIT:
      return {
        ...state,
        isFetching: action.isFetching,
        isSubmitting: action.isSubmitting,
        isError: false
      };
    case FETCH_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isFetching: false,
        isSubmitting: false,
        isError: false,
        fetchStatus: {
          code: action.statusCode,
          text: action.statusText,
        }
      };
    case FETCH_ERROR:
      return {
        ...state,
        data: action.payload,
        isFetching: false,
        isSubmitting: false,
        isError: true,
        fetchStatus: {
          code: action.statusCode,
          text: action.statusText,
        }
      };
    default:
      throw new Error();
  }
};

export const addAuthorizationHeader = (storage = window.localStorage) => {
  const token = storage.getItem('bearer-token');
  if (!token) throw new Error('bearer-token not found.');

  return { headers: { Authorization: `Bearer ${token}` } };
};

/**
 * Custom hook to abstract out fetch functionalities.
 * @param {String} initialMethod Http verb to execute.
 * @param {String} initialResource Name of the resource.
 * @param {*} initialData Data to be used in initializing useFetch.
 * @param {Boolean} requiresAuthentication False if the API does not require authentication. Default is true.
 * @param {Boolean} isLazyFetch Lazily call fetch. Default is false which will immediately call the API.
 * @param {Object} initialPayload Payload to be used for submitting a request.
 */
export default function useFetch(initialMethod, initialResource, initialData, requiresAuthentication = true, isLazyFetch = false, initialPayload = null, isFormData = false, baseApiUrl = null) {
  const [fetchConfig, setFetchConfig] = React.useState({
    method: initialMethod,
    resource: initialResource,
    payload: initialPayload,
    requiresAuthentication,
    isLazyFetch,
    isFormData
  });
  const baseUrl = baseApiUrl ? baseApiUrl : apiUrl;
  const [result, dispatch] = React.useReducer(fetchResultReducer, undefined, () => initializeFetchResult(initialData));
  React.useEffect(
    () => {
      const addPayloadAsBody = (isFormData, payload, authHeader) => {
        if (isFormData) {
          return {
            body: payload,
          };
        }
        return ({
          headers: {
            ...authHeader.headers,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(payload)
        });
      };

      const startFetch = async () => {
        try {
          const { method, resource, payload, requiresAuthentication, isFormData } = fetchConfig;
          const isPostOrPut = ['post', 'put'].includes(method.toLowerCase());

          dispatch({
            type: FETCH_INIT,
            isFetching: !isPostOrPut,
            isSubmitting: isPostOrPut,
          });

          const authHeader = requiresAuthentication ? addAuthorizationHeader() : {};
          const config = {
            method,
            ...authHeader,
            ...(isPostOrPut && addPayloadAsBody(isFormData, payload, authHeader))
          };
          const response = await fetch(`${baseUrl}/${resource}`, config);
          const data = response.headers.get('Content-Type') && (response.headers.get('Content-Type').includes('application/json'))
            ? await response.json()
            : await response.text();
          const actionType = (response.status >= 200 && response.status < 300)
            ? FETCH_SUCCESS
            : FETCH_ERROR;
          const statusText = actionType === FETCH_SUCCESS ? response.statusText : data;

          dispatch({
            type: actionType,
            payload: data,
            statusCode: response.status,
            statusText,
          });
        } catch (error) {
          dispatch({
            type: FETCH_ERROR,
            payload: error,
            statusCode: 500,
            statusText: 'server error',
          });
        }
      };

      if (fetchConfig.isLazyFetch) {
        dispatch({
          type: FETCH_SUCCESS,
          payload: initialData,
          statusCode: null,
          statusText: null,
        });
      } else {
        startFetch();
      }
    },
    [baseUrl, fetchConfig, initialData]
  );

  const httpRequest = React.useCallback(
    (resource = initialResource, requestRequiresAuthentication = requiresAuthentication, isFormData = fetchConfig.isFormData) => {
      const updateFetchConfig = (method = initialMethod, query = '') => payload => {
        setFetchConfig({
          method,
          resource: `${resource}${query ? '/' : ''}${query}`,
          payload,
          requiresAuthentication: requestRequiresAuthentication,
          isLazyFetch: false,
          isFormData,
        });
      };
      return {
        get: (query = '') => updateFetchConfig('get', query)(),
        delete: (query = '') => updateFetchConfig('delete', query)(),
        put: payload => updateFetchConfig('put')(payload),
        post: payload => updateFetchConfig('post')(payload),
      };
    },
    [fetchConfig.isFormData, initialMethod, initialResource, requiresAuthentication]
  );

  const request = React.useMemo(
    () => ({ ...httpRequest() }),
    [httpRequest]
  );

  return {
    ...result,
    httpRequest,
    request,
    fetchDispatch: dispatch
  };
}
