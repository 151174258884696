import React from 'react';

/**
 * Custom hook to abstract out form functionalities.
 * @param {Object} initialState Initial state of the form.
 * @param {Function} onSubmit Submit callback.
 * @param {Function} onCancel Cancel callback.
 * @param {Object} validation Validation object that has the following shape: {
        initialValidationResult,
        validate
      }
 * @param {Boolean} shouldValidateOnSubmit Should validate form on submission. Default is true.
 */
export default function useForm(initialState, onSubmit, onCancel, validation, shouldValidateOnSubmit = true) {
  const [state, setState] = React.useState(initialState);
  const [validationResult, setValidationResult] = React.useState(validation.initialValidationResult);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  React.useEffect(
    () => setState(initialState),
    [initialState]
  );

  React.useEffect(
    () => {
      if (isSubmitting) {
        const doesErrorExist = Object.values(validationResult).some(s => s.validationState === 'danger');
        if (!doesErrorExist) onSubmit();
        setIsSubmitting(false);
      }
    },
    [isSubmitting, onSubmit, validationResult]
  );

  const validateAll = () => {
    return Object.keys(validationResult).reduce((acc, key) => {
      const value = state[key];
      const result = validation.validate(value)[key]();
      acc[key] = { ...result[key] };

      return acc;
    }, {});
  };

  const handleChange = event => {
    switch (event.target.type) {
      case 'checkbox': {
        const { name, checked: value } = event.target;
        setState(state => ({
          ...state,
          [name]: value
        }));
        const validationObject = validation.validate(value);
        if (Object.hasOwn(validationObject, name)) {
          setValidationResult(validationObject[name]());
        }
        break;
      }

      case 'select-one':
      case 'radio': {
        const { name, value } = event.target;
        setState(state => ({
          ...state,
          [name]: value
        }));
        const validationObject = validation.validate(value);
        if (Object.hasOwn(validationObject, name)) {
          setValidationResult(validationObject[name]());
        }
        break;
      }

      default: {
        const { name, value } = event.target;
        setState(state => ({
          ...state,
          [name]: value
        }));
        break;
      }
    }
  };

  const handleBlur = ({ target: { name } }) => {
    const value = state[name];
    setValidationResult(validation.validate(value)[name]());
  };

  const handleSubmit = event => {
    if (!onSubmit) return;

    event.preventDefault();
    if (shouldValidateOnSubmit) {
      setIsSubmitting(true);
      setValidationResult(validateAll());
    } else {
      onSubmit();
    }
  };

  const handleCancel = event => {
    if (!onCancel) return;

    event.preventDefault();
    setState(initialState);
    onCancel();
  };

  return {
    state,
    handleChange,
    handleBlur,
    handleSubmit,
    handleCancel,
    validationResult,
  };
}
