import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { StyledFormContainer } from '../styles';
import { useFetch, useForm, FetchIndicator } from '../common';
import validation from './registration-validations';
import RegistrationForm from './RegistrationForm';
import RegistrationSuccess from './RegistrationSuccess';
import RegistrationBanner from './RegistrationBanner';
import RegistrationReview from './RegistrationReview';
import HttpCodes from '../common/HttpCodes';

const initialRegistration = {
  firstName: '',
  middleName: '',
  hasNoMiddleName: false,
  lastName: '',
  emailAddress: '',
  ssn: '',
  password: '',
  confirmPassword: ''
};

const initialFetchStatus = {
  code: null,
  text: null,
};

export default function Registration({ isLazyWorkEmailDomains = false }) {
  const initialWorkEmailDomains = React.useMemo(() => [], []);
  const { data: workEmailDomains, fetchStatus: workEmailFetchStatus, isFetching } = useFetch('get', 'workEmailDomain', initialWorkEmailDomains, false, isLazyWorkEmailDomains);
  const [registrationValidation, setRegistrationValidation] = React.useState(() => validation());
  const [isPendingReview, setIsPendingReview] = React.useState(false);

  const {
    state: registration,
    handleChange,
    handleBlur,
    handleSubmit,
    validationResult,
  } = useForm(initialRegistration, showReview, undefined, registrationValidation);
  const { request, fetchStatus: regFetchStatus, isSubmitting } = useFetch('post', 'registration', null, false, true);
  const [regCreatedStatus, setRegCreatedStatus] = React.useState(initialFetchStatus);

  React.useEffect(
    () => {
      setRegistrationValidation(
        registrationValidation => ({
          ...registrationValidation,
          validate: registrationValidation.setValidation(
            workEmailDomains,
            registration.emailAddress,
            registration.middleName,
            registration.password,
            registration.confirmPassword,
            registration.hasNoMiddleName
          )
        })
      );
    },
    [registration.emailAddress, registration.middleName, registration.password, registration.hasNoMiddleName, registration.confirmPassword, workEmailDomains]
  );

  React.useEffect(
    () => {
      setRegCreatedStatus(regFetchStatus);
    },
    [regFetchStatus]
  );

  function showReview() {
    setRegCreatedStatus(initialFetchStatus);
    setIsPendingReview(true);
  }

  function submit() {
    request.post(registration);
  }

  const isSubmitted = regFetchStatus.code === HttpCodes.Created;

  return (
    <Fragment>
      <RegistrationBanner />
      <StyledFormContainer className="container">
        <form>
          {!isSubmitted && !isPendingReview &&
            <FetchIndicator
              status={[isFetching]}
              render={() =>
                <RegistrationForm
                  registration={registration}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  handleReview={handleSubmit}
                  fetchStatus={workEmailFetchStatus}
                  validationResult={validationResult}
                />
              }
            />
          }
          {!isSubmitted && isPendingReview &&
            <RegistrationReview
              registration={registration}
              handleSubmit={submit}
              fetchStatus={regCreatedStatus}
              isSubmitting={isSubmitting}
              setIsPendingReview={setIsPendingReview}
            />
          }
        </form>
        {isSubmitted && <RegistrationSuccess />}
      </StyledFormContainer>;
    </Fragment >
  );
}

Registration.propTypes = {
  isLazyWorkEmailDomains: PropTypes.bool,
};
