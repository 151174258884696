import React from 'react';

import WorkHistoryProvider from './provider/WorkHistoryProvider';
import Introduction from './Introduction';
import Accordion from './Accordion';

export default function WorkHistory(props) {
  return (
    <WorkHistoryProvider {...props}>
      <Introduction />
      <Accordion />
    </WorkHistoryProvider>
  );
}
