import React from 'react';
import PropTypes from 'prop-types';
import { Control, Label, Textbox, AdditionalHelp } from 'es-components';

export default function TextInput({ label, optionalText, orientation = 'stacked', validationState = 'default', errorMessage, children, ...props }) {
  return (
    <Control orientation={orientation} validationState={validationState}>
      <Label>{label}{optionalText && <small>{optionalText}</small>}</Label>
      <Textbox {...props} />
      {errorMessage && <AdditionalHelp data-testid="textbox-additional-help">{errorMessage}</AdditionalHelp>}
      {children}
    </Control>
  );
}

TextInput.propTypes = {
  label: PropTypes.string.isRequired,
  optionalText: PropTypes.string,
  orientation: PropTypes.string,
  validationState: PropTypes.string,
  errorMessage: PropTypes.string,
  children: PropTypes.node,
};
