import React from 'react';
import PropTypes from 'prop-types';

import { StyledHorizontalLine } from '../styles';
import {
  Typography,
  FetchErrorNotification,
  Button
} from '../common';

const RegistrationReview = props => {
  const data = props.registration;
  const fullName = `${data.firstName} ${data.middleName} ${data.lastName}`;

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-12">
          <Button
            label="Back"
            isOutline
            onClick={() => props.setIsPendingReview(false)}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <Typography variant="header" text="Review your information before submitting." />
        </div>
      </div>
      <div className="row">
        <div className="col-4">
          <Typography variant="sectionHeader" text="Name:" />
        </div>
        <div className="col-8">
          <Typography variant="sectionHeader" text={fullName} />
        </div>
      </div>
      <StyledHorizontalLine />
      <div className="row">
        <div className="col-4">
          <Typography variant="sectionHeader" text="Personal email address:" />
        </div>
        <div className="col-8">
          <Typography variant="sectionHeader" text={data.emailAddress} />
        </div>
      </div>
      <StyledHorizontalLine />
      <div className="row">
        <div className="col-4">
          <Typography variant="sectionHeader" text="Social Security Number:" />
        </div>
        <div className="col-8">
          <Typography variant="sectionHeader" text={data.ssn} />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <FetchErrorNotification status={[props.fetchStatus]} />
        </div>
      </div>
      <div className="row">
        <div className="col-12 right">
          <Button
            data-testid="registration-review-submit-button"
            label="Submit"
            isOutline
            onClick={props.handleSubmit}
            isSubmitting={props.isSubmitting}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

RegistrationReview.propTypes = {
  registration: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  fetchStatus: PropTypes.object,
  isSubmitting: PropTypes.bool.isRequired,
  setIsPendingReview: PropTypes.func.isRequired,
};

export default RegistrationReview;
