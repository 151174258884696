import React from 'react';
import PropTypes from 'prop-types';

import ControlResolver from './ControlResolver';

const SubDescription = props => {
  const { displayNumber, controlTypeName } = props;

  return (
    <div className="row">
      <div className="col-1">
        {displayNumber}
      </div>
      <div className="col-8">
        <ControlResolver type={controlTypeName} {...props} />
      </div>
    </div>
  );
};

SubDescription.propTypes = {
  displayNumber: PropTypes.string,
  controlTypeName: PropTypes.string.isRequired
};

export default SubDescription;
