export default {
  profileId: null,
  registrationId: null,
  countryOfCitizenshipId: '',
  countryOfBirthId: '',
  isUSCitizen: null,
  stateOfBirth: '',
  dateOfBirth: null,
  countyOfBirth: '',
  genderId: '',
  ethnicityId: '',
  addressLine1: '',
  city: '',
  county: '',
  stateCode: '',
  zipCode: '',
  mailingAddressSameAsHome: false,
  mailingAddressLine1: '',
  mailingCity: '',
  mailingCounty: '',
  mailingStateCode: '',
  mailingZipCode: '',
  primaryPhoneNumber: '',
  governmentIssuedIdTypeId: '',
  governmentIssuedIdNumber: '',
  governmentIssuedIdCountryId: '',
  governmentIssuedIdExpirationDate: null,
  isMilitaryActiveService: null,
  isMilitarySpouse: null,
  isMilitaryVeteran: null,
  createdDateTime: new Date()
};
