import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Notification as EsNotification, Message } from 'es-components';

const StyledRootDiv = styled.div`
  margin-top: 30px;
  margin-bottom: 20px;
`;

export default function SuccessNotification({ header, message }) {
  return (
    <EsNotification type="success" includeIcon>
      <Message emphasizedText={header} text="" />
      <StyledRootDiv>{message}</StyledRootDiv>
    </EsNotification>
  );
}

SuccessNotification.propTypes = {
  header: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};
