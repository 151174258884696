import React from 'react';
import PropTypes from 'prop-types';

import useWorkHistoryProvider from '../provider/useWorkHistoryProvider';
import { DELETE_PERIOD, UPDATE_PERIOD } from '../provider/WorkHistoryProvider';
import validation from '../work-history-validations';
import { TYPE_EMPLOYMENT, TYPE_EDUCATION } from '../work-history-constants';
import { Typography, Button, useForm } from '../../common';
import EmploymentEducation from './EmploymentEducation';
import Unemployment from './Unemployment';
import { useStepperProvider } from '../../onboarding-steps';
import useDebounce from '../useDebounce';

const UNITED_STATES_COUNTRY_NAME = 'United States';

export const historyTypeHeader = {
  1: 'Employment period',
  2: 'Education period',
  3: 'Unemployment period'
};

export const WorkHistoryFormContext = React.createContext();

export default function WorkHistoryForm({ initialState }) {
  const { dispatchWorkHistory, setShouldSetDrawerActiveKey, countries, usStates } = useWorkHistoryProvider();
  const { workHistoryError } = useStepperProvider();

  const [workHistoryValidation, setWorkHistoryValidation] = React.useState(
    () => {
      const validationError = workHistoryError.fieldErrors.filter(fs => fs.workHistoryId === initialState.workHistoryId.toString());
      return validation(validationError.length > 0 ? validationError[0] : {});
    }
  );

  const {
    state,
    handleChange,
    handleBlur,
    handleSubmit,
    validationResult
  } = useForm(initialState, undefined, undefined, workHistoryValidation, false);

  const config = React.useMemo(
    () => {
      const isEmployment = initialState.workHistoryTypeId.toString() === TYPE_EMPLOYMENT;
      return {
        isEmployment,
        employerNameLabel: isEmployment ? 'Employer name' : 'Name of educational institution',
        locationTitle: isEmployment
          ? 'Location of employment (if multiple, provide most recent)'
          : 'Location of education (if only online course, list home location)',
        currentlyCheckboxLabel: isEmployment
          ? 'This is my current employer'
          : 'I am currently attending'
      };
    },
    [initialState.workHistoryTypeId]
  );

  const shouldDisplayUsStates = React.useMemo(
    () => {
      if (!countries) return false;
      const { countryName } = countries.find(c => c.countryId === parseInt(state.countryId, 10)) || {};
      return countryName === UNITED_STATES_COUNTRY_NAME;
    },
    [countries, state.countryId]
  );

  const provinces = React.useMemo(
    () => {
      if (!countries) return [];
      const { provinces = [] } = countries.find(c => c.countryId === parseInt(state.countryId, 10)) || {};
      return provinces;
    },
    [countries, state.countryId]
  );

  React.useEffect(
    () => {
      setWorkHistoryValidation(
        workHistoryValidation => ({
          ...workHistoryValidation,
          validate: workHistoryValidation.setValidation(
            config.isEmployment,
            state.isCurrentEmployer,
            state.startDate,
            state.endDate,
            shouldDisplayUsStates,
            provinces
          )
        })
      );
    },
    [config.isEmployment, provinces, shouldDisplayUsStates, state.endDate, state.isCurrentEmployer, state.startDate]
  );

  const debouncedState = useDebounce(state);

  React.useEffect(
    () => {
      dispatchWorkHistory({
        type: UPDATE_PERIOD,
        payload: debouncedState
      });
    },
    [dispatchWorkHistory, debouncedState]
  );

  const handleDeletePeriod = () => {
    setShouldSetDrawerActiveKey(true);
    dispatchWorkHistory({
      type: DELETE_PERIOD,
      payload: state
    });
  };

  const handleFormOnBlur = () => {
    setShouldSetDrawerActiveKey(false);
  };

  const value = React.useMemo(
    () => ({
      state,
      countries,
      usStates,
      handleChange,
      handleBlur,
      handleSubmit,
      validationResult,
      config,
      shouldDisplayUsStates,
      provinces
    }),
    [countries, config, handleBlur, handleChange, handleSubmit, provinces, shouldDisplayUsStates, state, usStates, validationResult]
  );

  return (
    <WorkHistoryFormContext.Provider value={value}>
      <form onBlur={handleFormOnBlur}>
        <div className="row">
          <div className="col-6" >
            <Typography variant="sectionHeader" text={historyTypeHeader[initialState.workHistoryTypeId]} />
          </div>
          {state.panelId > 1 &&
            <div className="col-6 right">
              <Button
                data-testid="delete-period"
                label="Delete this period"
                isOutline
                onClick={handleDeletePeriod}
              />
            </div>
          }
        </div>
        {[TYPE_EMPLOYMENT, TYPE_EDUCATION].includes(initialState.workHistoryTypeId.toString())
          ? <EmploymentEducation />
          : <Unemployment />
        }
      </form>
    </WorkHistoryFormContext.Provider>
  );
}

WorkHistoryForm.propTypes = {
  initialState: PropTypes.object.isRequired
};
