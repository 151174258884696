import React from 'react';
import styled from 'styled-components';

import useWorkHistoryProvider from './provider/useWorkHistoryProvider';
import { Typography, Button } from '../common';

const StyledDiv = styled.div`
  padding-left: 100px;
  padding-right: 100px;
`;

export default function Introduction() {
  const { shouldShowIntroduction, setShouldShowIntroduction } = useWorkHistoryProvider();

  if (!shouldShowIntroduction) return null;
  return (
    <StyledDiv>
      <Typography variant="sectionHeader" text="Step 2" />
      <br />
      <div className="row">
        We're required by law to collect a description of <b>your last five years.</b>
      </div>
      < br />
      <div className="row">
        This includes unemployment, military service, education, self-employment, and/or full/part-time work.
      </div>
      <br />
      <br />
      <div className="row">
        <div className="col-12 right">
          <Button
            data-testid="hide-introduction"
            label="Next"
            isOutline
            onClick={() => setShouldShowIntroduction(false)}
          />
        </div>
      </div>
    </StyledDiv>
  );
}
