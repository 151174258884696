import React from 'react';

import { defaultSubmissionValidation } from '../../common/validations';
import { stepsReducer, INITIALIZE_STEPS, SET_STEP_WARNING_FLAGS } from './stepperReducers';
import { COMPLETE } from '../stepper-constants';
import { CurrentUserContext } from '../../common';

export const StepperContext = React.createContext();
export const submissionCompleted = 'submission-completed';

const StepperProvider = props => {
  const { currentUser } = React.useContext(CurrentUserContext);
  const [steps, dispatchSteps] = React.useReducer(stepsReducer, []);
  const [submissionValidation, setSubmissionValidation] = React.useState(defaultSubmissionValidation);
  const [shouldShowReviewAndSubmitIntroduction, setShouldShowReviewAndSubmitIntroduction] = React.useState(true);
  const [isSubmissionCompleted, setIsSubmissionCompleted] = React.useState(false);

  React.useEffect(
    () => {
      if (currentUser.onboardingTasks.length) {
        const isSubmissionCompleted = currentUser.onboardingTasks.every(d => d.descriptor === COMPLETE);
        setIsSubmissionCompleted(isSubmissionCompleted);

        dispatchSteps({
          type: INITIALIZE_STEPS,
          payload: currentUser.onboardingTasks
        });
      }
    },
    [currentUser.onboardingTasks]
  );

  React.useEffect(
    () => {
      dispatchSteps({
        type: SET_STEP_WARNING_FLAGS,
        payload: submissionValidation.stepperFlags
      });
    },
    [submissionValidation]
  );

  const clearReviewAndSubmitError = React.useCallback(
    type => {
      const stepperWithWarning = submissionValidation.stepperFlags.filter(s => s !== type);

      switch (type) {
        case 'PersonalInfo':
          setSubmissionValidation(value => ({
            ...value,
            agentProfileError: { fieldError: {} },
            stepperFlags: stepperWithWarning
          }));
          break;
        case 'WorkHistory':
          setSubmissionValidation(value => ({
            ...value,
            workHistoryError: {
              formErrors: [],
              fieldErrors: []
            },
            stepperFlags: stepperWithWarning
          }));
          break;
        case 'LicensingDisclosures':
          setSubmissionValidation(value => ({
            ...value,
            licensingDisclosureError: { fieldError: {} },
            stepperFlags: stepperWithWarning
          }));
          break;
        case 'AppointmentDisclosures':
          setSubmissionValidation(value => ({
            ...value,
            appointmentDisclosureError: { fieldError: {} },
            stepperFlags: stepperWithWarning
          }));
          break;
        default:
          break;
      }
    },
    [submissionValidation.stepperFlags]
  );

  const value = React.useMemo(
    () => ({
      steps,
      dispatchSteps,
      agentProfileError: submissionValidation.agentProfileError,
      workHistoryError: submissionValidation.workHistoryError,
      licensingDisclosureError: submissionValidation.licensingDisclosureError,
      appointmentDisclosureError: submissionValidation.appointmentDisclosureError,
      setSubmissionValidation,
      shouldShowReviewAndSubmitIntroduction,
      setShouldShowReviewAndSubmitIntroduction,
      clearReviewAndSubmitError,
      isSubmissionCompleted,
      setIsSubmissionCompleted
    }),
    [clearReviewAndSubmitError, isSubmissionCompleted, shouldShowReviewAndSubmitIntroduction, steps, submissionValidation.appointmentDisclosureError, submissionValidation.licensingDisclosureError, submissionValidation.agentProfileError, submissionValidation.workHistoryError]
  );

  return <StepperContext.Provider value={value} {...props} />;
};

export default StepperProvider;
