import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Control, Label, MaskedTextbox, AdditionalHelp } from 'es-components';

const maskTypes = [
  'date',
  'dollar',
  'phone',
  'ssnum',
  'zip',
  'custom'
];

const StyledLabel = styled(Label)`
  margin-right: 0 !important;
`;

export default function MaskedTextInput({ label, popoverIcon, orientation, validationState, errorMessage, children, ...props }) {
  return (
    <Control orientation={orientation} validationState={validationState}>
      {
        popoverIcon ?
          <div>
            <StyledLabel>{label}</StyledLabel> {popoverIcon}
          </div>
          :
          <Label>{label}</Label>
      }
      <MaskedTextbox {...props} />
      {errorMessage && <AdditionalHelp>{errorMessage}</AdditionalHelp>}
      {children}
    </Control>
  );
}

MaskedTextInput.defaultProps = {
  orientation: 'stacked',
  validationState: 'default',
};

MaskedTextInput.propTypes = {
  label: PropTypes.string.isRequired,
  popoverIcon: PropTypes.element,
  orientation: PropTypes.string,
  validationState: PropTypes.string,
  errorMessage: PropTypes.string,
  maskType: PropTypes.oneOf(maskTypes).isRequired,
  children: PropTypes.node,
};
