import exists from './exists';
import exceeds from './exceeds';
import { passwordRequirements } from './passwordRequirements';

const maxLength = 300;

export const passwordNotValidMessage = 'Please provide a password that meets the guidelines';
export const exceedsPasswordLengthMessage = `Password cannot exceed ${maxLength} characters`;

export default (password, email) => {
  if (!exists(password) ||
    password.toLowerCase().localeCompare(email.toLowerCase()) === 0 ||
    !passwordRequirements.test(password)) {
    return passwordNotValidMessage;
  }

  if (exceeds(password, maxLength)) {
    return exceedsPasswordLengthMessage;
  }

  return null;
};
