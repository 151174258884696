import React from 'react';
import dayjs from 'dayjs';
import AnimateHeight from 'react-animate-height';

import { Button, Notification, CurrentUserContext, bearerTokenKey, refreshTokenKey, useFetch, setTokens, getCurrentUserFromLocalStorage } from '../common';
import useSaveProgressProvider from './provider/useSaveProgressProvider';

const ANIMATE_DURATION = 300;
const countdownStart = dayjs()
  .set('minute', 0)
  .set('second', 0);

const getTokens = (storage = window.localStorage) => ({
  bearerToken: storage.getItem(bearerTokenKey),
  refreshToken: storage.getItem(refreshTokenKey)
});

export default function SaveFinishLaterButton() {
  const { progress, forceSaveProgress, saveProgressFetchStatus, isSavingProgress } = useSaveProgressProvider();
  const [saveFinishLaterFetchStatus, setSaveFinishLaterFetchStatus] = React.useState(null);
  const [shouldCountdown, setShouldCountdown] = React.useState(false);
  const [countdown, setCountdown] = React.useState(countdownStart);
  const countdownIntervalRef = React.useRef();
  const { currentUser, setCurrentUser } = React.useContext(CurrentUserContext);
  const { data: freshToken, request } = useFetch('post', 'authentication/refreshToken', null, true, true);
  const progressResourceRef = React.useRef();

  React.useEffect(
    () => {
      progressResourceRef.current = progress?.resource;
    },
    [progress]
  );

  React.useEffect(
    () => {
      if (saveProgressFetchStatus.code) {
        setCountdown(countdownStart.add(10, 'second'));
        setSaveFinishLaterFetchStatus(saveProgressFetchStatus.code);
        // Refresh token only when a user profile has not been created. This will only happen once.
        if (saveProgressFetchStatus.code === 200 && progressResourceRef.current === 'agentProfiles' && !currentUser.profileId) {
          const tokens = getTokens();
          request.post(tokens);
        }
      } else {
        setSaveFinishLaterFetchStatus(null);
      }
    },
    [currentUser.profileId, request, saveProgressFetchStatus]
  );

  React.useEffect(
    () => {
      if (!freshToken) return;

      setTokens(freshToken);
      const { profileId: profileId } = getCurrentUserFromLocalStorage();
      setCurrentUser(currentUser => ({
        ...currentUser,
        profileId
      }));
    },
    [freshToken, setCurrentUser]
  );

  React.useEffect(
    () => {
      if (shouldCountdown) {
        countdownIntervalRef.current = setInterval(
          () => {
            setCountdown(value => value.subtract(1, 'second'));
          },
          1000
        );
      }

      return () => {
        clearInterval(countdownIntervalRef.current);
      };
    },
    [shouldCountdown]
  );

  React.useEffect(
    () => {
      const secondsLeft = countdown.second();
      if (secondsLeft === 0) {
        setShouldCountdown(false);

        setTimeout(() => {
          setSaveFinishLaterFetchStatus(null);
        }, ANIMATE_DURATION);
      }
    },
    [countdown]
  );

  const notification = React.useMemo(
    () => {
      if (!saveFinishLaterFetchStatus) return null;

      const isSaveSuccess = saveFinishLaterFetchStatus === 200;
      const notificationProps = {
        type: isSaveSuccess ? 'success' : 'danger',
        header: isSaveSuccess ? 'Success!' : 'Sorry.',
        message: isSaveSuccess ? 'You may exit this window or continue working.' : 'Something went wrong and your work was not saved. Try again later.',
      };

      const handleDismiss = () => {
        setShouldCountdown(false);
        setSaveFinishLaterFetchStatus(null);
      };

      setShouldCountdown(true);

      return (
        <React.Fragment>
          <Notification
            isInline
            onDismiss={handleDismiss}
            isDismissable
            {...notificationProps}
          />
        </React.Fragment>
      );
    },
    [saveFinishLaterFetchStatus]
  );

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-12 right">
          <Button
            disabled={!progress}
            label="Save and finish later"
            isOutline
            onClick={forceSaveProgress}
            isSubmitting={isSavingProgress}
          />
        </div>
      </div>
      <AnimateHeight
        duration={ANIMATE_DURATION}
        height={shouldCountdown ? 'auto' : 0}
      >
        <div className="row">
          <div className="col-12">
            {notification}
          </div>
        </div>
      </AnimateHeight>
    </React.Fragment>
  );
}
