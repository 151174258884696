import React from 'react';
import styled from 'styled-components';
import wtwTheme from 'es-components-wtw-theme';
import { Icon } from 'es-components';
import { wtwLightGrey } from '../../styles';

const StyledContainerDiv = styled.div`
  padding-bottom: 30px;
  margin-bottom: 50px;
`;
const StyledMainText = styled.div`
  text-align: center;
  color: ${wtwTheme.colors.primary};
  font-weight: bold;
  font-size: 1.5em;
`;
const StyledSubText = styled.div`
  text-align: center;
  color: #c110a0;
  font-size: 1.2em;
`;
const StyledBigIconDiv = styled.div`
  position: relative;
  text-align: center;
  height: 110px;
  margin-bottom: 20px;
`;
const StyledInboxIcon = styled(Icon)`
  color: ${wtwLightGrey};
`;
const NoTasks = () => (
  <StyledContainerDiv data-testid="no-tasks-available">
    <div className="row">
      <StyledBigIconDiv>
        <StyledInboxIcon name="inbox" size={120} />
      </StyledBigIconDiv>
    </div>
    <div className="row">
      <StyledMainText>Nothing to do right now.</StyledMainText>
      <StyledSubText>Your tasks will appear here as they become available.</StyledSubText>
    </div>
  </StyledContainerDiv>
);
export default NoTasks;
