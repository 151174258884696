import React from 'react';
import PropTypes from 'prop-types';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { useFetch, CurrentUserContext, FETCH_RESET } from '../../common';
import useAdjustCredentialsSlideout from '../useAdjustCredentialsSlideout';
import PastCertificationsProvider from '../../past-certifications/provider/PastCertificationsProvider';

export const DashboardContext = React.createContext();
export const numberOfDashboardTasksKey = 'number-of-dashboard-tasks';

export const COMPLETE_TASK = 'COMPLETE_TASK';
export const UNDO_COMPLETED_TASK = 'UNDO_COMPLETED_TASK';
export const INIT_DASHBOARD_TASKS = 'INIT_DASHBOARD_TASKS';
export const UPDATE_CREDENTIALS = 'UPDATE_CREDENTIALS';
export const ADD_TASKS = 'ADD_TASKS';

const initialData = {
  taskItems: []
};
const initialDisclosureQuestionData = [];

export const dashboardTasksReducer = (state, action) => {
  switch (action.type) {
    case INIT_DASHBOARD_TASKS:
      return [...action.payload];
    case COMPLETE_TASK:
      return [...state.slice(0, action.taskIndex), ...state.slice(action.taskIndex + 1)];
    case UNDO_COMPLETED_TASK:
      return [
        ...state.slice(0, action.taskIndex),
        action.selectedTask,
        ...state.slice(action.taskIndex),
      ];
    case UPDATE_CREDENTIALS: {
      const index = state.findIndex(c => c.agentCertificationTaskId === action.agentCertificationTaskId);

      const updatedTask = {
        ...state[index]
      };

      if (action.username) { updatedTask.username = action.username; }
      if (action.password) { updatedTask.password = action.password; }

      const updatedState = [
        ...state.slice(0, index),
        updatedTask,
        ...state.slice(index + 1),
      ];

      return updatedState;
    }
    case ADD_TASKS: return [...state, ...action.tasks];

    default:
      throw new Error(`No matching reducer for ${action.type}`);
  }
};

const getItemFromStorage = (key, storage = window.localStorage) => storage.getItem(key);
const setItemInStorage = (key, value, storage = window.localStorage) => storage.setItem(key, value);
export default function DashboardProvider(props) {
  const { currentUser } = React.useContext(CurrentUserContext);
  const totalTasksInStorage = getItemFromStorage(numberOfDashboardTasksKey);
  const numberOfTotalTasksInStorage = totalTasksInStorage ? parseInt(totalTasksInStorage, 10) : 0;
  const [selectedTaskItemId, setSelectedTaskItemId] = React.useState(null);
  const [numberOfTotalTasks, setNumberOfTotalTasks] = React.useState(numberOfTotalTasksInStorage);
  const {
    request: requestData,
    data,
    isFetching,
  } = useFetch('get', 'dashboard', initialData, true, true);

  const [dashboardTasks, dispatchDashboardTasks] = React.useReducer(
    dashboardTasksReducer,
    initialData.taskItems
  );
  const { request: requestDisclosureQuestion,
    fetchStatus: requestDisclosurePostStatus,
    fetchDispatch: requestDisclosureDispatch,
  } = useFetch(
    'post',
    'disclosureQuestions/descriptor',
    null,
    true,
    true
  );
  const { request: requestDisclosureQuestionUndo,
    fetchStatus: requestDisclosurePostStatusUndo,
    fetchDispatch: requestDisclosureDispatchUndo,
  } = useFetch(
    'post',
    'disclosureQuestions/descriptor',
    null,
    true,
    true
  );
  const selectedTaskRef = React.useRef({});

  const {
    request: { get: getCompletedLicensingDisclosureQuestions },
    data: completedLicensingDisclosureQuestions,
    isFetching: isFetchingCompletedLicensingDisclosureQuestions,
  } = useFetch('get', 'disclosureQuestions/latest-completed-by-type/licensing', initialDisclosureQuestionData, true, true);
  const {
    request: { get: getCompletedAppointmentDisclosureQuestions },
    data: completedAppointmentDisclosureQuestions,
    isFetching: isFetchingCompletedAppointmentDisclosureQuestions,
  } = useFetch('get', 'disclosureQuestions/latest-completed-by-type/appointment', initialDisclosureQuestionData, true, true);
  const {
    request: { get: getDisclosureQuestionDetail },
    data: disclosureQuestionDetail,
    isFetching: isFetchingDisclosureQuestionDetail,
  } = useFetch('get', 'disclosureQuestions/detail', null, true, true);
  const {
    fetchStatus: vProfileFetchStatus,
    request: { get: getVProfile },
    data: vProfileData,
    isFetching: isFetchingVProfile,
  } = useFetch('get', 'agentProfiles/vProfile', null, true, true);
  const {
    request: { get: getAdBankerUserData },
    data: adBankerUserData,
    isFetching: isFetchingADBankerUserData,
  } = useFetch('get', 'adBankerUsers', null, true, true);

  const { request: { post: dismissExamInfo } } = useFetch(
    'post',
    'examinfos/dismissExamInfo',
    null,
    true,
    true
  );

  const { request: { post: undoDismissExamInfo } } = useFetch(
    'post',
    'examinfos/undoDismissExamInfo',
    null,
    true,
    true
  );

  const completedDisclosureQuestionObjs = React.useMemo(
    () => ({
      completedLicensingDisclosureQuestions,
      completedAppointmentDisclosureQuestions,
    }),
    [completedLicensingDisclosureQuestions, completedAppointmentDisclosureQuestions]
  );

  const handleCompletedYearlyDisclosureTask = React.useCallback(newDisclosureDocumentationTasks => {
    const { index } = selectedTaskRef.current;
    dispatchDashboardTasks({
      type: COMPLETE_TASK,
      taskIndex: index,
    });

    dispatchDashboardTasks({
      type: ADD_TASKS,
      tasks: newDisclosureDocumentationTasks
    });

    const numberOfTasksFromStorage = parseInt(getItemFromStorage(numberOfDashboardTasksKey), 10);
    setItemInStorage(numberOfDashboardTasksKey, numberOfTasksFromStorage + newDisclosureDocumentationTasks.length);
    setNumberOfTotalTasks(numberOfTasksFromStorage + newDisclosureDocumentationTasks.length);
  }, []);

  const dismissExamInfoHandler = React.useCallback(() => {
    const { value, index } = selectedTaskRef.current;
    dismissExamInfo({ examInfoId: value.taskId });
    dispatchDashboardTasks({
      type: COMPLETE_TASK,
      taskIndex: index,
    });
    props.history.push({
      pathname: 'exam-info-dismissed',
      state: {
        fromDashboard: true,
      },
    });
  }, [dismissExamInfo, props.history]);

  const undoDismissExamInfoHandler = React.useCallback(() => {
    const { value, index } = selectedTaskRef.current;
    undoDismissExamInfo({ examInfoId: value.taskId });
    dispatchDashboardTasks({
      type: UNDO_COMPLETED_TASK,
      taskIndex: index,
      selectedTask: value,
    });
    props.history.replace('tasks');
  }, [props.history, undoDismissExamInfo]);

  const [examInfo, setExamInfo] = React.useState();
  const {
    fetchStatus: submitProofFetchStatus,
    request: { post: submitProof },
    isFetching: isSubmittingProof,
  } = useFetch('post', 'certifications/submit-proof', null, true, true, null, true);

  React.useEffect(
    () => {
      if (!currentUser.profileId) return;
      requestData.get(currentUser.profileId);
    }, [currentUser.profileId, requestData]);

  React.useEffect(() => {
    dispatchDashboardTasks({
      type: INIT_DASHBOARD_TASKS,
      payload: data.taskItems,
    });
    const examTask = data.taskItems?.find(task => task.taskSource === 'ExamInfo');
    if (examTask) {
      setExamInfo(examTask);
    }
  }, [data, data.taskItems]);

  React.useEffect(() => {
    if (data.numberOfTotalTasks > 0 && !getItemFromStorage(numberOfDashboardTasksKey)) {
      setItemInStorage(numberOfDashboardTasksKey, data.numberOfTotalTasks);
      setNumberOfTotalTasks(data.numberOfTotalTasks);
    }
  }, [data.numberOfTotalTasks]);

  const taskSourceTaskDetailMapRef = React.useRef({
    Question: {
      route: 'disclosure-question-detail',
      getDetail: getDisclosureQuestionDetail,
    },
    Certification: {
      route: 'certification-task-detail',
      getDetail: () => { }
    },
    YearlyDisclosures: {
      route: 'yearly-disclosures',
      getDetail: () => { }
    },
    ScheduleInfo: {
      route: 'disclosure-task-schedule',
      getDetail: getAdBankerUserData,
    }
  });

  React.useEffect(() => {
    if (submitProofFetchStatus?.code === 200) {
      const index = dashboardTasks.findIndex((s) => s.taskItemId === selectedTaskItemId);
      dispatchDashboardTasks({
        type: COMPLETE_TASK,
        taskIndex: index,
      });
    }
    submitProofFetchStatus.code = null;
  }, [dashboardTasks, selectedTaskItemId, submitProofFetchStatus]);

  const startAction = React.useCallback(
    (taskItemId) => {
      const index = dashboardTasks.findIndex((s) => s.taskItemId === taskItemId);
      selectedTaskRef.current = {
        index,
        value: dashboardTasks[index],
      };
      const taskSource = taskSourceTaskDetailMapRef.current[selectedTaskRef.current.value.taskSource];
      if (taskSource.route === 'disclosure-task-schedule') {
        taskSource.getDetail(currentUser.profileId);
      } else {        
        taskSource.getDetail(selectedTaskRef.current.value.taskId);
      }

      const taskItem = dashboardTasks.find(t => t.taskItemId === taskItemId);
      setSelectedTaskItemId(taskItemId);

      props.history.push({
        pathname: taskSourceTaskDetailMapRef.current[taskItem.taskSource].route,
        state: {
          fromDashboard: true,
        },
      });
    }, [dashboardTasks, props.history, currentUser.profileId]);

  React.useEffect(() => {
    if (requestDisclosurePostStatus && requestDisclosurePostStatus.code === 200) {
      const { index } = selectedTaskRef.current;
      dispatchDashboardTasks({
        type: COMPLETE_TASK,
        taskIndex: index,
      });
      requestDisclosureDispatch({
        type: FETCH_RESET,
      });
      props.history.push({
        pathname: 'action-complete',
        state: {
          fromDashboard: true,
        },
      });
    }
  }, [props.history, requestDisclosureDispatch, requestDisclosurePostStatus, requestDisclosurePostStatus.code]);

  React.useEffect(() => {
    if (requestDisclosurePostStatusUndo && requestDisclosurePostStatusUndo.code === 200) {
      const { index, value } = selectedTaskRef.current;

      dispatchDashboardTasks({
        type: UNDO_COMPLETED_TASK,
        taskIndex: index,
        selectedTask: value
      });

      requestDisclosureDispatchUndo({
        type: FETCH_RESET,
      });

      props.history.replace('tasks');
    }
  }, [props.history, requestDisclosureDispatch, requestDisclosureDispatchUndo, requestDisclosurePostStatus, requestDisclosurePostStatus.code, requestDisclosurePostStatusUndo]);

  const completeTask = React.useCallback(() => {
    const { value } = selectedTaskRef.current;
    requestDisclosureQuestion.post({
      disclosureResponseId: value.taskId,
      descriptor: 'Received',
    });
  }, [requestDisclosureQuestion]);

  const undoCompletedTask = React.useCallback(() => {
    const { value } = selectedTaskRef.current;
    requestDisclosureQuestionUndo.post({
      disclosureResponseId: value.taskId,
      descriptor: 'Not Started',
    });
  }, [requestDisclosureQuestionUndo]);

  const viewDisclosureQuestions = React.useCallback(() => {
    if (!completedLicensingDisclosureQuestions.length) getCompletedLicensingDisclosureQuestions();
    if (!completedAppointmentDisclosureQuestions.length) getCompletedAppointmentDisclosureQuestions();

    props.history.push({
      pathname: 'current-year-disclosures',
      state: {
        fromDashboard: true,
      },
    });
  }, [
    completedLicensingDisclosureQuestions.length,
    getCompletedLicensingDisclosureQuestions,
    completedAppointmentDisclosureQuestions.length,
    getCompletedAppointmentDisclosureQuestions,
    props.history
  ]);

  const viewExamInfo = React.useCallback(taskItemId => {
    const index = dashboardTasks.findIndex((s) => s.taskItemId === taskItemId);
    selectedTaskRef.current = {
      index,
      value: dashboardTasks[index],
    };
    props.history.push({
      pathname: 'exam-info',
      state: {
        fromDashboard: true,
      },
    });
  }, [dashboardTasks, props.history]);

  const viewProfile = React.useCallback(() => {
    props.history.push({
      pathname: 'profile',
      state: {
        fromDashboard: true,
      },
    });
  }, [props.history]);

  const viewPastCertifications = React.useCallback(() => {
    props.history.push({
      pathname: 'all-past-certifications',
      state: {
        fromDashboard: true,
      },
    });
  }, [props.history]);

  const fetchCompletedDisclosureQuestionsStatus = React.useMemo(
    () => [isFetchingCompletedLicensingDisclosureQuestions, isFetchingCompletedAppointmentDisclosureQuestions],
    [isFetchingCompletedLicensingDisclosureQuestions, isFetchingCompletedAppointmentDisclosureQuestions]
  );

  const fetchDisclosureQuestionDetailStatus = React.useMemo(
    () => [isFetchingDisclosureQuestionDetail],
    [isFetchingDisclosureQuestionDetail]
  );

  const fetchADBankerUserData = React.useMemo(
    () => [isFetchingADBankerUserData],
    [isFetchingADBankerUserData]
  );

  const selectedTaskItem = React.useMemo(() => {
    return dashboardTasks.find((t) => t.taskItemId === selectedTaskItemId)
      ? dashboardTasks.find((t) => t.taskItemId === selectedTaskItemId)
      : {};
  }, [dashboardTasks, selectedTaskItemId]);

  const { adjustCredentialsSlideoutObjs } = useAdjustCredentialsSlideout(selectedTaskItem, dispatchDashboardTasks, currentUser);

  const value = React.useMemo(
    () => ({
      isFetching,
      dashboardTasks,
      dispatchDashboardTasks,
      viewDisclosureQuestions,
      viewExamInfo,
      examInfo,
      numberOfTotalTasks,
      numberOfCompletedTasks: numberOfTotalTasks - dashboardTasks.length,
      startAction,
      completeTask,
      undoCompletedTask,
      selectedTask: selectedTaskRef.current,
      disclosureQuestionDetail,
      adBankerUserData,
      fetchDisclosureQuestionDetailStatus,
      fetchADBankerUserData,
      viewProfile,
      vProfileFetchStatus,
      vProfileData,
      isFetchingVProfile,
      getVProfile,
      currentUser,
      viewPastCertifications,
      adjustCredentialsSlideoutObjs,
      dismissExamInfoHandler,
      undoDismissExamInfoHandler,
      selectedTaskItem,
      submitProof,
      isSubmittingProof,
      submitProofFetchStatus,
      requestDisclosurePostStatus,
      requestDisclosurePostStatusUndo,
      completedDisclosureQuestionObjs,
      fetchCompletedDisclosureQuestionsStatus,
      handleCompletedYearlyDisclosureTask
    }),
    [isFetching, dashboardTasks, viewDisclosureQuestions, viewExamInfo, examInfo, numberOfTotalTasks, startAction, completeTask, undoCompletedTask, disclosureQuestionDetail, adBankerUserData, fetchDisclosureQuestionDetailStatus, fetchADBankerUserData, viewProfile, vProfileFetchStatus, vProfileData, isFetchingVProfile, getVProfile, currentUser, viewPastCertifications, adjustCredentialsSlideoutObjs, dismissExamInfoHandler, undoDismissExamInfoHandler, selectedTaskItem, submitProof, isSubmittingProof, submitProofFetchStatus, requestDisclosurePostStatus, requestDisclosurePostStatusUndo, completedDisclosureQuestionObjs, fetchCompletedDisclosureQuestionsStatus, handleCompletedYearlyDisclosureTask]
  );

  return (
    <DndProvider backend={HTML5Backend}>
      <PastCertificationsProvider {...props}>
        <DashboardContext.Provider value={value} {...props} />
      </PastCertificationsProvider>
    </DndProvider>
  );
}

DashboardProvider.propTypes = {
  history: PropTypes.object.isRequired,
};
