import React from 'react';
import PropType from 'prop-types';

import { Button, FetchIndicator, FetchErrorNotification } from '../common';
import useDisclosureQuestionsProvider from './provider/useDisclosureQuestionsProvider';

const DisclosureQuestions = () => {
  const { renderedDisclosureQuestions, setShouldSubmit, isSubmitting, submitStatus, fetchingStatus } = useDisclosureQuestionsProvider();

  return (
    <FetchIndicator
      status={fetchingStatus}
      render={() =>
        <form data-testid="disclosure-questions-section">
          {renderedDisclosureQuestions}
          <div className="row">
            <FetchErrorNotification status={[submitStatus]} />
          </div>
          <div className="row">
            <div className="right">
              <Button
                data-testid="disclosure-questions-submit"
                label="Next"
                isOutline
                onClick={() => setShouldSubmit(true)}
                isSubmitting={isSubmitting}
              />
            </div>
          </div>
        </form>
      }
    />
  );
};

DisclosureQuestions.propTypes = {
  history: PropType.object.isRequired
};

export default DisclosureQuestions;
