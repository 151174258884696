import React from 'react';
import PropTypes from 'prop-types';

import { useFetch } from '../../common';
import useAdjustCredentialsSlideout from '../../dashboard/useAdjustCredentialsSlideout';
import { CurrentUserContext } from '../../common';

export const PastCertificationsContext = React.createContext();

const INIT_PAST_CERTIFICATIONS = 'INIT_PAST_CERTIFICATIONS';
const UPDATE_PAST_CERTIFICATION = 'UPDATE_PAST_CERTIFICATION';
export const UPDATE_CREDENTIALS = 'UPDATE_CREDENTIALS';

const allPastCertificationsReducer = (state, action) => {
  switch (action.type) {
    case INIT_PAST_CERTIFICATIONS:
      return [...action.payload];
    case UPDATE_PAST_CERTIFICATION: {
      const updatedTask = action.updatedTask;
      const index = state.findIndex(i => i.agentCertificationTaskId === updatedTask.agentCertificationTaskId );
      return [
        ...state.slice(0, index),
        updatedTask,
        ...state.slice(index),
      ];
    }
    case UPDATE_CREDENTIALS: {
      const index = state.findIndex(c => c.agentCertificationTaskId === action.agentCertificationTaskId );

      const updatedTask = {
        ...state[index]
      };

      if (action.username) { updatedTask.username = action.username; }
      if (action.password) { updatedTask.password = action.password; }

      const updatedState = [
        ...state.slice(0, index),
        updatedTask,
        ...state.slice(index + 1),
      ];

      return updatedState;
    }
    default:
      throw new Error(`No matching reducer for ${action.type}`);
  }
};

export default function PastCertificationsProvider(props) {
  const { currentUser } = React.useContext(CurrentUserContext);
  const {
    fetchStatus: fetchPastCertificationsStatus,
    request: { get: requestPastCertifications },
    data: pastCertificationsData,
    isFetching: isFetchingPastCertifications,
  } = useFetch(
    'get',
    'Certifications/completed_and_closed_certifications',
    null,
    true,
    true
  );

  const [selectedCertifictionTaskId, setSelectedCertificationTaskId] = React.useState(null);
  const [allPastCertifications, dispatchAllPastCertifications] = React.useReducer(allPastCertificationsReducer, null);

  const goToPastCertDetail = React.useCallback(
    agentCertificationTaskId => {
      setSelectedCertificationTaskId(agentCertificationTaskId);
      props.history.push({
        pathname: 'past-certification-detail',
        state: {
          fromDashboard: true
        },
      });
    }, [props.history]
  );


  React.useEffect(() => {
    if (!fetchPastCertificationsStatus.code) return;
    if(fetchPastCertificationsStatus.code === 200) {
      dispatchAllPastCertifications({
        type: INIT_PAST_CERTIFICATIONS,
        payload: pastCertificationsData
      });
    }
  }, [fetchPastCertificationsStatus.code, pastCertificationsData]);

  const pastCertificationsObjs = React.useMemo(
    () => ({
      fetchPastCertificationsStatus,
      requestPastCertifications,
      allPastCertifications,
      isFetchingPastCertifications,
      dispatchAllPastCertifications
    }), [
      fetchPastCertificationsStatus,
      requestPastCertifications,
      allPastCertifications,
      isFetchingPastCertifications,
      dispatchAllPastCertifications,
    ]);

  const selectedCertDetail = allPastCertifications?.find(c => c.agentCertificationTaskId === selectedCertifictionTaskId);
  const { adjustCredentialsSlideoutObjs: pastCertAdjustCredentialsSlideoutObjs } = useAdjustCredentialsSlideout(selectedCertDetail, dispatchAllPastCertifications, currentUser);
  const value = React.useMemo(
    () => ({
      pastCertificationsObjs,
      pastCertAdjustCredentialsSlideoutObjs,
      selectedCertDetail,
      goToPastCertDetail,
      dispatchAllPastCertifications
    }),
    [goToPastCertDetail, pastCertAdjustCredentialsSlideoutObjs, pastCertificationsObjs, selectedCertDetail]);

  return <PastCertificationsContext.Provider {...props} value={value} />;
}

PastCertificationsProvider.propTypes = {
  history: PropTypes.object.isRequired
};
