import React from 'react';
import PropTypes from 'prop-types';
import { Control, RadioButton, AdditionalHelp, RadioGroup as EsRadioGroup } from 'es-components';

import toOptionShape from './toOptionShape';

const RadioGroup = React.memo(({
  data,
  onChange,
  dataTextField = 'text',
  dataValueField = 'value',
  errorMessage,
  validationState = 'default',
  orientation = 'stacked',
  children,
  ...props
}) => {
  if (!(data || []).length) return null;
  const options = data.map(toOptionShape(dataTextField, dataValueField));

  const handleChange = event => {
    if (!onChange) return;

    const { name, value } = event.target;
    const boolValues = ['true', 'false'];

    if (!boolValues.includes(value)) {
      onChange(event);
      return;
    }

    onChange({
      target: {
        name,
        value: value === 'true'
      }
    });
  };

  return (
    <Control orientation={orientation} validationState={validationState}>
      <EsRadioGroup
        onChange={handleChange}
        {...props}
      >
        {options.map(({ value, text }) =>
          <RadioButton
            data-testid={value}
            key={value}
            value={value}
          >
            {text}
          </RadioButton>
        )}
      </EsRadioGroup>
      {errorMessage && <AdditionalHelp>{errorMessage}</AdditionalHelp>}
      {children}
    </Control>
  );
});

RadioGroup.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func,
  dataTextField: PropTypes.string,
  dataValueField: PropTypes.string,
  errorMessage: PropTypes.string,
  validationState: PropTypes.string,
  orientation: PropTypes.string,
  children: PropTypes.node,
};

export default RadioGroup;
