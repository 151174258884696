import dayjs from 'dayjs';
import exists from './exists';

const PLEASE_PROVIDE_A = 'Please provide a';

export default (date, dateName = 'date', shouldBePast = false, shouldBeFuture = false) => {
  const selectedDate = dayjs(date);
  if (exists(date) && selectedDate.isValid()) {
    const currentDate = dayjs(new Date());

    if (shouldBePast && selectedDate.isAfter(currentDate)) {
      return `${PLEASE_PROVIDE_A} past ${dateName}`;
    }

    if (shouldBeFuture && selectedDate.isBefore(currentDate)) {
      return `${PLEASE_PROVIDE_A} future ${dateName}`;
    }

    return null;
  }

  return `${PLEASE_PROVIDE_A} valid ${dateName}`;
};
