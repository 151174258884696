import React from 'react';
import styled from 'styled-components';

const BannerDiv = styled.div`
  background-size: cover;
  background-repeat: no-repeat;
  height: 250px;
  top: 0;
  left: 0;
  margin-top: 0;
  margin-bottom: 30px;
  background-image: url(assets/Freeway.jpg);
`;

const TitleDiv = styled.div`
  position: relative;
  background-color: black;
  color: white;
  padding: 20px;
  height: 120px;
  font-size: 19px;
  top: 60px;
  @media (min-width: 370px) {
    font-size: 34px;
  }
  @media (min-width: 720px) {
    width: 350px;
  }
`;

const Banner = () =>
  <BannerDiv>
    <div className="container">
      <TitleDiv>Welcome to the Colleague Portal</TitleDiv>
    </div>
  </BannerDiv>;

export default Banner;
