const HttpCodes = Object.freeze({
  OK: 200,
  Created: 201,
  Accepted: 202,

  BadRequest: 400,
  Unauthorized: 401,
  Forbidden: 403,
  NotFound: 404
});


export default HttpCodes;
