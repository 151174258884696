import React from 'react';
import styled from 'styled-components';
import { Modal, ModalButtonContainer } from 'es-components';

import useReviewAndSubmitProvider from '../provider/useReviewAndSubmitProvider';
import { StyledHorizontalLine } from '../../styles';
import { Typography, Button, FetchErrorNotification } from '../../common';

const StyledButton = styled(Button)`
  margin-right: 15px;
`;

export default function SubmitSection() {
  const { submit, shouldConfirmSubmission, setShouldConfirmSubmission, submitStatus, isSubmittingOnboardingData } = useReviewAndSubmitProvider();
  React.useEffect(
    () => {
      if (submitStatus.code === 500) setShouldConfirmSubmission(false);
    },
    [setShouldConfirmSubmission, submitStatus.code]
  );

  return (
    <React.Fragment>
      <StyledHorizontalLine />
      <div className="row">
        <div className="col-8">
          <Typography variant="description" text="If everything is correct, click Submit." />
        </div>
        <div className="col-4 right">
          <Button
            label="Submit"
            isOutline
            onClick={() => setShouldConfirmSubmission(true)}
          />
        </div>
        <FetchErrorNotification status={[submitStatus]} />
      </div>
      <Modal
        size="medium"
        show={shouldConfirmSubmission}
        onHide={() => setShouldConfirmSubmission(false)}
      >
        <Modal.Header>WARNING</Modal.Header>
        <Modal.Body>
          <div>You will not be able to make changes after you submit.</div>
          <br />
          <div>Are you sure you want to submit?</div>
        </Modal.Body>
        <Modal.Footer>
          <ModalButtonContainer>
            <StyledButton
              label="No. Go back"
              isOutline
              onClick={() => setShouldConfirmSubmission(false)}
              disabled={isSubmittingOnboardingData}
            />
            <Button
              label="Yes. Submit"
              isOutline
              onClick={() => submit()}
              isSubmitting={isSubmittingOnboardingData}
            />
          </ModalButtonContainer>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}
