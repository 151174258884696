import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { LightNotification } from 'es-components';

const StyledWarningNotification = styled(LightNotification)`
  font-size: 14px;
  margin-top: 10px;
`;

export const MessageNotification = ({message}) => {
  return (
    <StyledWarningNotification type={'warning'} includeIcon>
      <span>{message}</span>
    </StyledWarningNotification>
  );
};

MessageNotification.propTypes = {
  message: PropTypes.string,
};
