import React from 'react';
import { Button, Icon } from 'es-components';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { DashboardContext } from './provider/DashboardProvider';
import { vbSoftwareGray6 } from '../styles';

const StyledIcon = styled(Icon)`
  color: ${vbSoftwareGray6};
`;

const StyledTaskRow = styled.div`
  display: flex;
  width: 94%;
  margin: 0 auto;
  padding: 10px 15px;
  justify-content: flex-end;
  align-items: center;
  font-size: 1.1em;
`;

const StyledRowText = styled.div`
  margin-left: 10px;
  flex: 5;
`;

const StyledButton = styled(Button)`
  width: 135px;
`;

export default function TaskRowInfo({ text, taskItemId }) {
  const { startAction } = React.useContext(DashboardContext);
  return (
    <StyledTaskRow data-testid="task-row">
      <StyledIcon name="hourglass" size={34} />
      <StyledRowText>{text}</StyledRowText>
      <StyledButton styleType="primary" onClick={() => startAction(taskItemId)}>View</StyledButton>
    </StyledTaskRow>
  );
}

TaskRowInfo.propTypes = {
  text: PropTypes.string.isRequired,
  taskItemId: PropTypes.string.isRequired
};
