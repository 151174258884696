import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';

import {
  AGENT_PROFILE_ROUTE,
  WORK_HISTORY_ROUTE,
  LICENSING_DISCLOSURES_ROUTE,
  APPOINTMENT_DISCLOSURES_ROUTE,
  REVIEW_AND_SUBMIT_ROUTE
} from '../onboarding-steps';
import CanyonImage from '../assets/Canyon.jpg';
import FallRiverImage from '../assets/FallRiver.jpg';
import FieldImage from '../assets/Field.jpg';
import ForestImage from '../assets/Forest.jpg';
import FreewayImage from '../assets/Freeway.jpg';

const StyledImgContainer = styled.div`
  position: relative;
  background-color: var(--wtwWhite);
  height: 138px;
`;

const StyledImg = styled.img`
  width: 100%;
  display: block;
  @media(max-width:800px){
    height:90%;
  }  
`;

const StyledHeadingText = styled.div`
  background-color: black;
  color: white;
  position: absolute;
  top: 14%;
  left: 2%;
  width: 33%;
  padding: 15px 25px;
  vertical-align: middle;
  font-size: 20px;
  @media(max-width:800px){
    font-size: 15px;
  }
`;

const ContentHeader = ({ location, title = 'Colleague Portal', ...props }) => {
  const urlToImageMap = {
    [AGENT_PROFILE_ROUTE]: ForestImage,
    [WORK_HISTORY_ROUTE]: FallRiverImage,
    [LICENSING_DISCLOSURES_ROUTE]: CanyonImage,
    [APPOINTMENT_DISCLOSURES_ROUTE]: FieldImage,
    [REVIEW_AND_SUBMIT_ROUTE]: FreewayImage
  };
  const pathnameArray = location.pathname.split('/');
  const headerImage = urlToImageMap[pathnameArray[pathnameArray.length - 1]] || FreewayImage;

  return (
    <StyledImgContainer {...props}>
      <React.Fragment>
        <StyledHeadingText>
          {title}
        </StyledHeadingText>
        <StyledImg src={headerImage} />
      </React.Fragment>
    </StyledImgContainer>
  );
};

ContentHeader.propTypes = {
  location: PropTypes.object.isRequired,
  title: PropTypes.string
};

export default withRouter(ContentHeader);
