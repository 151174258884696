import React from 'react';
import PropTypes from 'prop-types';
import { Textarea as EsTextarea, Control, Label, AdditionalHelp } from 'es-components';

export default function TextArea({
  label,
  rows = '3',
  isInline = false,
  validationState = 'default',
  errorMessage,
  children,
  ...props
}) {
  const orientation = isInline ? 'inline' : 'stacked';
  return (
    <React.Fragment>
      <Control orientation={orientation} validationState={validationState}>
        <Label htmlFor={orientation}>{label}</Label>
        <EsTextarea
          rows={rows}
          id={orientation}
          {...props}
        />
        {errorMessage && <AdditionalHelp>{errorMessage}</AdditionalHelp>}
        {children}
      </Control>
    </React.Fragment>
  );
}

TextArea.propTypes = {
  label: PropTypes.string.isRequired,
  rows: PropTypes.number,
  isInline: PropTypes.bool,
  validationState: PropTypes.string,
  errorMessage: PropTypes.string,
  children: PropTypes.node,
};
