import React from 'react';
import styled from 'styled-components';
import { Icon } from 'es-components';

import { StyledSectionHeader } from './certification-task-styles';

const StyledContainerDiv = styled.div`
  padding-top 40px;
  padding-bottom: 50px;
  margin-bottom: 50px;
`;

const StyledBoldSpan = styled.span`
  font-size: 1.3em;
  position: relative;
`;

const StyledBigIconDiv = styled.div`
  position: relative;
  text-align: center;
  height: 130px;
  margin-bottom: 20px;
`;

const StyledInboxIcon = styled(Icon)`
  color: green;
`;

export default function CertComplete() {
  return (
    <StyledContainerDiv data-testid="all-tasks-complete">
      <div className="row">
        <StyledBigIconDiv>
          <StyledInboxIcon name="ok-circle" size={150} />
        </StyledBigIconDiv>
      </div>
      <div className="row">
        <div className="col-12 center">
          <StyledSectionHeader>Certification submitted!</StyledSectionHeader>
        </div>
      </div>
      <div className="row">
        <div className="col-12 center">
          <StyledBoldSpan>
            You may return to the dashboard now.
          </StyledBoldSpan>
        </div>
      </div>
    </StyledContainerDiv>
  );
}
