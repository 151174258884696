import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import useOnHover from '../onboarding-steps/useOnHover';

const StyledTooltipAnchorTextDiv = styled.div`
  position: relative;
  display: inline-block;
`;

const StyledTooltipDiv = styled.span`
    position: absolute;
    top: 110%;
    left: 5%;
    margin-left: -5px;
    background-color: #ffffca;
    z-index: 1000;
    padding: 2px;
    border: 1px solid gray;
    box-shadow: 2px 2px 5px gray;
    font-size: .8rem;
    overflow-wrap: anywhere;
    width: fit-content;
`;

const StyledTooltipContainerText = styled.div`
  width: 300px;
  position: relative;
`;

export default function TextWithTooltip({ text, maxCharacterLength = 15 }) {
  const tooltipAnchorTextRef = React.useRef();
  const isHovering = useOnHover(tooltipAnchorTextRef);
  return (
    <StyledTooltipAnchorTextDiv ref={tooltipAnchorTextRef}>
      {
        text?.length > maxCharacterLength ?
          <>
            {`${text.substring(0, maxCharacterLength - 1)}...`}
            {isHovering && <StyledTooltipContainerText><StyledTooltipDiv>{text}</StyledTooltipDiv></StyledTooltipContainerText>}
          </>
          :
          text
      }
    </StyledTooltipAnchorTextDiv>
  );
}

TextWithTooltip.propTypes = {
  text: PropTypes.string.isRequired,
  maxCharacterLength: PropTypes.number
};
