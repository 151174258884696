import React from 'react';
import dayjs from 'dayjs';

import useReviewAndSubmitProvider from '../provider/useReviewAndSubmitProvider';
import { AGENT_PROFILE_ROUTE } from '../../onboarding-steps';
import { StyledHorizontalLine } from '../../styles';
import { Typography, Button } from '../../common';
import Row from './Row';

export const formatDate = date => date && dayjs(date).format('MMMM D, YYYY');

export default function AgentProfileSection() {
  const {
    history,
    agentProfile,
    getCountry,
    isBornInTheUsa,
    governmentIssuedIdNumberLabel,
    governmentIssuedIdType,
    gender,
    ethnicity,
    getUsState
  } = useReviewAndSubmitProvider();

  const usState = getUsState(agentProfile.stateCode);
  return (
    <React.Fragment>
      <StyledHorizontalLine />
      <div className="row">
        <div className="col-6">
          <Typography variant="sectionHeader" text="Personal Info" />
        </div>
        <div className="col-6 right">
          <Button
            label="Edit section"
            isOutline
            onClick={() => history.push(AGENT_PROFILE_ROUTE)}
            data-testid="edit-section"
          />
        </div>
      </div>
      <Row label="Citizenship status:" value={agentProfile.isUSCitizen ? 'I am a United States Citizen' : 'I am NOT a United States Citizen'} />
      {gender && <Row label="Gender:" value={gender.genderName} />}
      {ethnicity && <Row label="Ethnicity:" value={ethnicity.ethnicityName} />}
      <Row label="Date of birth:" value={formatDate(agentProfile.dateOfBirth)} />
      {agentProfile.isUSCitizen &&
        <React.Fragment>
          <Row label="Country of birth:" value={getCountry(agentProfile.countryOfBirthId).countryName} />
          {isBornInTheUsa &&
            <React.Fragment>
              <Row label="State of birth:" value={getUsState(agentProfile.stateOfBirth).stateName} />
              <Row label="County of birth:" value={agentProfile.countyOfBirth} />
            </React.Fragment>
          }
        </React.Fragment>
      }
      {!agentProfile.isUSCitizen && governmentIssuedIdType &&
        <React.Fragment>
          <Row label="Government issued ID:" value={governmentIssuedIdType.governmentIssuedIdTypeName} />
          {agentProfile.governmentIssuedIdTypeId &&
            <React.Fragment>
              <Row label={governmentIssuedIdNumberLabel} value={agentProfile.governmentIssuedIdNumber} />
              <Row label="Expiration Date:" value={formatDate(agentProfile.governmentIssuedIdExpirationDate)} />
            </React.Fragment>
          }
        </React.Fragment>
      }
      {!agentProfile.mailingAddressSameAsHome &&
        <React.Fragment>
          <br />
          <div className="row">
            <div className="col-12">
              <Typography variant="sectionHeader" text="Home address" />
            </div>
          </div>
        </React.Fragment>
      }
      <Row label="Street address:" value={agentProfile.addressLine1} />
      <Row label="City:" value={agentProfile.city} />
      {usState && <Row label="State:" value={usState.stateName} />}
      <Row label="Zip code:" value={agentProfile.zipCode} />
      <Row label="My mailing address is the same as my home address:" value={agentProfile.mailingAddressSameAsHome ? 'Yes' : 'No'} />
      {!agentProfile.mailingAddressSameAsHome &&
        <React.Fragment>
          <br />
          <div className="row">
            <div className="col-12">
              <Typography variant="sectionHeader" text="Mailing address" />
            </div>
          </div>
          <Row label="Street address:" value={agentProfile.mailingAddressLine1} />
          <Row label="City:" value={agentProfile.mailingCity} />
          <Row label="State:" value={getUsState(agentProfile.mailingStateCode).stateName} />
          <Row label="Zip code:" value={agentProfile.mailingZipCode} />
          <br />
        </React.Fragment>
      }
      <Row label="Phone number:" value={agentProfile.primaryPhoneNumber} />
    </React.Fragment>
  );
}
