import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { useStepperProvider } from '../onboarding-steps';
import { Typography, Button } from '../common';

const StyledDiv = styled.div`
  padding-left: 100px;
  padding-right: 100px;
`;

const Introduction = () => {
  const { shouldShowReviewAndSubmitIntroduction, setShouldShowReviewAndSubmitIntroduction } = useStepperProvider();

  if (!shouldShowReviewAndSubmitIntroduction) return null;

  return (
    <StyledDiv data-testid="introduction-div">
      <Typography variant="sectionHeader" text="All done?" />
      <br />
      <div className="row">
        You can double-check each section by clicking the circles above.
      </div>
      < br />
      <div className="row">
        If you think everything is complete, click Next.
      </div>
      <br />
      <br />
      <div className="row">
        <div className="col-12 right">
          <Button
            data-testid="hide-introduction"
            label="Next"
            isOutline
            onClick={() => setShouldShowReviewAndSubmitIntroduction(false)}
          />
        </div>
      </div>
    </StyledDiv>
  );
};

Introduction.propTypes = {
  type: PropTypes.string
};

export default Introduction;
