import React from 'react';
import PropTypes from 'prop-types';

import * as Styled from './disclosure-questions-styles';
import ControlResolver from './ControlResolver';
import { MessageNotification } from './MessageNotification';

const textBox = 'Textbox';
const header = 'Header';
const message = 'Message';
const label = 'Label';

const SubQuestion = ({ children, parentIndex, indexNumber, shouldShowSubQuestion, ...props}) => {  
  const showThirdLevelQuestion = shouldShowSubQuestion(parentIndex, indexNumber);

  const { displayNumber, controlTypeName, requiresResponse, subDescriptionRendering } = props;
  return (
    <Styled.Div className="row" data-testid="sub-question" >      
      {controlTypeName !== message &&
        <>
          {
            controlTypeName !== header && 
              <Styled.DisplayNumber className="col-1" style={{marginLeft: 20}}>
                {displayNumber}
              </Styled.DisplayNumber>
          }
          <div className="col-8">
            <div style={{marginLeft: 20}}>
              <ControlResolver type={requiresResponse ? label : controlTypeName} {...props} />
              {controlTypeName === textBox && <ControlResolver type={controlTypeName} {...props} />}
            </div>
          </div>
          {
            requiresResponse && controlTypeName !== textBox &&
              <Styled.ControlContainer className="col-3">
                <ControlResolver type={controlTypeName} {...props} />
              </Styled.ControlContainer>
          }
          {showThirdLevelQuestion && children}
          {!showThirdLevelQuestion && subDescriptionRendering}
        </>
      }
      {controlTypeName === message &&        
        <div className="row">
          <MessageNotification message={'The contracting team will reach out to you for more information closer to AEP. No additional information is required at this time.'} />
        </div>
      }
    </Styled.Div>
  );
};

SubQuestion.propTypes = {
  children: PropTypes.node,
  subDescriptionRendering: PropTypes.node,
  displayNumber: PropTypes.string,
  controlTypeName: PropTypes.string.isRequired,
  requiresResponse: PropTypes.bool.isRequired,
  sourceName: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  parentIndex: PropTypes.number.isRequired,
  shouldShowSubQuestion: PropTypes.func.isRequired,
  indexNumber: PropTypes.number.isRequired,
};

export default SubQuestion;
