import React from 'react';
import styled from 'styled-components';

import { ContentHeader } from '../header';
import AllTasksComplete from './task-detail/AllTasksComplete';
import NoTasks from './task-detail/NoTasks';
import WelcomeSection from './WelcomeSection';
import ProgressBar from './ProgressBar';
import TasksTable from './TasksTable';
import { DashboardContext } from './provider/DashboardProvider';
import { FetchIndicator, CurrentUserContext } from '../common';
import { StyledGreenHr } from './styles/dashboard-styles';

const StyledContainerDiv = styled.div`
  background-color: var(--wtwWhite);
`;

export default function Dashboard() {
  const { currentUser } = React.useContext(CurrentUserContext);
  const { isFetching, numberOfTotalTasks, numberOfCompletedTasks } =
    React.useContext(DashboardContext);

  const renderContent = () => {
    if (!currentUser.profileId) return null;

    function tasksTableDisplay(numberOfTotalTasks, numberOfCompletedTasks) {
      if (numberOfTotalTasks === 0) return <NoTasks />;
      if (numberOfTotalTasks > 0 && numberOfCompletedTasks < numberOfTotalTasks) {
        return (
          <>
            <ProgressBar
              numberOfTotalTasks={numberOfTotalTasks}
              numberOfCompletedTasks={numberOfCompletedTasks}
            />
            <TasksTable />
          </>
        );
      }
      if (numberOfTotalTasks === numberOfCompletedTasks) {
        return (
          <>
            <ProgressBar
              numberOfTotalTasks={numberOfTotalTasks}
              numberOfCompletedTasks={numberOfCompletedTasks}
            />
            <AllTasksComplete />
          </>
        );
      }
      return <></>;
    }
    return (
      <React.Fragment>
        <WelcomeSection />
        <StyledGreenHr />
        {tasksTableDisplay(numberOfTotalTasks, numberOfCompletedTasks)}
      </React.Fragment>
    );
  };

  return (
    <FetchIndicator
      status={[isFetching]}
      render={() => (
        <StyledContainerDiv data-testid="dashboard-container" className="container">
          <ContentHeader title="Colleague Portal Dashboard" />
          {renderContent()}
        </StyledContainerDiv>
      )}
    />
  );
}
