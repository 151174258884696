import React from 'react';
import PropTypes from 'prop-types';

import validation from '../agent-profile-validations';
import { useFetch, useForm, setObjectDefault, CurrentUserContext } from '../../common';
import { SAVE_AND_NEXT_UPDATE_IN_PROGRESS, useStepperProvider } from '../../onboarding-steps';
import initialAgentProfile from './initialAgentProfile';
import { useSaveProgressProvider } from '../../save-progress';

export const AgentProfileContext = React.createContext();

export const defaultGovernmentIssuedIdNumberLabel = 'USCIS#';
export const visaGovernmentIssuedIdNumberLabel = 'Visa number (not Control Number)';
export const unitedStatesCountryName = 'United States';

const initialSubmittedAgentProfileId = { result: null };

export default function AgentProfileProvider(props) {
  const { currentUser, setCurrentUser } = React.useContext(CurrentUserContext);
  const { data: rawData, isFetching: isFetchingAgentProfile } = useFetch('get', 'agentProfiles', initialAgentProfile, true, false);
  const memoAgentProfile = React.useMemo(() => setObjectDefault(rawData), [rawData]);

  const { data: submittedAgentProfileId, fetchStatus: submitStatus, request: { put: submit }, isSubmitting } = useFetch('put', 'agentProfiles', initialSubmittedAgentProfileId, true, true);
  const { dispatchSteps, agentProfileError, clearReviewAndSubmitError } = useStepperProvider();

  const { data: genders, request: requestGenders, isFetching: isFetchingGenders } = useFetch('get', 'genders', null, true, true);
  const { data: ethnicities, request: requestEthnicities, isFetching: isFetchingEthnicities } = useFetch('get', 'ethnicities', null, true, true);
  const { data: countries, request: requestCountries, isFetching: isFetchingCountries } = useFetch('get', 'countries', null, true, true);
  const { data: usStates, request: requestUsStates, isFetching: isFetchingUsStates } = useFetch('get', 'usStates', null, true, true);
  const { data: governmentIssuedIdTypes, request: requestGovernmentIssuedIdTypes, isFetching: isFetchingGovernmentIssuedIdTypes } = useFetch('get', 'governmentIssuedIdTypes', null, true, true);

  const [shouldSubmit, setShouldSubmit] = React.useState(false);
  const [agentProfileValidation, setAgentProfileValidation] = React.useState(() => validation(agentProfileError));

  const { setProgress, saveProgress } = useSaveProgressProvider();
  const saveProgressRef = React.useRef(saveProgress);
  const {
    state,
    handleChange,
    handleBlur,
    validationResult
  } = useForm(memoAgentProfile, undefined, undefined, agentProfileValidation, false);

  React.useEffect(
    () => {
      saveProgressRef.current();
    },
    []
  );

  React.useEffect(
    () => {
      const agentProfile = {
        ...state,
        createdDateTime: state.createdDateTime || new Date()
      };
      if (!agentProfile.profileId) {
        agentProfile.profileId = currentUser.profileId;
      }

      setProgress({
        resource: 'agentProfiles',
        state: agentProfile
      });
    },
    [currentUser.profileId, setProgress, state]
  );

  React.useEffect(
    () => {
      requestAllData();

      async function requestAllData() {
        await Promise.all([
          requestGenders.get(),
          requestCountries.get(),
          requestUsStates.get(),
          requestGovernmentIssuedIdTypes.get(),
          requestEthnicities.get()
        ]);
      }
    },
    [requestCountries, requestGenders, requestGovernmentIssuedIdTypes, requestUsStates, requestEthnicities]
  );

  React.useEffect(() => {
    if (shouldSubmit) {
      const agentProfile = {
        ...state,
        createdDateTime: state.createdDateTime || new Date()
      };
      if (!agentProfile.profileId) {
        agentProfile.profileId = currentUser.profileId;
      }
      submit(agentProfile);
    }
  }, [shouldSubmit, state, submit, currentUser.profileId]);

  const governmentIssuedIdNumberLabel = React.useMemo(
    () => {
      if (!governmentIssuedIdTypes) return defaultGovernmentIssuedIdNumberLabel;
      const selectedID = governmentIssuedIdTypes.find(g => g.governmentIssuedIdTypeId.toString() === state.governmentIssuedIdTypeId.toString());
      return selectedID?.governmentIssuedIdTypeName.toLowerCase().includes('visa')
        ? visaGovernmentIssuedIdNumberLabel
        : defaultGovernmentIssuedIdNumberLabel;
    },
    [governmentIssuedIdTypes, state.governmentIssuedIdTypeId]
  );

  React.useEffect(
    () => {
      if (submitStatus.code === 200) {
        clearReviewAndSubmitError('PersonalInfo');
        setProgress(null);
        if (!currentUser.profileId) {
          setCurrentUser(currentUser => ({
            ...currentUser,
            profileId: submittedAgentProfileId.result
          }));
        }
        dispatchSteps({
          type: SAVE_AND_NEXT_UPDATE_IN_PROGRESS,
          payload: 0
        });
        props.history.push(props.nextStep);
      }
    },
    [clearReviewAndSubmitError, currentUser.profileId, dispatchSteps, props.history, props.nextStep, setCurrentUser, setProgress, submitStatus.code, submitStatus.text, submittedAgentProfileId.result]
  );

  React.useEffect(() => {
    setAgentProfileValidation(
      agentProfileValidation => ({
        ...agentProfileValidation,
        validate: agentProfileValidation.setValidation(
          !!state.mailingAddressSameAsHome,
          state.stateCode
        )
      })
    );
  }, [state.mailingAddressSameAsHome, state.stateCode]);

  const homeAddress = React.useMemo(
    () => ({
      type: 'Home',
      descriptionText: '(cannot be a PO box and must be in your US state of residency)',
      disableCheckbox: {
        canDisable: false
      },
      name: {
        addressLine1: 'addressLine1',
        city: 'city',
        stateCode: 'stateCode',
        zipCode: 'zipCode',
      },
      source: {
        addressLine1: state.addressLine1,
        city: state.city,
        stateCode: state.stateCode,
        zipCode: state.zipCode
      },
      validation: {
        addressLine1: validationResult.addressLine1,
        city: validationResult.city,
        stateCode: validationResult.stateCode,
        zipCode: validationResult.zipCode
      }
    }),
    [state.addressLine1, state.city, state.stateCode, state.zipCode, validationResult.addressLine1, validationResult.city, validationResult.stateCode, validationResult.zipCode]
  );

  const mailingAddress = React.useMemo(
    () => ({
      type: 'Mailing',
      disableCheckbox: {
        canDisable: true,
        text: 'My mailing address is the same as my home address',
        name: 'mailingAddressSameAsHome',
        isChecked: !!state.mailingAddressSameAsHome
      },
      name: {
        addressLine1: 'mailingAddressLine1',
        city: 'mailingCity',
        stateCode: 'mailingStateCode',
        zipCode: 'mailingZipCode',
      },
      source: {
        addressLine1: state.mailingAddressLine1,
        city: state.mailingCity,
        stateCode: state.mailingStateCode,
        zipCode: state.mailingZipCode
      },
      validation: {
        addressLine1: validationResult.mailingAddressLine1,
        city: validationResult.mailingCity,
        stateCode: validationResult.mailingStateCode,
        zipCode: validationResult.mailingZipCode
      }
    }),
    [state.mailingAddressLine1, state.mailingAddressSameAsHome, state.mailingCity, state.mailingStateCode, state.mailingZipCode, validationResult.mailingAddressLine1, validationResult.mailingCity, validationResult.mailingStateCode, validationResult.mailingZipCode]
  );

  const fetchingStatus = React.useMemo(() =>
    [
      isFetchingCountries,
      isFetchingGenders,
      isFetchingEthnicities,
      isFetchingGovernmentIssuedIdTypes,
      isFetchingUsStates,
      isFetchingAgentProfile
    ], [isFetchingCountries, isFetchingGenders, isFetchingEthnicities, isFetchingGovernmentIssuedIdTypes, isFetchingUsStates, isFetchingAgentProfile]
  );

  const isBornInTheUsa = React.useMemo(
    () => {
      if (!state.countryOfBirthId || !countries) return false;

      const { countryId } = countries.find(c => c.countryName === unitedStatesCountryName);
      return state.countryOfBirthId.toString() === countryId.toString();
    },
    [countries, state.countryOfBirthId]
  );

  const value = React.useMemo(
    () => ({
      state,
      handleChange,
      handleBlur,
      validationResult,
      genders,
      countries,
      usStates,
      ethnicities,
      governmentIssuedIdTypes,
      governmentIssuedIdNumberLabel,
      homeAddress,
      mailingAddress,
      setShouldSubmit,
      submitStatus,
      fetchingStatus,
      isSubmitting,
      isBornInTheUsa,
    }),
    [
      countries,
      fetchingStatus,
      genders,
      governmentIssuedIdTypes,
      handleBlur,
      handleChange,
      homeAddress,
      isBornInTheUsa,
      isSubmitting,
      mailingAddress,
      governmentIssuedIdNumberLabel,
      state,
      submitStatus,
      usStates,
      validationResult,
      ethnicities
    ]
  );

  if (!rawData) return null;
  return <AgentProfileContext.Provider value={value} {...props} />;
}

AgentProfileProvider.propTypes = {
  history: PropTypes.object.isRequired,
  nextStep: PropTypes.string.isRequired
};
