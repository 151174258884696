import React from 'react';
import PropTypes from 'prop-types';

import { RadioGroup } from '../../common';
import { UPDATE_DISCLOSURE_QUESTION } from '../provider/disclosureQuestionReducers';
import { YES_NO_RADIO_GROUP } from '../disclosure-question-validations';

const data = [
  {
    value: 'Yes',
    text: 'Yes'
  },
  {
    value: 'No',
    text: 'No'
  },
];

const YesNoRadioGroup = ({ sourceName, value, isSubmitted, controlProps, ...props }) => {
  const yesNoRadioGroupId = `${YES_NO_RADIO_GROUP}-${sourceName}`;
  const {dispatchDisclosureQuestions, validationResult, handleChange} = controlProps;

  return (
    <RadioGroup
      {...props}
      name={yesNoRadioGroupId}
      onChange={event => {
        handleChange(event);
        dispatchDisclosureQuestions({
          type: UPDATE_DISCLOSURE_QUESTION,
          payload: {
            sourceName,
            value: event.target.value
          }
        });
      }}
      // TODO: refactor to use optional-chaining library
      validationState={validationResult && validationResult[yesNoRadioGroupId].validationState}
      errorMessage={validationResult && validationResult[yesNoRadioGroupId].errorMessage}
      selectedValue={value}
      data={data}
      disabled={isSubmitted}
      orientation="inline"
      data-testid="yes-no-radio-group"
    />
  );
};

YesNoRadioGroup.propTypes = {
  sourceName: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isSubmitted: PropTypes.bool.isRequired,
  controlProps: PropTypes.object
};

export default YesNoRadioGroup;
