import React from 'react';

import { StyledLink } from '../styles';
import { ActionSuccessNotification } from '../common';

const RegistrationSuccess = () =>
  <React.Fragment>
    <ActionSuccessNotification header="Success!" />
    <div className="row">
      <div className="col-12 center">
        We sent a verification link to the email address you provided. Please click on the link in the email to continue.
        <p>
          If you have already registered and verified, you can <StyledLink href="/">login here.</StyledLink>
        </p>
      </div>
    </div>
  </React.Fragment>;

export default RegistrationSuccess;
