import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Spinner from './Spinner';

const StyledRoot = styled.div`
  position: relative
`;

const StyledDiv = styled.div`
  position: relative;
  opacity: .3;
  pointer-events: none;
  z-index: 999;
`;

const StyledSpinnerDiv = styled.div`
  position: absolute;
  width: 100px;
  height: 100px;
  left: 50%;
  top: 20%;
  transform: translate(-50%, -50%);
  z-index: 1000;
`;

const FetchIndicator = React.memo(function FetchIndicator({ status, render }) {
  const shouldShowFetchIndicator = status.some(s => s);

  if (shouldShowFetchIndicator) {
    return (
      <React.Fragment>
        {shouldShowFetchIndicator &&
          <StyledRoot>
            <StyledDiv>
              {render()}
            </StyledDiv>
            <StyledSpinnerDiv>
              <Spinner />
            </StyledSpinnerDiv>
          </StyledRoot>
        }
      </React.Fragment>
    );
  }

  return render();
});

FetchIndicator.propTypes = {
  /** An array of `isFetching` prop returned from `useFetch` */
  status: PropTypes.arrayOf(PropTypes.bool.isRequired).isRequired,
  render: PropTypes.func.isRequired,
};

export default FetchIndicator;
