import React from 'react';

import AgentProfileProvider from './provider/AgentProfileProvider';
import AgentProfileForm from './forms/AgentProfileForm';

export default function AgentProfile(props) {
  return (
    <AgentProfileProvider {...props}>
      <AgentProfileForm />
    </AgentProfileProvider>
  );
}
