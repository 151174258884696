import React from 'react';

import useReviewAndSubmitProvider from './provider/useReviewAndSubmitProvider';
import HeaderSection from './sections/HeaderSection';
import AgentProfileSection from './sections/AgentProfileSection';
import WorkHistorySection from './sections/WorkHistorySection';
import LicensingDisclosuresSection from './sections/LicensingDisclosuresSection';
import AppointmentDisclosuresSection from './sections/AppointmentDisclosuresSection';
import SubmitSection from './sections/SubmitSection';

const Sections = () => {
  const { shouldShowSections } = useReviewAndSubmitProvider();

  if (!shouldShowSections) return null;

  return (
    <div data-testid="review-sections">
      <HeaderSection />
      <AgentProfileSection />
      <WorkHistorySection />
      <LicensingDisclosuresSection />
      <AppointmentDisclosuresSection />
      <SubmitSection />
    </div>
  );
};

export default Sections;
