import React from 'react';

import DisclosuresQuestionsSection from './DisclosureQuestionsSection';
import useReviewAndSubmitProvider from '../provider/useReviewAndSubmitProvider';
import { LICENSING_DISCLOSURES_ROUTE } from '../../onboarding-steps';

export default function LicensingDisclosuresSection() {
  const { history, licensingQuestions } = useReviewAndSubmitProvider();

  return (
    <DisclosuresQuestionsSection
      history={history}
      route={LICENSING_DISCLOSURES_ROUTE}
      headerText="Licensing Disclosures"
      data={licensingQuestions}
    />
  );
}
