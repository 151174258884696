import React from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';

import { WarningBadge } from './Badges';
import useOnHover from './useOnHover';
import { NOT_STARTED } from './stepper-constants';
import { StyledCircle, StyledStepperLine, StyledStepContainer, StyledStepText } from './stepper-styles';

export const Step = ({ number, text, warning, status, nextStatus, numberOfSteps, isSelected, route, history, match }) => {
  const stepContainerRef = React.useRef();
  const isHovering = useOnHover(stepContainerRef);

  const handleOnClick = () => {
    if (status === NOT_STARTED) return;
    history.push(`${match.url}/${route}`);
  };

  return (
    <StyledStepContainer 
      ref={stepContainerRef} 
      onClick={handleOnClick} 
      data-testid="step-container"
    >
      {number === 1 ?
        <StyledStepperLine hide />
        :
        <StyledStepperLine
          status={status}
          nextStatus={nextStatus}
        />
      }

      <StyledCircle
        isSelected={isSelected}
        status={status}
        isHovering={isHovering}
      >
        {number}
        {warning ? <WarningBadge /> : null}
      </StyledCircle>

      {number === numberOfSteps ?
        <StyledStepperLine hide />
        :
        <StyledStepperLine
          rightSide
          status={status}
          nextStatus={nextStatus}
        />
      }
      <StyledStepText
        isSelected={isSelected}
        isHovering={isHovering}
        status={status}
      >
        {text}
      </StyledStepText>
    </StyledStepContainer>
  );
};

Step.propTypes = {
  number: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  warning: PropTypes.bool.isRequired,
  status: PropTypes.string.isRequired,
  numberOfSteps: PropTypes.number.isRequired,
  isSelected: PropTypes.bool.isRequired,
  nextStatus: PropTypes.string.isRequired,
  route: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

export default withRouter(Step);
