import React from 'react';
import PropTypes from 'prop-types';

import { useForm } from '../../common';
import validation from '../disclosure-question-validations';

export const ControlsContext = React.createContext();

export default function ControlsProvider({ data, children, sourceName, reviewAndSubmitError, dispatch }) {
  const initialState = React.useMemo(
    () => ({ [`${data.controlTypeName}-${sourceName}`]: data.response }),
    [data.controlTypeName, data.response, sourceName]
  );

  const disclosureQuestionValidation = React.useMemo(
    () => validation(sourceName, data.controlTypeName, reviewAndSubmitError),
    [data.controlTypeName, reviewAndSubmitError, sourceName]
  );

  const {
    state,
    handleChange,
    handleBlur,
    handleSubmit,
    validationResult
  } = useForm(initialState, undefined, undefined, disclosureQuestionValidation, false);

  const value = React.useMemo(() => ({
    state,
    handleChange,
    handleBlur,
    handleSubmit,
    validationResult,
    dispatch
  }), [dispatch, handleBlur, handleChange, handleSubmit, state, validationResult]);

  return (
    <ControlsContext.Provider value={value}>
      {children}
    </ControlsContext.Provider>
  );
}

ControlsProvider.propTypes = {
  data: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  sourceName: PropTypes.string.isRequired,
  reviewAndSubmitError: PropTypes.string,
  dispatch: PropTypes.func.isRequired
};
