import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import useDisclosureQuestionsProvider from './provider/useDisclosureQuestionsProvider';
import { Typography, Button } from '../common';

const StyledDiv = styled.div`
  padding-left: 100px;
  padding-right: 100px;
`;

const Introduction = props => {
  const { shouldShowIntroduction, setShouldShowIntroduction } = useDisclosureQuestionsProvider();

  if (!shouldShowIntroduction) return null;

  const isTypeLicensing = props.type === 'licensing';

  return (
    <StyledDiv>
      <Typography
        text={`Step ${isTypeLicensing ? '3' : '4'}`}
        variant="sectionHeader"
        data-testid="disclosure-question-intro"
      />
      <br />
      <div className="row">
        {isTypeLicensing ? 'Now we\'re going to ask you some important legal questions.' : 'Just one more set of questions.'}
      </div>
      < br />
      <div className="row">
        These questions are <b>required by {isTypeLicensing ? 'the state in order to be licensed.' : 'insurance carriers in order to become appointed.'}</b>
      </div>
      <br />
      <div className="row">
        It is important that you answer the questions as accurately and honestly as possible. This will allow us to move you through the process quickly.
      </div>
      <br />
      <br />
      <div className="row">
        <div className="col-12 right">
          <Button
            data-testid="hide-introduction"
            label="Next"
            isOutline
            onClick={() => setShouldShowIntroduction(false)}
          />
        </div>
      </div>
    </StyledDiv>
  );
};

Introduction.propTypes = {
  type: PropTypes.string
};

export default Introduction;
