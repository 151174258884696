import React from 'react';
import PropTypes from 'prop-types';

import {
  Checkbox,
  Dropdown,
  MaskedTextInput,
  TextInput,
  TextArea,
  Typography,
} from '../../common';

const addressShape = {
  addressLine1: PropTypes.string,
  city: PropTypes.string,
  stateCode: PropTypes.string,
  zipCode: PropTypes.string
};

const disableCheckboxShape = {
  canDisable: PropTypes.bool.isRequired,
  text: PropTypes.string,
  name: PropTypes.string,
  testId: PropTypes.string,
  isChecked: PropTypes.bool
};

const Address = ({
  descriptionText,
  disableCheckbox,
  type,
  name,
  source,
  validationResult,
  usStates,
  onChange,
  onBlur
}) => {
  const canDisable = disableCheckbox.canDisable;
  const headerColSize = canDisable ? 'col-3' : 'col-12';
  const addressEnabled = !(disableCheckbox.canDisable && disableCheckbox.isChecked);

  return (
    <React.Fragment>
      <div className="row">
        <div className={headerColSize}>
          <Typography
            data-testid="address-header"
            variant="sectionHeader"
            text={`${type} address`}
            secondaryText={descriptionText}
          />
        </div>
        {canDisable &&
          <div className="col-7">
            <Checkbox
              data-testid="address-can-disable"
              name={disableCheckbox.name}
              text={disableCheckbox.text}
              checked={disableCheckbox.isChecked}
              onChange={onChange}
            />
          </div>
        }
      </div>
      {addressEnabled &&
        <React.Fragment>
          <div className="row">
            <div className="col-6">
              <TextArea
                data-testid="address-street-input"
                name={name.addressLine1}
                label="Street address"
                value={source.addressLine1}
                onChange={onChange}
                onBlur={onBlur}
                validationState={validationResult.addressLine1.validationState}
                errorMessage={validationResult.addressLine1.errorMessage}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <TextInput
                data-testid="address-city-input"
                name={name.city}
                label="City"
                value={source.city}
                onChange={onChange}
                onBlur={onBlur}
                validationState={validationResult.city.validationState}
                errorMessage={validationResult.city.errorMessage}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <Dropdown
                data-testid="address-state-dropdown"
                name={name.stateCode}
                label="State"
                value={source.stateCode}
                data={usStates}
                dataTextField="stateName"
                dataValueField="stateCode"
                onChange={onChange}
                onBlur={onBlur}
                validationState={validationResult.stateCode.validationState}
                errorMessage={validationResult.stateCode.errorMessage}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-4">
              <MaskedTextInput
                data-testid="address-zip-input"
                maskType="zip"
                name={name.zipCode}
                label="Zip code"
                value={source.zipCode}
                onChange={onChange}
                onBlur={onBlur}
                variant="zip"
                validationState={validationResult.zipCode.validationState}
                errorMessage={validationResult.zipCode.errorMessage}
              />
            </div>
          </div>
        </React.Fragment>
      }
    </React.Fragment>
  );
};

Address.propTypes = {
  type: PropTypes.string.isRequired,
  descriptionText: PropTypes.string,
  disableCheckbox: PropTypes.shape(disableCheckboxShape),
  name: PropTypes.shape(addressShape).isRequired,
  source: PropTypes.shape(addressShape).isRequired,
  validationResult: PropTypes.object,
  usStates: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired
};

export default Address;
