export default function updateValidationResult(initialValidationResult) {
  let validationResult = { ...initialValidationResult };

  return function (errorMessage, prop) {
    const result = {
      validationState: errorMessage ? 'danger' : 'default',
      errorMessage,
    };
    validationResult = {
      ...validationResult,
      [prop]: { ...result }
    };

    return validationResult;
  };
}
