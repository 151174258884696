import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledTableCellDiv = styled.div`
  width: ${props => props.width}px;
  min-width:${props => props.width}px;
  margin-top: 12px;
  margin-right: 15px;
`;
const StyledTableRowDiv = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid gray;
  background-color: ${props => props.backgroundColor}
  padding: 0 0 10px 10px;
`;

const StyledTableHeaderDiv = styled(StyledTableRowDiv)`
  border-bottom: 1px solid black;
  font-weight: bold;
  padding: 0 0 10px 10px;
`;

export const ExpandableRowsContext = React.createContext();

function Row({ rowNumber, rowData, columnConfig, hasAlternatingRowColors }) {
  return (
    <StyledTableRowDiv backgroundColor={hasAlternatingRowColors && rowNumber % 2 === 0 ? 'lightgray' : 'none'}>
      {
        columnConfig.map((c, i) =>
          <StyledTableCellDiv key={i} width={c.width}>
            {c.cellRenderer(rowData)}
          </StyledTableCellDiv>
        )}
    </StyledTableRowDiv>
  );
}

function HeaderRow({ columnConfig }) {
  return (
    <StyledTableHeaderDiv>
      {
        columnConfig.map((c, i) =>
          <StyledTableCellDiv key={i} width={c.width}>
            {c.label}
          </StyledTableCellDiv>
        )}
    </StyledTableHeaderDiv>
  );
}

function Table({ data, columnConfig, idKey, hasAlternatingRowColors }) {
  const [tableData, setTableData] = React.useState(() => data.map(d => ({ ...d,
    isRowExpanded: false})));

  React.useEffect(() => {
    setTableData(data);
  }, [data]);

  function setHandleRowExpansion(idKey) {
    return function (rowData) {
      const indexToUpdate = tableData.findIndex(d => d[idKey] === rowData[idKey]);
      setTableData([
        ...tableData.slice(0, indexToUpdate),
        {
          ...tableData[indexToUpdate],
          isRowExpanded: !tableData[indexToUpdate].isRowExpanded
        },
        ...tableData.slice(indexToUpdate + 1)
      ]);
    };
  }

  const handleRowExpansion = setHandleRowExpansion(idKey);
  return (
    <ExpandableRowsContext.Provider
      value={{
        handleRowExpansion
      }}
    >
      <HeaderRow columnConfig={columnConfig} />
      {tableData.map((d, i) =>
        <Row
          key={i}
          rowNumber={i + 1}
          rowData={d}
          columnConfig={columnConfig}
          hasAlternatingRowColors={hasAlternatingRowColors}
        />)}
    </ExpandableRowsContext.Provider>
  );
}

Table.propTypes = {
  data: PropTypes.array.isRequired,
  columnConfig: PropTypes.array.isRequired,
  idKey: PropTypes.string.isRequired,
  hasAlternatingRowColors: PropTypes.bool
};

Row.propTypes = {
  rowData: PropTypes.object.isRequired,
  columnConfig: PropTypes.array.isRequired,
  rowNumber: PropTypes.number.isRequired,
  hasAlternatingRowColors: PropTypes.bool
};

HeaderRow.propTypes = {
  columnConfig: PropTypes.array.isRequired
};

export default Table;
