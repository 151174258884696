const agentCertificationStatus = {
  Unavailable: 1,
  Pending: 2,
  Submitted: 3,
  Secondary_approval_required: 4,
  Completed: 5,
  Rejected: 6,
  Closed: 7,
};

function getKeyByValue(object, value) {
  for (const prop in object) {
    if (Object.hasOwn(object, prop)) {
      if (object[prop] === value) return prop;
    }
  }
  return '';
}

export function parseAgentCertificationStatus(agentCertificationStatusId) {
  return getKeyByValue(agentCertificationStatus, agentCertificationStatusId);
}

export default agentCertificationStatus;
