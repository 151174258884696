import PrimaryQuestion from './PrimaryQuestion';
import SubQuestion from './SubQuestion';
import { ThirdLevelQuestion } from './ThirdLevelQuestion';
import SubDescription from './SubDescription';

export default {
  PrimaryQuestion,
  SubQuestion,
  ThirdLevelQuestion,
  SubDescription
};
