import { defaultValidationState, updateValidationResult, isRequired } from '../common/validations';

export const TEXTBOX = 'Textbox';
export const YES_NO_RADIO_GROUP = 'YesNoRadioGroup';
export const NUMERIC_DROPDOWN = 'NumericDropdown';
export const I_HAVE_READ_IT_CHECKBOX = 'IHaveReadItCheckbox';

export default (sourceName, controlTypeName, reviewAndSubmitError) => {
  const textBoxId = `${TEXTBOX}-${sourceName}`;
  const yesNoRadioGroupId = `${YES_NO_RADIO_GROUP}-${sourceName}`;
  const numericDropdownId = `${NUMERIC_DROPDOWN}-${sourceName}`;
  const iHaveReadItCheckBoxId = `${I_HAVE_READ_IT_CHECKBOX}-${sourceName}`;

  const errorValidationState = {
    validationState: 'danger',
    errorMessage: reviewAndSubmitError
  };

  const initialValidationState = reviewAndSubmitError ? errorValidationState : defaultValidationState;

  const initialValidationResultSelector = (controlTypeName) => {
    switch (controlTypeName) {
      case TEXTBOX:
        return { [textBoxId]: { ...initialValidationState } };
      case YES_NO_RADIO_GROUP:
        return { [yesNoRadioGroupId]: { ...initialValidationState } };
      case NUMERIC_DROPDOWN:
        return { [numericDropdownId]: { ...initialValidationState } };
      case I_HAVE_READ_IT_CHECKBOX:
        return { [iHaveReadItCheckBoxId]: { ...initialValidationState } };
      default:
        return null;
    }
  };

  const initialValidationResult = initialValidationResultSelector(controlTypeName);

  const update = updateValidationResult(initialValidationResult);

  const validate = value => ({
    [textBoxId]: () => {
      const errorMessage = isRequired(value)`a response`;
      return update(errorMessage, textBoxId);
    },
    [yesNoRadioGroupId]: () => {
      const errorMessage = isRequired(value)`a response`;
      return update(errorMessage, yesNoRadioGroupId);
    },
    [numericDropdownId]: () => {
      const errorMessage = isRequired(value)`a response`;
      return update(errorMessage, numericDropdownId);
    },
    [iHaveReadItCheckBoxId]: () => {
      const stringValue = value.toString() === 'true' ? value.toString() : '';
      const errorMessage = isRequired(stringValue)`a response`;
      return update(errorMessage, iHaveReadItCheckBoxId);
    }
  });

  return {
    initialValidationResult,
    validate
  };
};
