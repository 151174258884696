import React from 'react';

import { Button, FetchErrorNotification, Typography, FetchIndicator } from '../../common';
import { AgentProfileContext } from '../provider/AgentProfileProvider';
import { CitizenshipStatus, BirthInfo, ContactInfo, GovernmentIssuedID } from '../forms';

const welcomeHeader = 'Step 1';
const welcomeMessage = "Now that you're registered, let's get some more info about you.";

export default function AgentProfileForm() {
  const {
    state,
    setShouldSubmit,
    submitStatus,
    fetchingStatus,
    isSubmitting,
  } = React.useContext(AgentProfileContext);

  return (
    <FetchIndicator
      status={fetchingStatus}
      render={() => (
        <React.Fragment>
          {submitStatus?.code !== null && (
            <div className="row">
              <div className="col-12">
                <FetchErrorNotification status={[submitStatus]} />
              </div>
            </div>
          )}
          <div className="row">
            <div className="col-12">
              <Typography variant="header" text={welcomeHeader} />
              <Typography variant="description" text={welcomeMessage} />
            </div>
          </div>
          <CitizenshipStatus />
          {state.isUSCitizen !== null && (
            <React.Fragment>
              <BirthInfo />
              <GovernmentIssuedID />
              <ContactInfo />
            </React.Fragment>
          )}
          <div className="row">
            <div className="col-12 right">
              <Button
                data-testid="userprofile-submit-btn"
                label="Continue"
                isOutline
                onClick={() => setShouldSubmit(true)}
                isSubmitting={isSubmitting}
              />
            </div>
          </div>
        </React.Fragment>
      )}
    />
  );
}
