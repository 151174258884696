const boolFields = [
  'is',
  'should',
  'has',
];

export default data =>
  Object.keys(data).reduce((accumulator, key) => {
    const isFieldBoolean = boolFields.some(b => key.substr(0, b.length) === b && key.substr(b.length, 1) === key.substr(b.length, 1).toUpperCase());
    accumulator[key] = (data[key] === null && !key.toLowerCase().includes('date')) && !isFieldBoolean ? '' : data[key];

    return accumulator;
  }, {});
