import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Icon } from 'es-components';

const OK_CIRCLE = 'ok-circle';
const CERTIFICATE = 'certificate';

const StyledHeader = styled.div`
  font-weight: bold;
  opacity: 0.8;
  font-size: 30px;
`;

const StyledMessage = styled.div`
  margin: 20px 0;
  opacity: 0.8;
  font-size: 24px;
`;

const iconConfig = {
  [OK_CIRCLE]: {
    color: '#3a8549'
  },
  [CERTIFICATE]: {
    color: '#f8b738'
  }
};

const StyledIcon = styled(Icon)`
  color:  ${props => iconConfig[props.name].color};
`;

export default function ActionSuccessNotification({ header, message, iconName = OK_CIRCLE, className = '' }) {
  return (
    <div className={`row ${className}`}>
      <div className="col-12 center">
        <StyledHeader>{header}</StyledHeader>
        {message && <StyledMessage>{message}</StyledMessage>}
        <StyledIcon size={150} name={iconName} />
      </div>
    </div>
  );
}

ActionSuccessNotification.propTypes = {
  header: PropTypes.string.isRequired,
  message: PropTypes.string,
  iconName: PropTypes.oneOf([OK_CIRCLE, CERTIFICATE]),
  className: PropTypes.string,
};
