import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { StyledFoamGreenDiv } from './styles/dashboard-styles';

const StyledRoot = styled(StyledFoamGreenDiv)`
  display: flex;
  align-items: center;
  padding: 20px 30px;
`;

const StyledProgressBar = styled.div`
  flex: 4;
  background-color: white;
`;

const StyledProgressBarStriped = styled.div`
  color: rgb(255, 255, 255);
  height: 25px;
  width: ${props => `${props.percentCompleted}%`};
  background-size: 40px 40px;
  background-color: rgb(0, 155, 204);
  background-image: 
    linear-gradient(45deg, rgba(24, 26, 27, 0.15) 25%,
    rgba(0, 0, 0, 0) 25%,
    rgba(0, 0, 0, 0) 50%,
    rgba(24, 26, 27, 0.15) 50%,
    rgba(24, 26, 27, 0.15) 75%,
    rgba(0, 0, 0, 0) 75%,
    rgba(0, 0, 0, 0));
  box-shadow: rgba(0, 0, 0, 0.15) 0px -1px 0px inset;
`;

const StyledText = styled.div`
  flex: 1;
  margin-left: 20px;
`;

export default function ProgressBar({ numberOfCompletedTasks, numberOfTotalTasks }) {
  const percentCompleted = Math.ceil(numberOfCompletedTasks / numberOfTotalTasks * 100);

  return (
    <StyledRoot data-testid="progress-bar">
      <StyledProgressBar>
        <StyledProgressBarStriped percentCompleted={percentCompleted} />
      </StyledProgressBar>
      <StyledText>
        {numberOfCompletedTasks}/{numberOfTotalTasks} tasks complete
      </StyledText>
    </StyledRoot>
  );
}

ProgressBar.propTypes = {
  numberOfCompletedTasks: PropTypes.number.isRequired,
  numberOfTotalTasks: PropTypes.number.isRequired,
};
