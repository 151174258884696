import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { OutlineButton, Button as ESButton } from 'es-components';

import Spinner from './Spinner';

const StyledRootDiv = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const StyledDiv = styled.div`
  position: relative;
`;

const StyledSpinnerDiv = styled.div`
  position: absolute;
  width: 25px;
  height: 25px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Button = React.forwardRef(({ label, isSubmitting = false, disabled = false, isOutline = false, ...props }, ref) => {
  const internalButton =
    <React.Fragment>
      {isSubmitting &&
        <StyledRootDiv>
          <StyledDiv>
            {label}
          </StyledDiv>
          <StyledSpinnerDiv>
            <Spinner title={label} description={label} />
          </StyledSpinnerDiv>
        </StyledRootDiv>
      }
      {!isSubmitting && label}
    </React.Fragment>;

  if (isOutline) {
    return (
      <OutlineButton
        ref={ref}
        disabled={isSubmitting || disabled}
        {...props}
      >
        {internalButton}
      </OutlineButton>);
  }
  return (
    <ESButton
      ref={ref}
      disabled={isSubmitting || disabled}
      {...props}
    >
      {internalButton}
    </ESButton>);
});

Button.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  isSubmitting: PropTypes.bool,
  disabled: PropTypes.bool,
  isOutline: PropTypes.bool
};

export default Button;
