import { defaultValidationState, updateValidationResult, isValidEmail } from '../common/validations';

export default function () {
  const initialValidationResult = { emailAddress: { ...defaultValidationState } };
  const update = updateValidationResult(initialValidationResult);
  const validate = value => ({
    emailAddress: () => {
      const errorMessage = isValidEmail(value, []);
      return update(errorMessage, 'emailAddress');
    }
  });

  return {
    initialValidationResult,
    validate
  };
}
