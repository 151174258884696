import React from 'react';
import styled from 'styled-components';
import { Heading, Icon } from 'es-components';
import wtwTheme from 'es-components-wtw-theme';
import dayjs from 'dayjs';

import BackBar from '../dashboard/BackBar';
import { Table, IconButton, Notification, Button, Checkbox } from '../common';
import { ContentHeader } from '../header';
import { wtwWhite } from '../styles';
import ProofSection from './ProofSection';
import { taskDetailColumnList } from './TaskDetailColumnList';
import { parseAgentCertificationStatus } from '../common/AgentCertificationStatus';
import { DashboardContext } from '../dashboard/provider/DashboardProvider';
import AdjustCredentialsSlideout from '../dashboard/AdjustCredentialsSlideout';
import useRefreshToDashboard from '../dashboard/useRefreshToDashboard';
import CertComplete from './CertComplete';

const StyledContainer = styled.div`
  background-color: ${wtwWhite};
  margin-bottom: 4em;
`;

const StyledHeading = styled(Heading)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const StyledHeadingSpan = styled.span`
  margin-right: 20px;
`;

const StyledPageContentDiv = styled.div`
  padding: 20px;
`;

const NotificationContentFlexDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledNotificationHeader = styled.div`
  background-color: #069;
  padding: 8px 15px;
  color: white;
`;

const StyledHeaderIconWithTextDiv = styled.div`
  display: flex;
  align-items: center;
`;

const AGENT_CERT_REJECTED_STATUS = parseAgentCertificationStatus(6);

export default function CertificationTaskDetail(props) {
  const [usernamePasswordVerificationChecked, setUsernamePasswordVerificationChecked] = React.useState(false);
  const [showSubmitSuccessMessage, setShowSubmitSuccessMessage] = React.useState(false);
  const {
    adjustCredentialsSlideoutObjs,
    selectedTaskItem: certificationDetail,
    submitProofFetchStatus
  } = React.useContext(DashboardContext);

  useRefreshToDashboard(props);

  React.useEffect(() => {
    if (submitProofFetchStatus?.code === 200) {
      setShowSubmitSuccessMessage(true);
    }
  }, [submitProofFetchStatus]);

  function getRejectedReasonTitleAndBody(rejectedReasonString) {
    const bodyStartIndex = rejectedReasonString.indexOf('[');
    const bodyEndIndex = rejectedReasonString.indexOf(']');
    if (bodyStartIndex < 0 || bodyEndIndex < 0 || bodyEndIndex < bodyStartIndex) {
      return ({
        title: '',
        body: rejectedReasonString
      });
    }

    return ({
      title: rejectedReasonString.substring(0, bodyStartIndex).trim(),
      body: rejectedReasonString.substring(bodyStartIndex + 1, bodyEndIndex).trim()
    });
  }

  const parsedRejectedReason = certificationDetail.agentCertificationTaskStatusChangedReason ? getRejectedReasonTitleAndBody(certificationDetail.agentCertificationTaskStatusChangedReason) : null;

  return (
    <StyledContainer className="container">
      <ContentHeader title="Certification Process" />
      <BackBar />
      {showSubmitSuccessMessage ?
        <CertComplete />
        :
        <>
          <StyledPageContentDiv>
            <StyledHeading level={2} underlineColor={wtwTheme.colors.primary}>
              {<StyledHeadingSpan>{certificationDetail.certYear} {certificationDetail.carrierAlias ? certificationDetail.carrierAlias : certificationDetail.carrierName} certification</StyledHeadingSpan>}
              <IconButton
                styleType="primary"
                iconLeft="external-link"
                label="View instructions"
                disabled={!certificationDetail.instructionLink}
                onClick={() => {
                  const instructionLink =
                      certificationDetail.instructionLink.startsWith('http://') || certificationDetail.instructionLink.startsWith('https://') ?
                        certificationDetail.instructionLink
                        :
                        `http://${certificationDetail.instructionLink}`;
                  window.open(instructionLink, '_blank');
                }}
                data-testid="instruction-link-button"
              />
            </StyledHeading>
            {certificationDetail.agentCertificationTaskStatusName === AGENT_CERT_REJECTED_STATUS &&
                  <Notification
                    message={parsedRejectedReason.body}
                    type="danger"
                    header={`Rejected on ${dayjs(certificationDetail.agentCertificationTaskStatusChangedDate).format('MM/DD/YYYY')}: ${parsedRejectedReason.title}`}
                  />
            }
            <Table
              data={[certificationDetail]}
              columnConfig={taskDetailColumnList}
              idKey="agentCertificationTaskId"
              hasAlternatingRowColors
            />
            <br />
            <>
              <StyledNotificationHeader>
                <NotificationContentFlexDiv>
                  <StyledHeaderIconWithTextDiv>
                    <Icon name="info-circle" size={28} />&nbsp;
                        Your credentials must be up-to-date before submitting a certification proof.
                  </StyledHeaderIconWithTextDiv>
                  <Button
                    label="Change username/password"
                    isOutline
                    size="sm"
                    styleType="info"
                    onClick={() => adjustCredentialsSlideoutObjs.openAdjustCredentialsSlideout(certificationDetail.agentCertificationTaskId)}
                  />
                </NotificationContentFlexDiv>
              </StyledNotificationHeader>
              <Notification
                isInline
                includeIcon={false}
                isLightNotification
                message={
                  <Checkbox
                    onClick={() => setUsernamePasswordVerificationChecked(!usernamePasswordVerificationChecked)}
                    text="The above username and password are accurate and up-to-date."
                    checked={usernamePasswordVerificationChecked}
                  />
                }
                type="info"
              />
            </>
            <br />
            <ProofSection usernamePasswordVerificationChecked={usernamePasswordVerificationChecked} certificationDetail={certificationDetail}/>
          </StyledPageContentDiv>
          <AdjustCredentialsSlideout adjustCredentialsSlideoutObjs={adjustCredentialsSlideoutObjs} />
        </>
      }

    </StyledContainer>
  );
}
