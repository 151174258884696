import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Button as ESButton, Icon } from 'es-components';

const StyledButtonText = styled.span`
  font-size: 15px;
  vertical-align: text-bottom;
`;
const StyledBaseIcon = styled(Icon)`
  position: relative;
  top: 3px;
`;
const StyledLeftIcon = styled(StyledBaseIcon)`
  margin-right: 5px
`;
const StyledRightIcon = styled(StyledBaseIcon)`
  margin-left: 5px;
`;

const IconButton = React.forwardRef(({ label, iconLeft, iconRight, iconSize = 28, ...props }, ref) => {
  return (
    <ESButton
      ref={ref}
      {...props}
    >
      {iconLeft &&
        <StyledLeftIcon
          name={iconLeft}
          size={iconSize}
        />
      }
      <StyledButtonText >{label}</StyledButtonText>
      {iconRight &&
        <StyledRightIcon
          name={iconRight}
          size={iconSize}
        />
      }
    </ESButton>
  );
});

IconButton.propTypes = {
  label: PropTypes.string.isRequired,
  iconLeft: PropTypes.string,
  iconRight: PropTypes.string,
  iconSize: PropTypes.number
};

export default IconButton;
