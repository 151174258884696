import React from 'react';
import PropTypes from 'prop-types';

import {
  StyledDiv,
  StyledMobileBannerDiv,
  StyledTitleDiv,
  StyledForm,
  StyledLink,
  StyledFetchErrorNotificationDiv
} from '../login/login-styles';
import { StyledFormTitle, StyledControlDiv, SuccessNotificationDiv } from './password-reset-styles';
import { useForm, TextInput, Button, FetchErrorNotification, useFetch, licensureEmail } from '../common';
import validation from './password-reset-request-validations';
import SuccessNotification from './SuccessNotification';
import HttpCodes from '../common/HttpCodes';

const passwordResetRequestValidation = validation();

export default function PasswordResetRequest({ location }) {
  const { request, fetchStatus, isSubmitting } = useFetch('post', 'forgotPassword', null, false, true);
  const initialState = React.useMemo(
    () => {
      const { state: { initialEmailAddress } = {} } = location;
      return { emailAddress: initialEmailAddress || '' };
    },
    [location]
  );
  const { state, handleChange, handleBlur, handleSubmit, validationResult } = useForm(initialState, requestPasswordReset, undefined, passwordResetRequestValidation);

  function requestPasswordReset() {
    request.post(state);
  }

  const renderBody = () => {
    if (fetchStatus.code === HttpCodes.OK) {
      return (
        <StyledForm>
          <SuccessNotificationDiv>
            <SuccessNotification
              header="Reset link requested"
              message="If your email address is in our system, you should receive instructions soon."
            />
          </SuccessNotificationDiv>
          <div className="row">
            <StyledLink href="/">Return to login page</StyledLink>
          </div>
        </StyledForm>);
    }

    return (
      <StyledForm>
        <StyledFormTitle className="row">
          <div className="col-12">Reset Password</div>
        </StyledFormTitle>
        <div className="row">
          <div className="col-12">
            Enter the email address you used to register.
          </div>
        </div>
        <StyledControlDiv className="row">
          <div className="col-12">
            <TextInput
              data-testid="email-address"
              name="emailAddress"
              label="Email address"
              validationState={validationResult.emailAddress.validationState}
              errorMessage={validationResult.emailAddress.errorMessage}
              value={state.emailAddress}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
        </StyledControlDiv>
        <div className="row">
          <div className="col-4 left">
            <StyledLink id="register-here" href="/">Back to login</StyledLink>
          </div>
          <div className="col-8 right">
            <Button
              data-testid="request-reset-password"
              type="submit"
              label="Reset password"
              isOutline
              onClick={handleSubmit}
              isSubmitting={isSubmitting}
            />
          </div>
        </div>
        <StyledControlDiv className="row">
          <div className="col-12">
            Forgot the email you used? Contact: <a href={`mailto:${licensureEmail}`}>{licensureEmail}</a>
          </div>
        </StyledControlDiv>
        <StyledFetchErrorNotificationDiv>
          <FetchErrorNotification status={[fetchStatus]} />
        </StyledFetchErrorNotificationDiv>
      </StyledForm>);
  };

  return (
    <StyledDiv>
      <div className="container">
        <StyledTitleDiv>Individual Marketplace Colleague Portal</StyledTitleDiv>
        {renderBody()}
      </div>
      <StyledMobileBannerDiv />
    </StyledDiv>
  );
}

PasswordResetRequest.propTypes = {
  location: PropTypes.object.isRequired,
};
