import React from 'react';

import { SaveProgressContext } from './SaveProgressProvider';
import { useFetch } from '../../common';
import { useStepperProvider } from '../../onboarding-steps';

const resourceToStepperFlagMap = {
  agentProfiles: 'PersonalInfo',
  workHistory: 'WorkHistory',
  'disclosureQuestions/appointment': 'AppointmentDisclosures',
  'disclosureQuestions/licensing': 'LicensingDisclosures'
};

export default function useSaveProgressProvider() {
  const context = React.useContext(SaveProgressContext);
  const { httpRequest, fetchStatus: saveProgressFetchStatus, isSubmitting: isSavingProgress } = useFetch(null, null, null, true, true);
  const { clearReviewAndSubmitError } = useStepperProvider();

  /**
   * Calls http put based on a given resource and state and clears submission error.
   * If resource and state are not set, submission is skipped.
   */
  const saveProgress = React.useCallback(
    () => {
      const { progress, setProgress } = context;
      if (!progress) return;

      httpRequest(progress.resource).put(progress.state);
      setProgress(null);
      clearReviewAndSubmitError(resourceToStepperFlagMap[progress.resource]);
    },
    [clearReviewAndSubmitError, context, httpRequest]
  );

  /**
   * Calls http put even if the resource and state are not set.
   * Use this to force submission when any fetch result is needed.
   */
  const forceSaveProgress = React.useCallback(
    () => {
      const { resource, state } = context.progress || {};
      httpRequest(resource).put(state);
    },
    [context, httpRequest]
  );

  return {
    ...context,
    saveProgress,
    forceSaveProgress,
    saveProgressFetchStatus,
    isSavingProgress,
  };
}
