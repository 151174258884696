import React from 'react';
import PropTypes from 'prop-types';
import { Notification as EsNotification, Message, InlineMessage, LightNotification as EsLightNotification } from 'es-components';

export default function Notification({ header, message, isInline = false, includeIcon = true, isLightNotification = false, ...props }) {
  if (isLightNotification) {
    return (
      <EsLightNotification includeIcon={includeIcon} {...props}>
        {isInline
          ? <InlineMessage emphasizedText={header} text={message} />
          : <Message emphasizedText={header} text={message} />
        }
      </EsLightNotification>
    );
  }
  return (
    <EsNotification includeIcon={includeIcon} {...props}>
      {isInline
        ? <InlineMessage emphasizedText={header} text={message} />
        : <Message emphasizedText={header} text={message} />
      }
    </EsNotification>
  );
}

Notification.propTypes = {
  header: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.node.isRequired]),
  includeIcon: PropTypes.bool,
  isInline: PropTypes.bool,
  isLightNotification: PropTypes.bool
};
