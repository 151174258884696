import React from 'react';

export const SaveProgressContext = React.createContext();

export default function SaveProgressProvider(props) {
  const [progress, setProgress] = React.useState(null);

  const value = React.useMemo(
    () => ({
      progress,
      setProgress,
    }),
    [progress]
  );

  return <SaveProgressContext.Provider value={value} {...props} />;
}
