import React from 'react';

import DisclosuresQuestionsSection from './DisclosureQuestionsSection';
import useReviewAndSubmitProvider from '../provider/useReviewAndSubmitProvider';
import { APPOINTMENT_DISCLOSURES_ROUTE } from '../../onboarding-steps';

export default function AppointmentDisclosuresSection() {
  const { history, appointmentQuestions } = useReviewAndSubmitProvider();

  return (
    <DisclosuresQuestionsSection
      history={history}
      route={APPOINTMENT_DISCLOSURES_ROUTE}
      headerText="Appointment Disclosures"
      data={appointmentQuestions}
    />
  );
}
