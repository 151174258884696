import React from 'react';
import PropTypes from 'prop-types';

import { Dropdown } from '../../common';
import { UPDATE_DISCLOSURE_QUESTION } from '../provider/disclosureQuestionReducers';
import { NUMERIC_DROPDOWN } from '../disclosure-question-validations';

const data = [...Array(37)].map((_, index) => {
  const value = index.toString();
  return {
    id: value,
    text: value
  };
});

const NumericDropdown = ({ sourceName, value, controlProps, ...props }) => {
  const numericDropdownId = `${NUMERIC_DROPDOWN}-${sourceName}`;
  const { validationResult, handleBlur, handleChange, dispatchDisclosureQuestions } = controlProps;

  return <Dropdown
    {...props}
    name={numericDropdownId}
    onBlur={handleBlur}
    onChange={event => {
      handleChange(event);
      dispatchDisclosureQuestions({
        type: UPDATE_DISCLOSURE_QUESTION,
        payload: {
          sourceName,
          value: event.target.value
        }
      });
    }}
    // TODO: refactor to use optional-chaining library
    validationState={validationResult && validationResult[numericDropdownId].validationState}
    errorMessage={validationResult && validationResult[numericDropdownId].errorMessage}
    value={value}
    data={data}
    dataTextField="text"
    dataValueField="id"
    label=""
    data-testid="numeric-dropdown"
  />;
};

NumericDropdown.propTypes = {
  sourceName: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  controlProps: PropTypes.object
};

export default NumericDropdown;
