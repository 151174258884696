import styled from 'styled-components';
import { cpSectionBackground } from '../../styles';

export const DisplayNumber = styled.div`
  font-weight: bold;
  font-size: 16px;
`;

export const ControlContainer = styled.div`
  margin-bottom: 0 !important;
`;

export const Div = styled.div`
  background-color: ${cpSectionBackground};
  padding-top: 20px;
`;

export const Ul = styled.ul`
  margin: 0;
  padding-left: 20;
`;

export const PrimaryQuestionDiv = styled.div`
  margin-top: 20px;
`;

export const Header = styled.div`
  font-weight: bold;
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 10px;
`;
