import exists from './exists';
import exceeds from './exceeds';

const maxLength = 300;

export const confirmPasswordMissingMessage = 'Please confirm your password';
export const passwordsDoNotMatchMessage = 'Passwords do not match';
export const exceedsPasswordLengthMessage = `Password cannot exceed ${maxLength} characters`;

export default (password, confirmPassword) => {
  if (!exists(confirmPassword)) return confirmPasswordMissingMessage;

  if (exceeds(confirmPassword, maxLength)) {
    return exceedsPasswordLengthMessage;
  }

  if (password !== confirmPassword) return passwordsDoNotMatchMessage;
  return null;
};
