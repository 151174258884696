import React from 'react';

import LicensingAppointmentDisclosures from './LicensingAppointmentDisclosures';
import DisclosureQuestionsProvider from './provider/DisclosureQuestionsProvider';
import Introduction from './Introduction';

export default props =>
  <DisclosureQuestionsProvider {...props} >
    <Introduction {...props} />
    <LicensingAppointmentDisclosures {...props} />
  </DisclosureQuestionsProvider>;
