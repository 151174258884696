import React from 'react';

import { AgentProfileContext } from '../provider/AgentProfileProvider';
import { StyledHorizontalLine } from '../../styles';
import { MaskedTextInput } from '../../common';
import Address from './Address';

export default React.memo(function ContactInfo() {
  const {
    state,
    usStates,
    homeAddress,
    mailingAddress,
    validationResult,
    handleChange,
    handleBlur
  } = React.useContext(AgentProfileContext);

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-12">
          <StyledHorizontalLine />
        </div>
      </div>
      <Address
        type={homeAddress.type}
        descriptionText={homeAddress.descriptionText}
        disableCheckbox={homeAddress.disableCheckbox}
        name={homeAddress.name}
        source={homeAddress.source}
        validationResult={homeAddress.validation}
        usStates={usStates}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <Address
        type={mailingAddress.type}
        disableCheckbox={mailingAddress.disableCheckbox}
        name={mailingAddress.name}
        source={mailingAddress.source}
        validationResult={mailingAddress.validation}
        usStates={usStates}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <div className="row">
        <div className="col-12">
          <StyledHorizontalLine />
        </div>
      </div>
      <div className="row">
        <div className="col-5">
          <MaskedTextInput
            maskType="phone"
            name="primaryPhoneNumber"
            label="Phone number"
            value={state.primaryPhoneNumber}
            onChange={handleChange}
            onBlur={handleBlur}
            validationState={validationResult.primaryPhoneNumber.validationState}
            errorMessage={validationResult.primaryPhoneNumber.errorMessage}
          />
        </div>
      </div>
    </React.Fragment>
  );
});
