import React from 'react';
import dayjs from 'dayjs';
import DownloadProofLink from './DownloadProofLink';

function formatDate(date) {
  return date ? dayjs(date).format('MM/DD/YYYY') : '-';
}

export const completedCertResultsColumnList = [
  {
    label: 'Finished date',
    dataKey: 'finishedDate',
    width: 200,
    cellRenderer: rowData => formatDate(rowData.finishedDate)
  },
  {
    label: 'Number of attempts',
    dataKey: 'numberOfAttempts',
    width: 200,
    cellRenderer: rowData => rowData.numberOfAttempts
  },
  {
    label: 'Score',
    dataKey: 'certificationScore',
    width: 200,
    cellRenderer: rowData => rowData.certificationScore
  },
  {
    label: 'Proof',
    dataKey: 'proofLink',
    width: 200,
    cellRenderer: rowData => <DownloadProofLink agentCertificationTaskId={rowData.agentCertificationTaskId} />
  }
];
