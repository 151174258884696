import { defaultValidationState, updateValidationResult, isValidEmail, isValidText } from '../common/validations';

export default workEmailDomains => {
  const initialValidationResult = {
    userName: { ...defaultValidationState },
    password: { ...defaultValidationState },
  };

  const update = updateValidationResult(initialValidationResult);

  const validate = value => ({
    userName: () => {
      const errorMessage = isValidEmail(value, workEmailDomains);
      return update(errorMessage, 'userName');
    },
    password: () => {
      const errorMessage = isValidText(value, 'password');
      return update(errorMessage, 'password');
    },
  });

  return {
    initialValidationResult,
    validate
  };
};
