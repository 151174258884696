import React from 'react';
import PropTypes from 'prop-types';

export const MultilineText = ({value}) => {
  const arrayString = value.split(/\r?\n/);  
  if(arrayString.length === 1) {
    return `${value}`;
  }

  return (
    <>
      {
        arrayString.map((element, index) => 
          <React.Fragment key={index}>
            {element}<p/>
          </React.Fragment>
        )
      }
    </>
  );
};

MultilineText.propTypes = {
  value: PropTypes.string,
};

