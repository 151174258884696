import React from 'react';
import PropTypes from 'prop-types';

import Notification from './Notification';

const notification = (message, keyProp = {}) =>
  <Notification
    type="danger"
    message={message}
    {...keyProp}
  />;

/**
 * Component that displays notification(s) on fetch error.
 */
const FetchErrorNotification = React.memo(function FetchErrorNotification({ status }) {
  if (status.some(s => s.code === 500)) return notification('Something went wrong. Please try again later.');

  const statusWithError = status.find(s => s.code >= 300 && s.code < 500);
  if (statusWithError) {
    if (typeof (statusWithError.text) === 'string') {
      return notification(statusWithError.text);
    }

    return (
      <React.Fragment>
        {statusWithError.text.map((msg, idx) => notification(msg.errorMessage, { key: idx }))}
      </React.Fragment>
    );
  }

  return null;
});

FetchErrorNotification.propTypes = {
  /** An array of `fetchStatus` object(s) returned from `useFetch` */
  status: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default FetchErrorNotification;
