import React from 'react';
import styled from 'styled-components';

import { ActionSuccessNotification } from '../common';
import useStepperProvider from './provider/useStepperProvider';
import { submissionCompleted } from './provider/StepperProvider';

const StyledActionSuccessNotification = styled(ActionSuccessNotification)`
  margin: 50px 0;
`;

export default function SubmissionComplete() {
  const { isSubmissionCompleted } = useStepperProvider();
  const submissionCompletedStorage = localStorage.getItem(submissionCompleted);

  if (submissionCompletedStorage) localStorage.setItem(submissionCompleted, true);
  if (isSubmissionCompleted) localStorage.setItem(submissionCompleted, true);
  if (!isSubmissionCompleted && (submissionCompletedStorage === null || submissionCompletedStorage === undefined)) return null;
  
  return <StyledActionSuccessNotification header="Thank you!" message="Your application has been submitted. We will reach out with next steps." />;
}
