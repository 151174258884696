import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './disclosure-questions-styles';

const BulletPoint = ({ text }) => <Styled.Ul><li data-testid="bullet-point">{text}</li></Styled.Ul>;

BulletPoint.propTypes = {
  text: PropTypes.string.isRequired
};

export default BulletPoint;
