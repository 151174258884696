import React from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { Modal } from 'es-components';

import useFetch from './useFetch';
import setTokens, { bearerTokenKey, refreshTokenKey } from './setTokens';
import isUserAuthenticated from './isUserAuthenticated';
import Button from './Button';
import { useSaveProgressProvider } from '../save-progress';
import { CurrentUserContext, initialCurrentUser } from './CurrentUserProvider';

const StyledDiv = styled.div`
  margin-top: 15px;
`;

const StyledCountdown = styled.div`
  margin-top: 15px;
  font-weight: bold;
  font-size: 32px;
`;

const StyledSpan = styled.span`
  font-weight: bold;
`;

const countdownStart = dayjs()
  .set('minute', 0)
  .set('second', 0);

const getTokens = (storage = window.localStorage) => ({
  bearerToken: storage.getItem(bearerTokenKey),
  refreshToken: storage.getItem(refreshTokenKey)
});

function useAuthTimeout(history) {
  const { data, request } = useFetch('post', 'authentication/refreshToken', null, true, true);
  const [shouldShowTimeoutModal, setShouldShowTimeoutModal] = React.useState(false);
  const [countdown, setCountdown] = React.useState(() => countdownStart.add(parseInt(window.waitCountdownInMinutes, 10), 'minute'));
  const authIntervalRef = React.useRef();
  const countdownIntervalRef = React.useRef();
  const { forceSaveProgress, saveProgressFetchStatus } = useSaveProgressProvider();
  const { setCurrentUser } = React.useContext(CurrentUserContext);

  const handleInit = React.useCallback(
    () => {
      setCountdown(countdownStart.add(parseInt(window.waitCountdownInMinutes, 10), 'minute'));
      setShouldShowTimeoutModal(false);
      refreshToken();
      authIntervalRef.current = setInterval(
        () => {
          if (!isUserAuthenticated()) setShouldShowTimeoutModal(true);
        },
        window.autoValidateTokenInSeconds * 1000
      );

      function refreshToken() {
        const tokens = getTokens();
        request.post(tokens);
      }
    },
    [request]
  );

  React.useEffect(
    () => {
      handleInit();

      return () => {
        clearInterval(authIntervalRef.current);
      };
    },
    [handleInit]
  );

  React.useEffect(
    () => {
      if (!data) return;
      setTokens(data);
    },
    [data]
  );

  React.useEffect(
    () => {
      if (shouldShowTimeoutModal) {
        clearInterval(authIntervalRef.current);
        countdownIntervalRef.current = setInterval(
          () => {
            setCountdown(value => value.subtract(1, 'second'));
          },
          1000
        );
      }

      return () => {
        clearInterval(countdownIntervalRef.current);
      };
    },
    [shouldShowTimeoutModal]
  );

  React.useEffect(
    () => {
      if (countdown.minute() === 0 && countdown.second() === 0) {
        forceSaveProgress();
      }
    },
    [countdown, forceSaveProgress]
  );

  React.useEffect(
    () => {
      if (saveProgressFetchStatus.code) {
        setCurrentUser(initialCurrentUser);
        history.replace({
          pathname: '/',
          state: {
            loginMessage: {
              type: 'info',
              header: 'Your session expired',
              message: 'You were automatically logged off due to inactivity.'
            }
          }
        });
      }
    },
    [history, saveProgressFetchStatus.code, setCurrentUser]
  );

  return () =>
    <Modal show={shouldShowTimeoutModal}>
      <Modal.Header hideCloseButton>Are you still there?</Modal.Header>
      <Modal.Body>
        <div className="center">
          <StyledDiv>
            Your session is about to expire. You will be automatically logged off in
          </StyledDiv>
          <StyledCountdown>{countdown.format('m:ss')}</StyledCountdown>
          <StyledDiv>
            To continue your session, select <StyledSpan>Continue</StyledSpan>
          </StyledDiv>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="row">
          <div className="right">
            <Button
              onClick={handleInit}
              label="Continue"
              isOutline
            />
          </div>
        </div>
      </Modal.Footer>
    </Modal >;
}

export default useAuthTimeout;
