import React from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { Heading } from 'es-components';
import wtwTheme from 'es-components-wtw-theme';
import PropTypes from 'prop-types';

import { DashboardContext } from '../provider/DashboardProvider';
import useRefreshToDashboard from '../useRefreshToDashboard';
import { ContentHeader } from '../../header';
import { Notification } from '../../common';
import BackBar from '../BackBar';

const StyledDiv = styled.div`
  background-color: white;
`;
const StyledPageContentDiv = styled.div`
  padding: 15px;
`;

const StyledProfileLabelDiv = styled.div`
  text-align: right;
  width: 30%;
  color: gray;
`;

const StyledProfileSectionLabelDiv = styled(StyledProfileLabelDiv)`
  font-size: 22px;
  margin-bottom: 10px;
`;

const StyledFlexRowDiv = styled.div`
  display: flex;
  width: 100%;
`;

const StyledProfileDataDiv = styled.div`
  font-weight: bold;
  margin-left: 10px;
`;

const maskSSN = ssn => `***-**-${ssn.substring(5)}`;
const UnitedStatesCountryCode = 226;

function mapProfileDataToSections(profileData) {
  const isUSCitizen = profileData.countryOfCitizenshipId === UnitedStatesCountryCode;
  const citizenshipStatus = isUSCitizen ?
    {
      'Citizenship status': 'I am a United States citizen'
    }
    :
    {
      'Citizenship status': 'I am NOT a United States citizen',
      'Country of citizenship': profileData.countryOfCitizenshipName
    };

  const birthInformation = isUSCitizen ?
    {
      'Gender': profileData.genderName,
      'Ethnicity': profileData.ethnicityName,
      'Date of birth': dayjs(profileData.dateOfBirth).format('MMMM D, YYYY'),
      'Country of birth': profileData.countryOfBirthName,
      'State of birth': profileData.stateOfBirth,
      'County of birth': profileData.countyOfBirth
    }
    :
    {
      'Gender': profileData.genderName,
      'Ethnicity': profileData.ethnicityName,
      'Date of birth': dayjs(profileData.dateOfBirth).format('MMMM D, YYYY'),
    };

  const governmentIssuedId =
    {
      'ID type': profileData.governmentIssuedIdTypeName,
      [profileData.governmentIssuedIdTypeId === 2 ? 'Visa number (not Control Number)' : 'USCIS#']: profileData.governmentIssuedIdNumber,
      'Expiration date': dayjs(profileData.governmentIssuedIdExpirationDate).format('MMMM D, YYYY'),
    };

  const mailingAddress = profileData.mailingAddressSameAsHome ?
    {
      'Same as home address': 'Yes'
    }
    :
    {
      'Street address': profileData.mailingAddressLine1,
      'City': profileData.mailingCity,
      'State': profileData.mailingStateCode,
      'Zip code': profileData.mailingZipCode
    };

  return ({
    'Name': {
      'Legal first name': profileData.firstName,
      'Middle name': profileData.middleName,
      'Last name': profileData.lastName
    },
    'Email': {
      'Personal email': profileData.emailAddress
    },
    'SSN': {
      'Social Security number': maskSSN(profileData.ssn)
    },
    'Citizenship status': citizenshipStatus,
    'Birth information': birthInformation,
    'Government issued ID': isUSCitizen ? null : governmentIssuedId,
    'Home address': {
      'Street address': profileData.addressLine1,
      'City': profileData.city,
      'State': profileData.stateCode,
      'Zip code': profileData.zipCode
    },
    'Mailing address': mailingAddress,
    'Phone number': {
      'Phone number': profileData.primaryPhoneNumber
    }
  });
}

function ProfileSection({sectionLabel, data}) {
  return (
    <div>
      <StyledFlexRowDiv>
        <StyledProfileSectionLabelDiv>{sectionLabel}</StyledProfileSectionLabelDiv>
        <div />
      </StyledFlexRowDiv>
      {Object.keys(data).map(key => (
        <StyledFlexRowDiv key={key}>
          <StyledProfileLabelDiv>{key}</StyledProfileLabelDiv>
          <StyledProfileDataDiv>{data[key]}</StyledProfileDataDiv>
        </StyledFlexRowDiv>
      ))}
      <br />
    </div>
  );
}

export default function Profile(props) {
  const { vProfileData, getVProfile, currentUser } = React.useContext(DashboardContext);
  const [profileData, setProfileData] = React.useState(null);

  React.useEffect(() => {
    getVProfile(currentUser.profileId);
  }, [currentUser.profileId, getVProfile]);

  React.useEffect(() => {
    setProfileData(vProfileData);
  }, [vProfileData]);

  useRefreshToDashboard(props);

  if (!profileData) return null;
  const profileSectionData = mapProfileDataToSections(profileData);

  return (
    <StyledDiv className="container">
      <ContentHeader title="Personal profile" />
      <BackBar />
      <StyledPageContentDiv>
        <Heading level={2} underlineColor={wtwTheme.colors.primary}>
          {`Personal information for ${profileData.firstName} ${profileData.lastName}`}
        </Heading>
        <Notification
          type="info"
          isInline
          header="Is something incorrect?"
          message={<span>Contact <a href="mailto:employee.licensure@wtwco.com">employee.licensure@wtwco.com</a></span>}
        />
        {Object.keys(profileSectionData).map(key =>
          !profileSectionData[key] ? null :
            <ProfileSection
              key={key}
              sectionLabel={key}
              data={profileSectionData[key]}
            />)}
      </StyledPageContentDiv>
    </StyledDiv>
  );
}

ProfileSection.propTypes = {
  sectionLabel: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired
};
