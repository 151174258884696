import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ControlResolver } from '../../disclosure-questions/controls';

const textBox = 'Textbox';
const header = 'Header';
const label = 'Label';

const StyledDisplayNumber = styled.div`
  font-weight: bold;
  font-size: 16px;
  margin-right: 0 !important;
`;

const StyledContainer = styled.div`
  margin-left: 0 !important;
`;

const DisclosureQuestionTemplate = ({ displayNumber, controlTypeName, requiresResponse, ...props }) => {
  return (
    <div className="row" data-testid="sub-question">
      {controlTypeName !== header &&
        <StyledDisplayNumber className="col-1">
          {displayNumber ? `${displayNumber}.` : null}
        </StyledDisplayNumber>
      }
      <StyledContainer className="col-8">
        <ControlResolver type={requiresResponse ? label : controlTypeName} {...props} />
        {controlTypeName === textBox && <ControlResolver type={controlTypeName} {...props} />}
      </StyledContainer>
      {requiresResponse && controlTypeName !== textBox &&
        <div className="col-3">
          <ControlResolver type={controlTypeName} {...props} />
        </div>
      }
    </div>
  );
};

DisclosureQuestionTemplate.propTypes = {
  displayNumber: PropTypes.string,
  controlTypeName: PropTypes.string.isRequired,
  requiresResponse: PropTypes.bool.isRequired,
};

export default DisclosureQuestionTemplate;
