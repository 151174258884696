import React from 'react';

import { AgentProfileContext } from '../provider/AgentProfileProvider';
import { StyledHorizontalLine } from '../../styles';
import { Dropdown, Typography, RadioGroup } from '../../common';

export const isUSCitizenOptions = [
  {
    value: true,
    text: 'I am a United States Citizen'
  },
  {
    value: false,
    text: 'I am NOT a United States Citizen'
  },
];

export default React.memo(function CitizenshipStatus() {
  const {
    state,
    countries,
    validationResult,
    handleChange,
    handleBlur
  } = React.useContext(AgentProfileContext);

  const handleCitizenshipChange = ({ target: { name, value } }) => {
    handleChange({
      target: {
        name: 'countryOfCitizenshipId',
        value: ''
      }
    });

    handleChange({
      target: {
        name: name,
        value: value
      }
    });
  };

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-12">
          <StyledHorizontalLine />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <Typography variant="sectionHeader" text="Citizenship Status" />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <RadioGroup
            data-testid="is-us-citizen-radio"
            name="isUSCitizen"
            data={isUSCitizenOptions}
            onChange={handleCitizenshipChange}
            onBlur={handleBlur}
            selectedValue={state.isUSCitizen}
            validationState={validationResult.isUSCitizen.validationState}
            errorMessage={validationResult.isUSCitizen.errorMessage}
          />
        </div>
      </div>
      {state.isUSCitizen === false &&
        <div className="row">
          <div className="col-8">
            <Dropdown
              data-testid="citizenship-country-dropdown"
              label="Country of citizenship"
              data={countries}
              dataTextField="countryName"
              dataValueField="countryId"
              name="countryOfCitizenshipId"
              value={state.countryOfCitizenshipId}
              onChange={handleChange}
              onBlur={handleBlur}
              validationState={validationResult.countryOfCitizenshipId.validationState}
              errorMessage={validationResult.countryOfCitizenshipId.errorMessage}
            />
          </div>
        </div>
      }
    </React.Fragment>
  );
});
