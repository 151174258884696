import React from 'react';
import styled from 'styled-components';
import { Heading } from 'es-components';
import wtwTheme from 'es-components-wtw-theme';

import BackBar from '../dashboard/BackBar';
import { Table, FetchIndicator } from '../common';
import { ContentHeader } from '../header';
import { wtwWhite } from '../styles';
import { PastCertificationsColumnList } from './PastCertificationsColumnList';
import { PastCertificationsContext } from './provider/PastCertificationsProvider';

const StyledContainer = styled.div`
  background-color: ${wtwWhite};
  margin-bottom: 4em;
`;

const StyledHeading = styled(Heading)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const StyledPageContentDiv = styled.div`
  padding: 20px;
`;

export default function PastCertifications() {
  const { pastCertificationsObjs } = React.useContext(PastCertificationsContext);
  const {
    requestPastCertifications,
    allPastCertifications,
    isFetchingPastCertifications,
  } = pastCertificationsObjs;

  React.useEffect(()=>{
    if(allPastCertifications === null) {
      requestPastCertifications();
    }
  }, [allPastCertifications,  requestPastCertifications]);

  return (
    <StyledContainer className="container">
      <ContentHeader title="Colleague Portal Dashboard" />
      <FetchIndicator
        status={[isFetchingPastCertifications]}
        render={() =>
          <React.Fragment>
            <BackBar />
            <StyledPageContentDiv>
              <StyledHeading level={2} underlineColor={wtwTheme.colors.primary}>
                Past certification records
              </StyledHeading>
              {allPastCertifications &&
                <Table
                  data={allPastCertifications}
                  columnConfig={PastCertificationsColumnList}
                  idKey="agentCertificationTaskId"
                  hasAlternatingRowColors
                />
              }
              <br />
            </StyledPageContentDiv>
          </React.Fragment>
        }
      />
    </StyledContainer>
  );
}
