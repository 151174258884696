import React from 'react';
import styled from 'styled-components';
import { Icon, OutlineButton } from 'es-components';

import { CurrentUserContext, IconButton } from '../common';
import { wtwWhite } from '../styles';
import './styles/welcome-section-styles.css';
import { DashboardContext } from './provider/DashboardProvider';

const StyledContainer = styled.div`
  background-color: ${wtwWhite};
  margin-top: 20px;
  margin-bottom: 10px;
  @media only screen and (max-width: 425px): {
    width: 100%;
  }
`;
const StyledIconButton = styled(IconButton)`
  width: 100%;
`;
const StyledProfileIcon = styled(Icon)`
  position: relative;
  top: 1px;
`;
const StyledProfileButtonSpan = styled.span`
  margin-left: 4px;
  font-size: small;
`;
const StyledMdBreakpointContainer = styled.div`
  @media only screen and (max-width: 62em) {
      width: 96%;
  }
`;
const StyledNestedButtonRow = styled.div`
  float: left;
  min-height: 0.125rem;
  position: relative;
  width: 100%;
`;
const StyledIntroSpan = styled.span`
  color: black;
  font-size: 1.7em;
  font-weight: bold;
`;
const StyledButtonPanel = styled.div`
  float: left;
  width: 100%;
`;
const StyledButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap-reverse;
`;

export default function WelcomeSection() {
  const { currentUser } = React.useContext(CurrentUserContext);
  const { viewDisclosureQuestions, viewProfile, viewPastCertifications } =
    React.useContext(DashboardContext);

  return (
    <StyledContainer data-testid="welcome-section">
      <div className="row">
        <div className="col-8">
          <StyledIntroSpan>{`Hello ${currentUser.firstName}, welcome to your dashboard`}</StyledIntroSpan>
        </div>
        <div className="col-4 right">
          <OutlineButton
            id="view-your-profile-button"
            label="View your profile"
            onClick={viewProfile}
          >
            <StyledProfileIcon name="user" size={24} />
            <StyledProfileButtonSpan>View your profile</StyledProfileButtonSpan>
          </OutlineButton>
        </div>
      </div>
      <StyledButtonContainer className="row">
        <StyledMdBreakpointContainer className="col-8">
          <StyledButtonPanel className="container infoButtonPanel">
            <StyledNestedButtonRow className="nestedrow-no-indent">
              <div className="col-6">
                <StyledIconButton
                  data-testid="view-disclosure-questions-button"
                  styleType="primary"
                  iconLeft="element-block"
                  label="Completed disclosures"
                  onClick={viewDisclosureQuestions}
                />
              </div>
              <div className="col-6">
                <StyledIconButton
                  styleType="primary"
                  iconLeft="certificate"
                  label="Past certifications"
                  onClick={viewPastCertifications}
                />
              </div>
            </StyledNestedButtonRow>
          </StyledButtonPanel>
        </StyledMdBreakpointContainer>
      </StyledButtonContainer>
    </StyledContainer>
  );
}
