import React from 'react';
import PropTypes from 'prop-types';
import { QuestionRow } from './QuestionRow';

export const QuestionGrid = ({ disclosureQuestions }) => {
  return disclosureQuestions.map((element) => {
    const { questionId, children } = element;
    return (
      <QuestionRow
        key={questionId}
        subQuestions={children}
        {...element}
      />
    );
  });
};

QuestionGrid.propTypes = {
  disclosureQuestions: PropTypes.array.isRequired
};
