import exists from './exists';

/**
 * Check if a required field has value.
 * Returns a message if validation fails. Otherwise, null.
 */
export default value => (name, message = `Please provide ${name}`) => {
  if (value !== null && typeof (value) === 'object') return null;
  if (value === true || value === false) return null;
  if (!exists((value || '').toString().trim())) return message;
  return null;
};
