import AgentProfile from '../../agent-profile';
import WorkHistory from '../../work-history';
import ReviewAndSubmit from '../../review-and-submit';
import LicensingAppointmentDisclosures from '../../disclosure-questions';
import { NOT_STARTED, IN_PROGRESS } from '../stepper-constants';

export const INITIALIZE_STEPS = 'INITIALIZE_STEPS';
export const UPDATE_SELECTED_STEP = 'UPDATE_SELECTED_STEP';
export const SAVE_AND_NEXT_UPDATE_IN_PROGRESS = 'SAVE_AND_NEXT_UPDATE_IN_PROGRESS';
export const SET_STEP_WARNING_FLAGS = 'SET_STEP_WARNING_FLAGS';

export const AGENT_PROFILE_ROUTE = 'personal-info';
export const WORK_HISTORY_ROUTE = '5-year-history';
export const LICENSING_DISCLOSURES_ROUTE = 'licensing-disclosures';
export const APPOINTMENT_DISCLOSURES_ROUTE = 'appointment-disclosures';
export const REVIEW_AND_SUBMIT_ROUTE = 'review-and-submit';

const routeStepMap = {
  'User Profile': {
    id: 1,
    text: 'Personal Info',
    route: AGENT_PROFILE_ROUTE,
    component: props => AgentProfile({
      nextStep: WORK_HISTORY_ROUTE,
      ...props
    })
  },
  'Work History': {
    id: 2,
    text: '5-Year History',
    route: WORK_HISTORY_ROUTE,
    component: props => WorkHistory(props)
  },
  'Licensing Disclosures': {
    id: 3,
    text: 'Licensing Disclosures',
    route: LICENSING_DISCLOSURES_ROUTE,
    component: (props) =>
      LicensingAppointmentDisclosures({
        ...props,
        type: 'licensing'
      })
  },
  'Appointment Disclosures': {
    id: 4,
    text: 'Appointment Disclosures',
    route: APPOINTMENT_DISCLOSURES_ROUTE,
    component: (props) =>
      LicensingAppointmentDisclosures({
        ...props,
        type: 'appointment'
      })
  }
};

const warningFlagMap = {
  1: 'PersonalInfo',
  2: 'WorkHistory',
  3: 'LicensingDisclosures',
  4: 'AppointmentDisclosures'
};

const getReviewAndSubmitStatus = steps => steps.every(step => step.status !== NOT_STARTED) ? IN_PROGRESS : NOT_STARTED;
const isFirstStepOrNextStep = (index, currentIndex) => index === 0 || index === currentIndex + 1;

const initializeStepsFromRawData = (rawData) => {
  if (!rawData.length) return [];

  let lastInProgressTaskIndex = 0;

  const steps = rawData.map((task, index) => {
    if (task.descriptor === IN_PROGRESS) {
      lastInProgressTaskIndex = index;
    }

    const step = routeStepMap[task.routeName];
    return {
      id: step.id,
      text: step.text,
      warning: false,
      status: task.descriptor,
      route: step.route,
      headerImage: step.headerImage,
      isSelected: false,
      Component: step.component
    };
  });

  steps.push({
    id: 5,
    text: 'Review and Submit',
    warning: false,
    status: getReviewAndSubmitStatus(steps),
    route: REVIEW_AND_SUBMIT_ROUTE,
    isSelected: false,
    Component: ReviewAndSubmit
  });

  if (steps[lastInProgressTaskIndex]) {
    steps[lastInProgressTaskIndex].isSelected = true;
  }

  return steps;
};

export const stepsReducer = (state, action) => {
  switch (action.type) {
    case INITIALIZE_STEPS:
      return initializeStepsFromRawData(action.payload);

    case UPDATE_SELECTED_STEP: {
      const updatedSteps = state.map(step => {
        return {
          ...step,
          isSelected: action.payload === step.text
        };
      });

      const reviewAndSubmitIndex = updatedSteps.findIndex(step => step.id === 5);
      updatedSteps[reviewAndSubmitIndex].status = getReviewAndSubmitStatus(updatedSteps);

      return updatedSteps;
    }

    case SAVE_AND_NEXT_UPDATE_IN_PROGRESS: {
      const updatedSteps = state.map((step, index) => {
        if (isFirstStepOrNextStep(index, action.payload)) {
          return {
            ...step,
            status: IN_PROGRESS
          };
        }
        return step;
      });

      const reviewAndSubmitIndex = updatedSteps.findIndex(step => step.id === 5);
      updatedSteps[reviewAndSubmitIndex].status = getReviewAndSubmitStatus(updatedSteps);

      return updatedSteps;
    }

    case SET_STEP_WARNING_FLAGS: {
      const updatedSteps = state.map(step => {
        return {
          ...step,
          warning: action.payload.indexOf(warningFlagMap[step.id]) > -1
        };
      });

      return updatedSteps;
    }

    default:
      return state;
  }
};
