import styled from 'styled-components';

export const StyledFormTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-top: 25px;
`;

export const StyledControlDiv = styled.div`
  margin-top: 25px;
`;

export const SuccessNotificationDiv = styled.div`
  margin-top: 80px;
  margin-bottom: 80px;
`;

export const LinkExpiredNotificationDiv = styled.div`
  margin-top: 80px;
  margin-bottom: 20px;
`;
