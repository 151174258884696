import React from 'react';
import PropTypes from 'prop-types';

import rowTypeMap from '../../../disclosure-questions/controls/rowTypeMap';
import ControlsProvider from '../../../disclosure-questions/controls/ControlsProvider';
import yearlyDisclosureQuestionsReducer, { INITIALIZE_DISCLOSURE_QUESTIONS } from './yearlyDisclosureQuestionReducer';
import { useFetch } from '../../../common';
import { validateResponses } from './validateResponses';

export const YearlyDisclosuresContext = React.createContext();

export default function YearlyDisclosuresProvider({ 
  yearlyDisclosuresTaskItem,
  handleCompletedYearlyDisclosureTask, 
  ...props
}) {
  const [disclosureQuestions, dispatchDisclosureQuestions] = React.useReducer(yearlyDisclosureQuestionsReducer, []);
  const [responsesAreValidForSubmit, setResponsesAreValidForSubmit] = React.useState(false);
  const [shouldShowSubmitErrorNotification, setShouldShowSubmitErrorNotification] = React.useState(false);
  const [showSubmitSuccessMessage, setShowSubmitSuccessMessage] = React.useState(false);

  const {
    fetchStatus: submitYearlyDisclosureResponsesStatus,
    request: { put: submitYearlyDisclosureResponses },
    data: newDisclosureDocumentationTasks,
    isSubmitting: isSubmittingYearlyDisclosureResponses,
  } = useFetch(
    'put',
    `disclosureQuestions/submit-yearly-disclosure-questions/${yearlyDisclosuresTaskItem.type}`,
    null,
    true,
    true
  );

  React.useEffect(() => {
    if (!submitYearlyDisclosureResponsesStatus.code) return;
    if (submitYearlyDisclosureResponsesStatus.code !== 200) setShouldShowSubmitErrorNotification(true);
    else {
      handleCompletedYearlyDisclosureTask(newDisclosureDocumentationTasks.Value);
      setShowSubmitSuccessMessage(true);
    }
  }, [handleCompletedYearlyDisclosureTask, newDisclosureDocumentationTasks, submitYearlyDisclosureResponsesStatus.code]);

  React.useEffect(() => {
    disclosureQuestions && setResponsesAreValidForSubmit(validateResponses(disclosureQuestions));
  }, [disclosureQuestions]);

  React.useEffect(
    () => {
      dispatchDisclosureQuestions({
        type: INITIALIZE_DISCLOSURE_QUESTIONS,
        payload: yearlyDisclosuresTaskItem.questions
      });
    },
    [yearlyDisclosuresTaskItem.questions]
  );

  const handleSubmitDisclosureQuestions = React.useCallback(() => {
    submitYearlyDisclosureResponses(disclosureQuestions);
  }, [disclosureQuestions, submitYearlyDisclosureResponses]);

  const shouldShowSubQuestion = React.useCallback(
    (parentIndex, indexNumber) => {
      if(indexNumber !== null) {
        return disclosureQuestions[parentIndex].children[indexNumber].response.toLowerCase() === 'yes';
      }

      return disclosureQuestions[parentIndex].response.toLowerCase() === 'yes';
    },
    [disclosureQuestions]
  );

  const renderDisclosureQuestions = React.useCallback(
    (disclosureQuestions, isSubmitted = false, startingIndex = -1, parentSourceName) =>
      disclosureQuestions.map((data, elementIndex) => {
        let previousParentId = `${elementIndex}.`;
        if(parentSourceName) {       
          previousParentId = `${parentSourceName}${elementIndex}.`;
        }

        const parentIndex = ~startingIndex ? startingIndex : elementIndex;
        const sourceName = `${previousParentId}`;

        const descriptions = data.children.filter(child => child.rowType.includes('Description'));
        const subDescriptionRendering = renderDisclosureQuestions(descriptions || [], isSubmitted, elementIndex, previousParentId);

        const controlProps = {
          key: elementIndex,
          sourceName,
          value: data.response,
          isSubmitted: isSubmitted,
          ...data,
          parentIndex,
          shouldShowSubQuestion,
          indexNumber: elementIndex,
          subDescriptionRendering,
          displayChildren: true
        };
        const children = renderDisclosureQuestions(data.children || [], isSubmitted, elementIndex, previousParentId);

        if (data.whereToRender !== 'disclosureQuestion') return null;
        return (
          <ControlsProvider
            key={sourceName}
            data={data}
            sourceName={sourceName}
            dispatch={dispatchDisclosureQuestions}
          >
            {React.createElement(rowTypeMap[data.rowType], controlProps, children)}
          </ControlsProvider>
        );
      }),
    [shouldShowSubQuestion]
  );

  const renderedDisclosureQuestions = React.useMemo(
    () => renderDisclosureQuestions(disclosureQuestions),
    [disclosureQuestions, renderDisclosureQuestions]
  );

  const value = React.useMemo(
    () => ({
      dispatchDisclosureQuestions,
      renderedDisclosureQuestions,
      responsesAreValidForSubmit,
      shouldShowSubmitErrorNotification,
      isSubmitting: isSubmittingYearlyDisclosureResponses,
      showSubmitSuccessMessage,
      yearlyDisclsosuresTaskItem: yearlyDisclosuresTaskItem,
      handleSubmitDisclosureQuestions
    }),
    [
      renderedDisclosureQuestions,
      responsesAreValidForSubmit,
      shouldShowSubmitErrorNotification,
      isSubmittingYearlyDisclosureResponses,
      showSubmitSuccessMessage,
      yearlyDisclosuresTaskItem,
      handleSubmitDisclosureQuestions
    ]
  );

  return <YearlyDisclosuresContext.Provider value={value} {...props} />;
}

YearlyDisclosuresProvider.propTypes = {
  history: PropTypes.object.isRequired,
  yearlyDisclosuresTaskItem: PropTypes.object.isRequired,
  handleCompletedYearlyDisclosureTask: PropTypes.func.isRequired
};
