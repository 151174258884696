import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';

import { isUserAuthenticated } from '../common';

export default function AuthenticatedRoute({ component: Component, ...routeProps }) {
  if (!isUserAuthenticated() && routeProps.location.pathname !== '/') return <Redirect to="/" />;
  return <Route {...routeProps} render={props => <Component {...props} />} />;
}

AuthenticatedRoute.propTypes = {
  component: PropTypes.func.isRequired
};
