import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import BackBar from '../../BackBar';
import { ContentHeader } from '../../../header';
import { wtwWhite } from '../../../styles';
import { DashboardContext } from '../../provider/DashboardProvider';
import useRefreshToDashboard from '../../useRefreshToDashboard';
import YearlyDisclosureQuestions from './YearlyDisclosureQuestions';
import YearlyDisclosuresProvider from './YearlyDisclosuresProvider';

const StyledContainer = styled.div`
  background-color: ${wtwWhite};
  margin-bottom: 4em;
`;

const StyledPageContentDiv = styled.div`
  padding: 20px;
`;

export default function YearlyDisclosures({ history, ...props}) {
  const {
    selectedTask,
    handleCompletedYearlyDisclosureTask
  } = React.useContext(DashboardContext);

  useRefreshToDashboard(props);

  return (
    <StyledContainer className="container">
      <ContentHeader title="Certification Process" />
      <BackBar />
      <StyledPageContentDiv>
        <YearlyDisclosuresProvider
          history={history}
          {...props}
          yearlyDisclosuresTaskItem={selectedTask.value}
          handleCompletedYearlyDisclosureTask={handleCompletedYearlyDisclosureTask}
        >
          <YearlyDisclosureQuestions history={history} />
        </YearlyDisclosuresProvider>
      </StyledPageContentDiv>
    </StyledContainer>
  );
}

YearlyDisclosures.propTypes = {
  history: PropTypes.object.isRequired
};
