import React from 'react';
import PropTypes from 'prop-types';
import { Control, Label, AdditionalHelp, DateInput as ESDateInput } from 'es-components';
import styled from 'styled-components';

const StyledDateInput = styled(ESDateInput)`
  width: 100%;
`;

const DateInput = React.memo(({
  label,
  name,
  onChange,
  onBlur,
  orientation = 'stacked',
  validationState = 'default',
  errorMessage,
  children,
  ...props
}) => {
  const handleOnChange = selectedDate => {
    onChange({
      target: {
        name,
        value: selectedDate.value,
        type: 'dateinput'
      }
    });
  };

  const handleOnBlur = () => {
    if (!onBlur) return;
    onBlur({ target: { name } });
  };

  return (
    <Control orientation={orientation} validationState={validationState}>
      <Label>{label}</Label>
      <StyledDateInput
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        {...props}
      >
        <ESDateInput.Month />
        <ESDateInput.Day />
        <ESDateInput.Year />
      </StyledDateInput>
      {errorMessage && <AdditionalHelp>{errorMessage}</AdditionalHelp>}
      {children}
    </Control>
  );
});

DateInput.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  orientation: PropTypes.string,
  validationState: PropTypes.oneOf(['default', 'success', 'warning', 'danger']),
  errorMessage: PropTypes.string,
  children: PropTypes.node
};

export default DateInput;
