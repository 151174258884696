import React from 'react';
import styled from 'styled-components';
import wtwTheme from 'es-components-wtw-theme';

import Step from './Step';
import useStepperProvider from './provider/useStepperProvider';
import { submissionCompleted } from './provider/StepperProvider';

const StyledStepperContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: top;
  width: 100%;
  position: relative;
  padding-top: 18px;
`;

const StyledStepperHorizontalLine = styled.hr`
  border: none;
  background-color: ${wtwTheme.colors.gray5};
  height: 2px;
  margin: 0;
`;

const Stepper = () => {
  const { steps, isSubmissionCompleted } = useStepperProvider();
  const submissionCompletedStorage = localStorage.getItem(submissionCompleted);

  if(isSubmissionCompleted) return null;
  if(submissionCompletedStorage) return null;
  return (
    <React.Fragment>
      <StyledStepperContainer>
        {steps.map((step, index, steps) => {
          return (
            <Step
              key={step.id}
              number={step.id}
              text={step.text}
              warning={step.warning}
              status={step.status}
              nextStatus={steps[index + 1] ? steps[index + 1].status : ''}
              numberOfSteps={steps.length}
              isSelected={step.isSelected}
              route={step.route}
            />
          );
        }
        )}
      </StyledStepperContainer>
      <StyledStepperHorizontalLine />
    </React.Fragment>
  );
};

export default Stepper;
