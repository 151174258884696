import React from 'react';
import CertificationDetailLink from './CertificationDetailLink';


import { ProductExpander } from '../common';


export const PastCertificationsColumnList = [
  {
    label: 'Year',
    dataKey: 'certYear',
    width: 60,
    cellRenderer: (rowData) => rowData.certYear,
  },
  {
    label: 'Carrier',
    dataKey: 'carrierAlias',
    width: 300,
    cellRenderer: (rowData) => (rowData.carrierAlias ? rowData.carrierAlias : rowData.carrierName),
  },
  {
    label: 'Product',
    dataKey: 'products',
    width: 160,
    cellRenderer: (rowData) => <ProductExpander rowData={rowData} idKey={rowData.agentCertificationTaskId} />,
  },
  {
    label: 'Status',
    dataKey: 'agentCertificationTaskStatusName',
    width: 80,
    cellRenderer: (rowData) => rowData.agentCertificationTaskStatusName,
  },
  {
    label: '',
    dataKey: 'agentCertificationTaskId',
    width: 200,
    cellRenderer: (rowData) => (
      <CertificationDetailLink certification={rowData}/>
    ),
  },
];

