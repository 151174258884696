import React from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { Heading, Notification } from 'es-components';

import { Button } from '../../common';
import { DashboardContext } from '../provider/DashboardProvider';
import useRefreshToDashboard from '../useRefreshToDashboard';
import { ContentHeader } from '../../header';
import BackBar from '../BackBar';
import { wtwViolet } from '../../styles';

const StyledDiv = styled.div`
  background-color: var(--wtwWhite);
`;

const StyledExamDetailDiv = styled.div`
  margin: 20px;
  padding-bottom: 20px;
`;

const StyledButton = styled(Button)`
  &&& {
    margin: 0 0 0 2em;
  }
`;

export default function ExamInfo(props) {
  const { examInfo, dismissExamInfoHandler } = React.useContext(DashboardContext);
  useRefreshToDashboard(props);

  const dismissExamHandler = () => dismissExamInfoHandler(examInfo.taskId);

  const dateTimeArray = examInfo ?
    dayjs(examInfo.dateTimeOfTest).format('MM/DD/YYYYTh:mm A')
      .split('T')
    : null;
  const date = dateTimeArray ? dateTimeArray[0] : null;
  const time = dateTimeArray ? dateTimeArray[1] : null;

  return examInfo ? (
    <StyledDiv className="container">
      <ContentHeader title="Colleague Portal Dashboard" />
      <BackBar />
      <StyledExamDetailDiv className="container">
        <div className="col-12">
          <div className="row">
            <div className="col-12">
              <Heading level={3} underlineColor={wtwViolet}>Resident license exam information</Heading>
            </div>
          </div>
          <div className="col-12">
            <div className="row">
              <div className="col-12">
                <Notification type="warning" includeIcon >
                  <strong>Warning!</strong> This information will be deleted on {examInfo.expirationDate}.
                  <StyledButton
                    isOutline
                    label="Delete now"
                    onClick={dismissExamHandler}
                    styleType="warning"
                  />
                </Notification>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-4">
              Exam type:
            </div>
            <div className="col-8">
              {examInfo.examName}
            </div>
          </div>
          <div className="row">
            <div className="col-4">
              Date:
            </div>
            <div className="col-8">
              {date}
            </div>
          </div>
          <div className="row">
            <div className="col-4">
              Time:
            </div>
            <div className="col-8">
              {time}
            </div>
          </div>
          <div className="row">
            <div className="col-4">
              Location:
            </div>
            <div className="col-8">
              <div>{examInfo.locationName}</div>
              {examInfo.locationAddress.split('\n').map((addressLine, i) => <div key={i}>{addressLine}</div>)}
            </div>
          </div>
          {examInfo.confirmationNumber &&
            <div className="row">
              <div className="col-4">
                Confirmation number:
              </div>
              <div className="col-8">
                {examInfo.confirmationNumber}
              </div>
            </div>
          }
          <div className="row">
            <div className="col-4">
              Finger print required:
            </div>
            <div className="col-8">
              {examInfo.isFingerprintRequired ? 'Yes' : 'No'}
            </div>
          </div>
        </div>
      </StyledExamDetailDiv>
    </StyledDiv>
  ) : <></>;
}
