import React from 'react';
import styled from 'styled-components';
import wtwTheme from 'es-components-wtw-theme';
import { Icon } from 'es-components';
import { cpLightGrey } from '../../styles';

const StyledContainerDiv = styled.div`
  padding-bottom: 30px;
  margin-bottom: 50px;
`;
const StyledMainText = styled.div`
  text-align: center;
  color: ${wtwTheme.colors.primary};
  font-weight: bold;
  font-size: 1.5em;
`;
const StyledSubText = styled.div`
  text-align: center;
  color: #c110a0;
  font-size: 1.2em;
`;
const StyledBigIconDiv = styled.div`
  position: relative;
  text-align: center;
  height: 110px;
  margin-bottom: 20px;
`;
const StyledInboxIcon = styled(Icon)`
  color: ${cpLightGrey};
`;

export default function AllTasksComplete() {
  return (
    <StyledContainerDiv data-testid="all-tasks-complete">
      <div className="row">
        <StyledBigIconDiv>
          <StyledInboxIcon name="thumbs-up" size={120} />
        </StyledBigIconDiv>
      </div>
      <div className="row">
        <StyledMainText>Nice Job!</StyledMainText>
        <StyledSubText>You finished all your tasks!</StyledSubText>
      </div>
    </StyledContainerDiv>
  );
}
