import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { PopoverLink, Popover, Icon } from 'es-components';

import {
  cpLightGrey,
  vbSoftwareGray6,
  StyledLink,
} from '../styles';
import {
  Button,
  TextInput,
  MaskedTextInput,
  Checkbox,
  FetchErrorNotification,
  Typography
} from '../common';
import { PasswordGuidelines } from '../password-reset';

const StyledGreyHorizontalRule = styled.hr({
  color: vbSoftwareGray6
});
const StyledGreySpan = styled.span({
  color: cpLightGrey
});
const StyledFlexButtonContainer = styled.div({
  display: 'flex',
  justifyContent: 'flex-end'
});
const onBlurNotificationClass = css`
  @media (min-width: 721px) {
    width: 270px;
  }
  @media (min-width: 776px) {
    width: 310px;
  }
`;

const QuestionMarkPopoverIcon = () =>
  <Popover
    name="popover-question-icon"
    title="Why do we need this?"
    hasAltCloseButton
    content="This is used to verify your identity. All information you provide is secure. Please see our privacy policy for more information."
    suppressUnderline
    ariaLabel="Icon"
    placement="top"
    renderTrigger={({ ref, toggleShow, isOpen }) => (
      <PopoverLink
        onClick={toggleShow}
        aria-expanded={isOpen}
        ref={ref}
        suppressUnderline
        styleType="primary"
      >
        <span aria-hidden="true"><Icon name="question-circle" size={22} /></span>
      </PopoverLink>
    )}
  />;

export default function RegistrationForm({
  registration,
  handleChange,
  handleBlur,
  handleReview,
  validationResult,
  fetchStatus,
}) {
  const preventCopyPaste = event => event.preventDefault();

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-12">
          <Typography variant="sectionHeader" text="Please register to continue your application process." />
        </div>
      </div>
      <div className="row">
        <div className="col-4">
          <TextInput
            label="Legal first name"
            name="firstName"
            data-testid="firstName"
            value={registration.firstName}
            onChange={handleChange}
            onBlur={handleBlur}
            validationState={validationResult.firstName.validationState}
            errorMessage={validationResult.firstName.errorMessage}
          />
        </div>
        <div className="col-4">
          <TextInput
            label="Middle name"
            name="middleName"
            data-testid="middleName"
            value={registration.hasNoMiddleName ? '' : registration.middleName}
            onChange={handleChange}
            onBlur={handleBlur}
            validationState={validationResult.middleName.validationState}
            errorMessage={validationResult.middleName.errorMessage}
            disabled={registration.hasNoMiddleName}
          >
            <Checkbox
              name="hasNoMiddleName"
              data-testid="hasNoMiddleName"
              text="I don't have a middle name"
              checked={registration.hasNoMiddleName}
              onChange={handleChange}
            />
          </TextInput>
        </div>
        <div className="col-4">
          <TextInput
            label="Last name"
            name="lastName"
            data-testid="lastName"
            value={registration.lastName}
            onChange={handleChange}
            onBlur={handleBlur}
            validationState={validationResult.lastName.validationState}
            errorMessage={validationResult.lastName.errorMessage}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-8">
          <TextInput
            label="Email address"
            name="emailAddress"
            data-testid="emailAddress"
            variant="email"
            notificationHeader="Personal Email Address"
            notificationText="Please use the same email address you provided on your employment application."
            notificationClassName={onBlurNotificationClass}
            value={registration.emailAddress}
            onChange={handleChange}
            onBlur={handleBlur}
            validationState={validationResult.emailAddress.validationState}
            errorMessage={validationResult.emailAddress.errorMessage}
          />
        </div>
        <div className="col-4">
          <MaskedTextInput
            label="Social Security number"
            popoverIcon={<QuestionMarkPopoverIcon />}
            name="ssn"
            data-testid="ssn"
            maskType="ssnum"
            notificationHeader="Social Security Number"
            notificationText="Your Social Security Number is used to verify your identity. All information on our site is secure and is subject to HIPAA guidelines. Please see our privacy policy for more information."
            notificationClassName={onBlurNotificationClass}
            value={registration.ssn}
            onChange={handleChange}
            onBlur={handleBlur}
            validationState={validationResult.ssn.validationState}
            errorMessage={validationResult.ssn.errorMessage}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <Typography variant="sectionHeader" text="Please create a password. Your password must have:" />
          <PasswordGuidelines shouldAddPadding={false} />
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <TextInput
            label="Password"
            name="password"
            data-testid="password"
            type="password"
            value={registration.password}
            onChange={handleChange}
            onBlur={handleBlur}
            validationState={validationResult.password.validationState}
            errorMessage={validationResult.password.errorMessage}
          />
        </div>
        <div className="col-6">
          <TextInput
            label="Confirm password"
            name="confirmPassword"
            data-testid="confirmPassword"
            type="password"
            onPaste={preventCopyPaste}
            onCopy={preventCopyPaste}
            onDrag={preventCopyPaste}
            onDrop={preventCopyPaste}
            value={registration.confirmPassword}
            onChange={handleChange}
            onBlur={handleBlur}
            validationState={validationResult.confirmPassword.validationState}
            errorMessage={validationResult.confirmPassword.errorMessage}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <FetchErrorNotification status={[fetchStatus]} />
        </div>
      </div>
      <StyledGreyHorizontalRule />
      <div className="row">
        <div className="col-7">
          <StyledGreySpan>
            Already have an account? <StyledLink href="/">login here</StyledLink>
          </StyledGreySpan>
        </div>
        <StyledFlexButtonContainer className="col-5">
          <Button
            onClick={handleReview}
            label="Next"
            isOutline
          />
        </StyledFlexButtonContainer>
      </div>
    </React.Fragment>
  );
}

RegistrationForm.propTypes = {
  registration: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleReview: PropTypes.func.isRequired,
  validationResult: PropTypes.object.isRequired,
  fetchStatus: PropTypes.object,
  registrationResult: PropTypes.object,
  authenticationResult: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object
};
