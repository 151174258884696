import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router';

import { CurrentUserContext, RenderWithAuthTimeout } from '../common';
import DashboardProvider from './provider/DashboardProvider';
import Dashboard from './Dashboard';
import DisclosureQuestionDetail from './task-detail/DisclosureQuestionDetail';
import ActionComplete from './task-detail/ActionComplete';
import CurrentYearDisclosuresSummary from './task-detail/CurrentYearDisclosuresSummary';
import CertificationTaskDetail from '../certification-task';
import ExamInfo from './task-detail/ExamInfo';
import ExamInfoDismissed from './task-detail/ExamInfoDismissed';
import Profile from './task-detail/Profile';
import PastCertifications from '../past-certifications/PastCertifications';
import PastCertificationDetail from '../past-certifications/PastCertificationDetail';
import YearlyDisclosures from './task-detail/yearly-disclosures/YearlyDisclosures';
import DisclosureTaskSchedule from './task-detail/DisclosureTaskSchedule';

const dashboardRoutes = [
  {
    path: 'tasks',
    Component: Dashboard,
  },
  {
    path: 'disclosure-question-detail',
    Component: DisclosureQuestionDetail,
  },
  {
    path: 'action-complete',
    Component: ActionComplete,
  },
  {
    path: 'current-year-disclosures',
    Component: CurrentYearDisclosuresSummary,
  },
  {
    path: 'certification-task-detail',
    Component: CertificationTaskDetail,
  },
  {
    path: 'exam-info',
    Component: ExamInfo,
  },
  {
    path: 'profile',
    Component: Profile,
  },
  {
    path: 'all-past-certifications',
    Component: PastCertifications
  },
  {
    path: 'exam-info-dismissed',
    Component: ExamInfoDismissed,
  },
  {
    path: 'past-certification-detail',
    Component: PastCertificationDetail
  },
  {
    path: 'yearly-disclosures',
    Component: YearlyDisclosures
  },
  {
    path: 'disclosure-task-schedule',
    Component: DisclosureTaskSchedule
  },
];

export default function DashboardRoutes({ history, match }) {
  const { initCurrentUser } = React.useContext(CurrentUserContext);

  React.useEffect(
    () => {
      initCurrentUser();
    },
    [initCurrentUser]
  );

  const routes = React.useMemo(
    () => {
      return dashboardRoutes.map(({ path, Component }) =>
        <Route
          key={path}
          path={`${match.url}/${path}`}
          render={props => <RenderWithAuthTimeout history={history} render={() => <Component {...props} />} />}
        />
      );
    },
    [history, match.url]
  );

  return (
    <DashboardProvider history={history}>
      <Switch>
        {routes}
        <Redirect to={`${match.url}/tasks`} />
      </Switch>
    </DashboardProvider>
  );
}

DashboardRoutes.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};
