import React from 'react';
import PropTypes from 'prop-types';

import { StyledDiv, StyledMobileBannerDiv, StyledTitleDiv, StyledForm, StyledLink } from '../login/login-styles';
import { StyledFormTitle, SuccessNotificationDiv, LinkExpiredNotificationDiv } from './password-reset-styles';
import { FetchIndicator, useForm, Notification, TextInput, Button, FetchErrorNotification, useFetch } from '../common';
import validation from './password-reset-validations';
import SuccessNotification from './SuccessNotification';
import PasswordGuidelines from './PasswordGuidelines';

const initialState = {
  password: '',
  confirmPassword: '',
};

export default function PasswordReset({ history, match }) {
  const { request: { post: validateToken }, fetchStatus: statusTokenValidation, isSubmitting: isTokenValidating } = useFetch('post', 'updatePassword', null, false, true);
  const { request, fetchStatus, isSubmitting } = useFetch('put', 'updatePassword', null, false, true);
  const [passwordResetValidation, setPasswordResetValidation] = React.useState(() => validation());
  const { state, handleChange, handleBlur, handleSubmit, validationResult } = useForm(initialState, resetPassword, undefined, passwordResetValidation);

  React.useEffect(
    () => {
      const { params: { token } } = match;
      validateToken({ token });
    },
    [match, validateToken]
  );

  React.useEffect(
    () => {
      setPasswordResetValidation(
        validation => ({
          ...validation,
          validate: validation.setValidation(state.password)
        })
      );
    },
    [state.password]
  );

  function resetPassword() {
    request.put({
      ...state,
      token: match.params.token
    });
  }

  const shouldShowCreateNewPassword = statusTokenValidation.code === 200
    || [fetchStatus.text, statusTokenValidation.text].every(t => t === undefined);

  function renderForm() {
    if (fetchStatus.code === 200) {
      return (
        <StyledForm type="password-reset">
          <SuccessNotificationDiv>
            <SuccessNotification
              header="Success"
              message="Your password has been reset."
            />
          </SuccessNotificationDiv>
          <div className="row">
            <div className="col-12 right">
              <Button
                label="Login"
                isOutline
                onClick={() => history.replace({ pathname: '/' })}
              />
            </div>
          </div>
        </StyledForm>
      );
    }
    
    if (shouldShowCreateNewPassword) {
      return (
        <StyledForm type="password-reset">
          <StyledFormTitle className="row">
            <div className="col-12">Create new password</div>
          </StyledFormTitle>
          <div className="row">
            <div className="col-12">
              Your password must have:
              <PasswordGuidelines />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <TextInput
                data-testid="password"
                type="password"
                name="password"
                label="New password"
                validationState={validationResult.password.validationState}
                errorMessage={validationResult.password.errorMessage}
                value={state.password}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <TextInput
                data-testid="confirm-password"
                type="password"
                name="confirmPassword"
                label="Confirm password"
                validationState={validationResult.confirmPassword.validationState}
                errorMessage={validationResult.confirmPassword.errorMessage}
                value={state.confirmPassword}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-4 left">
              <StyledLink id="register-here" href="/">Back to login</StyledLink>
            </div>
            <div className="col-8 right">
              <Button
                data-testid="reset-password"
                type="submit"
                label="Submit"
                isOutline
                onClick={handleSubmit}
                isSubmitting={isSubmitting}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <FetchErrorNotification status={[fetchStatus]} />
            </div>
          </div>
        </StyledForm>
      );
    }

    if (statusTokenValidation?.text !== 'OK') {
      return (
        <StyledForm type="password-reset" className="col-12">
          <LinkExpiredNotificationDiv>
            <Notification
              type="danger"
              message="This reset link has expired"
            />
          </LinkExpiredNotificationDiv>
          <div className="row">
            <div className="col-12 right">
              <Button
                label="Request new link"
                isOutline
                onClick={() => history.replace({ pathname: '/forgot-password' })}
              />
            </div>
          </div>
        </StyledForm>
      );
    }

    return null;
  }

  return (
    <StyledDiv>
      <div className="container">
        <StyledTitleDiv>Individual Marketplace Colleague Portal</StyledTitleDiv>
        <FetchIndicator status={[isTokenValidating]} render={() => renderForm()} />
      </div>
      <StyledMobileBannerDiv />
    </StyledDiv>
  );
}

PasswordReset.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};
