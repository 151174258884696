import React from 'react';
import PropTypes from 'prop-types';

import useFetch from '../../common/useFetch';
import rowTypeMap from '../controls/rowTypeMap';
import disclosureQuestionsReducer, { INITIALIZE_DISCLOSURE_QUESTIONS } from './disclosureQuestionReducers';
import ControlsProvider from '../controls/ControlsProvider';
import { SAVE_AND_NEXT_UPDATE_IN_PROGRESS, useStepperProvider } from '../../onboarding-steps';
import { useSaveProgressProvider } from '../../save-progress';

export const DisclosureQuestionsContext = React.createContext();
const initialData = [];

const processDataForPutRequest = questions =>
  questions.map(q => {
    var newQuestionObj = Object.assign({}, q);
    delete newQuestionObj.text;

    if (newQuestionObj.children.length > 0) {
      newQuestionObj.children = processDataForPutRequest(q.children);
    }

    return newQuestionObj;
  });

const DisclosureQuestionsProvider = props => {
  const { data: rawData, isFetching: isFetchingDisclosureQuestions } = useFetch('get', `disclosureQuestions/${props.type}`, initialData);
  const { fetchStatus: submitStatus, request: { put: submit }, isSubmitting } = useFetch('put', `disclosureQuestions/${props.type}`, null, true, true);
  const [shouldShowIntroduction, setShouldShowIntroduction] = React.useState(false);
  const [disclosureQuestions, dispatchDisclosureQuestions] = React.useReducer(disclosureQuestionsReducer, []);
  const [shouldSubmit, setShouldSubmit] = React.useState(false);
  const { dispatchSteps, licensingDisclosureError, appointmentDisclosureError, clearReviewAndSubmitError } = useStepperProvider();
  const { setProgress, saveProgress } = useSaveProgressProvider();
  const saveProgressRef = React.useRef(saveProgress);

  React.useEffect(
    () => {
      dispatchDisclosureQuestions({
        type: INITIALIZE_DISCLOSURE_QUESTIONS,
        payload: rawData
      });
      const initialShouldShowInstruction = rawData.length > 0 && rawData.every(disclosureQuestion => disclosureQuestion.response === '');
      setShouldShowIntroduction(initialShouldShowInstruction);
    },
    [rawData]
  );

  React.useEffect(
    () => {
      saveProgressRef.current();
    },
    []
  );

  React.useEffect(
    () => {
      setProgress({
        resource: `disclosureQuestions/${props.type}`,
        state: processDataForPutRequest(disclosureQuestions)
      });
    },
    [disclosureQuestions, props.type, setProgress]
  );

  React.useEffect(
    () => {
      if (!shouldSubmit) return;
      const questionsWithTextRemoved = processDataForPutRequest(disclosureQuestions);
      submit(questionsWithTextRemoved);
    },
    [disclosureQuestions, shouldSubmit, submit]
  );

  React.useEffect(
    () => {
      if (submitStatus.code === 200) {
        setProgress({
          resource: `disclosureQuestions/${props.type}`,
          state: processDataForPutRequest(disclosureQuestions)
        });
        if (props.type === 'licensing') {
          clearReviewAndSubmitError('LicensingDisclosures');
          dispatchSteps({
            type: SAVE_AND_NEXT_UPDATE_IN_PROGRESS,
            payload: 2
          });
          props.history.push('appointment-disclosures');
        } else {
          clearReviewAndSubmitError('AppointmentDisclosures');
          dispatchSteps({
            type: SAVE_AND_NEXT_UPDATE_IN_PROGRESS,
            payload: 3
          });
          props.history.push('review-and-submit');
        }
      }
    },
    [clearReviewAndSubmitError, disclosureQuestions, dispatchSteps, props.history, props.type, setProgress, submitStatus.code, submitStatus.text]
  );

  const shouldShowSubQuestion = React.useCallback(
    (parentIndex, indexNumber) => {
      if(indexNumber !== null) {
        return disclosureQuestions[parentIndex].children[indexNumber].response.toLowerCase() === 'yes';
      }

      return disclosureQuestions[parentIndex].response.toLowerCase() === 'yes';
    },
    [disclosureQuestions]
  );

  const renderDisclosureQuestions = React.useCallback(
    (disclosureQuestions, isSubmitted = false, startingIndex = -1, parentSourceName) =>
      disclosureQuestions.map((data, elementIndex) => {
        let previousParentId = `${elementIndex}.`;
        if(parentSourceName) {       
          previousParentId = `${parentSourceName}${elementIndex}.`;
        }

        const parentIndex = ~startingIndex ? startingIndex : elementIndex;
        const sourceName = `${previousParentId}`;
        const reviewAndSubmitError = props.type === 'licensing' ? licensingDisclosureError.fieldError[data.questionId] : appointmentDisclosureError.fieldError[data.questionId];
      
        const descriptions = data.children.filter(child => child.rowType.includes('Description'));
        const subDescriptionRendering = renderDisclosureQuestions(descriptions || [], isSubmitted, elementIndex, previousParentId);

        const controlProps = {
          key: elementIndex,
          sourceName,
          value: data.response,
          isSubmitted: isSubmitted,
          ...data,
          parentIndex,
          shouldShowSubQuestion: shouldShowSubQuestion,
          indexNumber: elementIndex,
          subDescriptionRendering,
          displayChildren: true
        };
        const children = renderDisclosureQuestions(data.children || [], isSubmitted, elementIndex, previousParentId);
        
        if (data.whereToRender !== 'disclosureQuestion') return null;
        return (
          <ControlsProvider
            key={sourceName}
            data={data}
            sourceName={sourceName}
            reviewAndSubmitError={reviewAndSubmitError}
            dispatch={dispatchDisclosureQuestions}
            
          >
            {React.createElement(rowTypeMap[data.rowType], controlProps, children)}
          </ControlsProvider>
        );
      }),
    [appointmentDisclosureError.fieldError, licensingDisclosureError.fieldError, props.type, shouldShowSubQuestion]
  );

  const fetchingStatus = React.useMemo(
    () => [isFetchingDisclosureQuestions],
    [isFetchingDisclosureQuestions]
  );

  const renderedDisclosureQuestions = React.useMemo(
    () => renderDisclosureQuestions(disclosureQuestions),
    [disclosureQuestions, renderDisclosureQuestions]
  );

  const value = React.useMemo(
    () => ({
      dispatchDisclosureQuestions,
      renderedDisclosureQuestions,
      shouldShowSubQuestion,
      shouldShowIntroduction,
      setShouldShowIntroduction,
      questionType: props.type,
      setShouldSubmit,
      submitStatus,
      isSubmitting,
      fetchingStatus
    }),
    [fetchingStatus, isSubmitting, props.type, renderedDisclosureQuestions, shouldShowIntroduction, shouldShowSubQuestion, submitStatus]
  );

  return <DisclosureQuestionsContext.Provider value={value} {...props} />;
};

DisclosureQuestionsProvider.propTypes = {
  type: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired
};

export default DisclosureQuestionsProvider;
