import {
  defaultValidationState,
  updateValidationResult,
  isValidEmail,
  isValidText,
  isValidSsn,
  isValidPassword,
  isValidConfirmPassword
} from '../common/validations';

export default function () {
  const initialValidationResult = {
    firstName: { ...defaultValidationState },
    middleName: { ...defaultValidationState },
    lastName: { ...defaultValidationState },
    emailAddress: { ...defaultValidationState },
    ssn: { ...defaultValidationState },
    password: { ...defaultValidationState },
    confirmPassword: { ...defaultValidationState },
  };

  const update = updateValidationResult(initialValidationResult);

  const setValidation = (workEmailDomains = [], emailAddressValue = '', middleNameValue = '', passwordValue = '', confirmPasswordValue = '', hasNoMiddleName = false) => {
    const validate = (value) => ({
      firstName: () => update(isValidText(value, 'first name'), 'firstName'),
      middleName: () => update((hasNoMiddleName) ? null : isValidText(value, 'middle name'), 'middleName'),
      hasNoMiddleName: () => update((value) ? null : isValidText(middleNameValue, 'middle name'), 'middleName'),
      lastName: () => update(isValidText(value, 'last name'), 'lastName'),
      emailAddress: () => {
        let returnVal = update(isValidEmail(value, workEmailDomains), 'emailAddress');
        if (passwordValue) returnVal = update(isValidPassword(passwordValue, value), 'password');
        return returnVal;
      },
      ssn: () => update(isValidSsn(value), 'ssn'),
      password: () => {
        let returnVal = update(isValidPassword(value, emailAddressValue), 'password');
        if (confirmPasswordValue) returnVal = update(isValidConfirmPassword(value, confirmPasswordValue), 'confirmPassword');
        return returnVal;
      },
      confirmPassword: () => update(isValidConfirmPassword(passwordValue, value), 'confirmPassword')
    });

    return validate;
  };

  return {
    initialValidationResult,
    validate: null,
    setValidation
  };
}
