import React from 'react';
import PropTypes from 'prop-types';

import DisclosureQuestions from './DisclosureQuestions';
import useDisclosureQuestionsProvider from './provider/useDisclosureQuestionsProvider';
import NeedHelp from './NeedHelp';

const LicensingAppointmentDisclosures = ({ history }) => {
  const { shouldShowIntroduction } = useDisclosureQuestionsProvider();
  if (shouldShowIntroduction) return null;
  return (
    <React.Fragment>
      <NeedHelp />
      <hr />
      <DisclosureQuestions history={history} />
    </React.Fragment>
  );
};

LicensingAppointmentDisclosures.propTypes = {
  history: PropTypes.object.isRequired
};

export default LicensingAppointmentDisclosures;
