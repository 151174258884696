import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const white = 'var(--wtwWhite, #fff)';
const largeScreenMq = '@media (min-width: 898px)';

const StyledBufferDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  ${largeScreenMq} {
    justify-content: space-between;
    flex-direction: row;
  }
`;

const StyledLinksDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  ${largeScreenMq} {
    flex-direction: row;
  }
`;

const StyledFooterDiv = styled.div`
  flex-shrink: 0;
  padding: 45px 0;
  font-size: 14px;
  background-color: var(--wtwBlack, #000);
  color: var(--vb-software-gray-6, #979797);
`;

const StyledLink = styled.a`
  color: ${white};
  text-decoration: none;
  margin-bottom: 1em;

  &:last-of-type {
    margin-bottom: 3em;
  }

  &:hover,
  &:visited {
    color: ${white};
  }

  &:hover {
    text-decoration: underline;
  }

  ${largeScreenMq} {
    margin-bottom: 0;
    & + a {
      margin-left: 2em;
    }
  }
`;

const StyledCopyrightDiv = styled.div`
  color: white;
  margin-bottom: 3em;
`;

const Footer = ({ year }) =>
  <StyledFooterDiv>
    <div className="container">
      <StyledBufferDiv>
        <StyledLinksDiv>
          <StyledLink
            id="privacy-policy"
            href="https://secure.icbdr.com/images/talentnetwork/applicantprivacynotice.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </StyledLink>
          <StyledLink
            id="healthcare-site"
            href="https://marketplace.viabenefits.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            marketplace.viabenefits.com
          </StyledLink>
          <StyledLink
            id="medicare-site"
            href="https://my.viabenefits.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            my.viabenefits.com
          </StyledLink>
        </StyledLinksDiv>
        <StyledCopyrightDiv>
          &copy; {year} Willis Towers Watson. All Rights Reserved
        </StyledCopyrightDiv>
      </StyledBufferDiv>
      <p>
        At Willis Towers Watson, we are committed to maintaining an environment of Equal Opportunity and Affirmative Action. If you need a reasonable accommodation to
access the information provided on this web site, please contact the office where the position is located for further assistance. If you need an accommodation to
interview for a position or at any stage of the recruiting process, please contact your recruiter.
      </p>

      <p>
        Willis Towers Watson believes that effectively managing a diverse workforce is vital to our business strategy. Our continued success depends greatly on our ability to
fully and effectively employ qualified persons, regardless of race, color, religion, marital status, sexual orientation, age, disability, veteran status, military status,
ancestry, gender, gender identity or expression, or any other characteristic protected by applicable human rights or equal opportunity legislation.
      </p>

      <p>
        We have an obligation to our organization, ourselves and our clients to hire and develop the best people we can find. We will continually review our policies and
practices to ensure that all areas of the employment process (including recruiting, hiring, work assignments, compensation, benefits, promotions, transfers, company-sponsored development programs and overall workplace experience) are free from discriminatory practices. We are committed to equal employment opportunities
at Willis Towers Watson.
      </p>

    </div>
  </StyledFooterDiv>;

Footer.propTypes = {
  year: PropTypes.number
};

Footer.defaultProps = {
  year: new Date(Date.now()).getFullYear()
};

export default Footer;
