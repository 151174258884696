import React from 'react';
import PropTypes from 'prop-types';

import * as Styled from './disclosure-questions-styles';
import ControlResolver from './ControlResolver';
import { MultilineText } from '../../common';

const PrimaryQuestion = props => {
  const { children, displayNumber, parentIndex, shouldShowSubQuestion, text, controlTypeName, subDescriptionRendering } = props;

  const showSecondLevelQuestion = shouldShowSubQuestion(parentIndex, null);
  return (
    <React.Fragment>
      <Styled.PrimaryQuestionDiv className="row">
        {
          controlTypeName === 'Header' && 
          <div className="col-12">
            {text}
          </div>
        }
        {
          controlTypeName !== 'Header' && 
          <>
            <Styled.DisplayNumber className="col-1">
              {displayNumber}
            </Styled.DisplayNumber>
            <div className="col-8">
              <MultilineText value={text} />
            </div>
            <Styled.ControlContainer className="col-3">
              <ControlResolver type={controlTypeName} {...props} />
            </Styled.ControlContainer>
          </>
        }
      </Styled.PrimaryQuestionDiv>
      {showSecondLevelQuestion && children}
      {!showSecondLevelQuestion && subDescriptionRendering}
    </React.Fragment>
  );
};

PrimaryQuestion.propTypes = {
  children: PropTypes.node,
  subDescriptionRendering: PropTypes.node,
  displayNumber: PropTypes.string,
  text: PropTypes.string.isRequired,
  controlTypeName: PropTypes.string.isRequired,
  sourceName: PropTypes.string.isRequired,
  parentIndex: PropTypes.number.isRequired,
  shouldShowSubQuestion: PropTypes.func.isRequired,
};

export default PrimaryQuestion;
