import React from 'react';

import { ProductExpander } from '../common';
import TextWithTooltip from './TextTooltip';

export const taskDetailColumnList = [
  {
    label: 'Products',
    dataKey: 'products',
    width: 150,
    cellRenderer: rowData => <ProductExpander rowData={rowData} idKey={rowData.agentCertificationTaskId}/>,
  },
  {
    label: 'Username',
    dataKey: 'username',
    width: 150,
    cellRenderer: rowData => <TextWithTooltip text={rowData.username}>{rowData.username}</TextWithTooltip>,
  },
  {
    label: 'Password',
    dataKey: 'password',
    width: 150,
    cellRenderer: rowData => <TextWithTooltip text={rowData.password}>{rowData.password}</TextWithTooltip>,
  },
  {
    label: 'NPN',
    dataKey: 'nationalProducerNumber',
    width: 100,
    cellRenderer: rowData => rowData.nationalProducerNumber,
  },
  {
    label: 'Lic. number',
    dataKey: 'licenseNumber',
    width: 120,
    cellRenderer: rowData => rowData.licenseNumber?.split(',').map(l => <div key={l}>{l}</div>),
  },
  {
    label: 'Writing number',
    dataKey: 'writingNumber',
    width: 150,
    cellRenderer: rowData => rowData.writingNumber?.split(',').map(w => <div key={w}>{w}</div>),
  },
];
