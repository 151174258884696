import React from 'react';
import styled from 'styled-components';

import useReviewAndSubmitProvider from './provider/useReviewAndSubmitProvider';
import { Typography } from '../common';
import { Icon } from 'es-components';

const StyledDiv = styled.div`
  padding-left: 100px;
  padding-right: 100px;
`;
const WarningIcon = styled(Icon)`
  color: ${props => props.textColor};
  vertical-align: middle;
  margin-left: 5px;
`;

const ErrorsFound = () => {
  const { shouldShowErrorsFound } = useReviewAndSubmitProvider();

  if (!shouldShowErrorsFound) return null;

  return (
    <StyledDiv data-testid="errors-found-div">
      <Typography
        variant="sectionHeader"
        text="It looks like there could be some information missing."
      />
      <br />
      <div className="row">
        You can double-check each section by clicking the circles above.
      </div>
      < br />
      <div className="row">
        Double-check the sections above that have this symbol:
        <WarningIcon name="exclamation-sign"
          textColor="red"
          size={26}
        />
      </div>
      < br />
      <div className="row">
        After you check those sections, return here to try again.
      </div>
      <br />
    </StyledDiv>
  );
};

export default ErrorsFound;
