import React from 'react';

import useWorkHistoryProvider from '../provider/useWorkHistoryProvider';
import { historyTypes } from '../work-history-constants';
import { Typography, RadioGroup, Button } from '../../common';

export default function TypeSelection() {
  const { data, handleTypeSelectionNext, selectedType, setSelectedType } = useWorkHistoryProvider();

  return (
    <div data-testid="type-selection">
      <div>
        <Typography variant="sectionHeader" text={data.length > 1 ? 'During this period I was:' : 'Right now I am:'} />
      </div>
      <br />
      <div className="row">
        <RadioGroup
          data={historyTypes}
          onClick={event => setSelectedType(event.target.value)}
          selectedValue={selectedType}
          name="typeSelection"
        />
      </div>
      <div className="row">
        <div className="col-12 right">
          <Button
            data-testid="type-selection-next"
            label="Next"
            isOutline
            disabled={!selectedType}
            onClick={() => handleTypeSelectionNext()}
          />
        </div>
      </div>
    </div>
  );
}
