import React from 'react';
import styled from 'styled-components';

import { DashboardContext } from '../provider/DashboardProvider';
import { ContentHeader } from '../../header';
import { ActionSuccessNotification, Button, Notification } from '../../common';
import BackBar from '../BackBar';
import useRefreshToDashboard from '../useRefreshToDashboard';

const StyledDiv = styled.div`
  background-color: var(--wtwWhite);
`;

const StyledActionSuccessNotification = styled(ActionSuccessNotification)`
  margin-top: 40px;
`;

const StyledUndoDiv = styled.div`
  padding: 40px 0 30px 0;
`;

const StyledUndoText = styled.div`
  font-size: 18px;
  opacity: 0.5;
  @media (min-width: 720px) {
    margin: 18px 0 0 0 !important;
    text-align: right;
    float: right;
  }
  @media (max-width: 720px) {
    text-align: center;
  }
`;

export default function ActionComplete(props) {
  const { selectedTask, undoCompletedTask, requestDisclosurePostStatusUndo } = React.useContext(DashboardContext);

  const message = selectedTask.value ? `You've ${selectedTask.value.text.replace('Provide', 'provided')}!` : '';
  useRefreshToDashboard(props);

  return (
    <StyledDiv className="container">
      <ContentHeader title="Provide Documentation" />
      <BackBar />
      <StyledActionSuccessNotification header="Thank you!" message={message} />
      <StyledUndoDiv className="row">
        {requestDisclosurePostStatusUndo.code && requestDisclosurePostStatusUndo.code !== 200 ?
          <div className="row">
            <div className="col-12">
              <Notification
                isInline
                includeIcon
                type="danger"
                header="Sorry, there was an error."
                message="Please try again later."
              />
            </div>
          </div> : <></> }
        <StyledUndoText className="col-7">
          Completed task on accident?
        </StyledUndoText>
        <div className="col-5 left">
          <Button
            label="UNDO"
            isOutline
            onClick={undoCompletedTask}
          />
        </div>
      </StyledUndoDiv>
    </StyledDiv>
  );
}
