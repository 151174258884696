export const TYPE_EMPLOYMENT = '1';
export const TYPE_EDUCATION = '2';
export const TYPE_UNEMPLOYMENT = '3';
export const historyTypes = [
  {
    value: TYPE_EMPLOYMENT,
    text: 'EMPLOYED (full-time, part-time, self-employed, or military)',
    title: 'Employment Period'
  },
  {
    value: TYPE_EDUCATION,
    text: 'Enrolled in full/part-time EDUCATION (if also working during this time, choose EMPLOYED)',
    title: 'Education Period'
  },
  {
    value: TYPE_UNEMPLOYMENT,
    text: 'UNEMPLOYED (more than 30 days)',
    title: 'Unemployment Period'
  },
];
