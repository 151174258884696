import React from 'react';

const useOnHover = (ref) => {
  const [isHovering, setIsHovering] = React.useState(false);
  React.useEffect(
    () => {
      const currentRef = ref.current;

      const handleMouseEnter = () => {
        setIsHovering(true);
      };

      const handleMouseLeave = () => {
        setIsHovering(false);
      };

      currentRef.addEventListener('mouseover', handleMouseEnter, true);
      currentRef.addEventListener('mouseleave', handleMouseLeave, true);

      return () => {
        currentRef.removeEventListener('mouseover', handleMouseEnter);
        currentRef.removeEventListener('mouseleave', handleMouseLeave);
      };
    }, [ref]
  );
  return isHovering;
};

export default useOnHover;
