import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import {apiUrl} from '../common';

const StyledLink = styled.a`
  color: var(--wtwViolet);
`;

export default function DownloadProofLink({ agentCertificationTaskId }) {
  return (
    <StyledLink
      href={`${apiUrl}/Certifications/download-certification-proof/${agentCertificationTaskId}`}
      download
    >
      Download Proof
    </StyledLink>
  );
}

DownloadProofLink.propTypes = {
  agentCertificationTaskId: PropTypes.number.isRequired
};
