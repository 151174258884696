import React from 'react';
import PropTypes from 'prop-types';

import { useFetch, setObjectDefault, CurrentUserContext } from '../../common';
import initialWorkHistory from './initialWorkHistory';
import { SAVE_AND_NEXT_UPDATE_IN_PROGRESS, useStepperProvider } from '../../onboarding-steps';
import { useSaveProgressProvider } from '../../save-progress';

export const WorkHistoryContext = React.createContext();
export const ADD_NEW_PERIOD = 'ADD_NEW_PERIOD';
export const INIT_NEW_PERIOD = 'INIT_NEW_PERIOD';
export const DELETE_PERIOD = 'DELETE_PERIOD';
export const UPDATE_PERIOD = 'UPDATE_PERIOD';

const INIT_WORK_HISTORY = 'INIT_WORK_HISTORY';

// for making `Add another event period` panel always available
const newPeriodPanel = {
  panelId: 0,
  workHistoryTypeId: ADD_NEW_PERIOD
};

function workHistoryReducer(state, action) {
  switch (action.type) {
    case INIT_WORK_HISTORY:
      return [
        ...action.payload,
        newPeriodPanel
      ];
    case INIT_NEW_PERIOD:
      return [
        ...state.slice(0, state.length - 1),
        action.payload,
        ...state.slice(state.length - 1)
      ];
    case DELETE_PERIOD: {
      const index = state.findIndex(s => s.panelId === action.payload.panelId);
      return [
        ...state.slice(0, index),
        ...state.slice(index + 1),
      ];
    }
    case UPDATE_PERIOD: {
      const index = state.findIndex(s => s.panelId === action.payload.panelId);
      return [
        ...state.slice(0, index),
        action.payload,
        ...state.slice(index + 1),
      ];
    }
    default:
      return state;
  }
}

export default function WorkHistoryProvider(props) {
  const { currentUser } = React.useContext(CurrentUserContext);
  const { data: workHistory, request: { get: getWorkHistory }, isFetching: isFetchingWorkHistory } = useFetch('get', 'workHistory', null, true, true);
  const { fetchStatus: submitStatus, request: { put: submit }, isSubmitting } = useFetch('put', 'workHistory', null, true, true);

  const { data: countries, request: requestCountries, isFetching: isFetchingCountries } = useFetch('get', 'countries', null, true, true);
  const { data: usStates, request: requestUsStates, isFetching: isFetchingUsStates } = useFetch('get', 'usStates', null, true, true);
  const { dispatchSteps, clearReviewAndSubmitError } = useStepperProvider();

  const [data, dispatchWorkHistory] = React.useReducer(workHistoryReducer, []);
  const [shouldShowIntroduction, setShouldShowIntroduction] = React.useState(null);
  const [shouldSetDrawerActiveKey, setShouldSetDrawerActiveKey] = React.useState(true);
  const [activeKey, setActiveKey] = React.useState();
  const [selectedType, setSelectedType] = React.useState(null);
  const [shouldSubmit, setShouldSubmit] = React.useState(false);

  const { setProgress, saveProgress } = useSaveProgressProvider();
  const saveProgressRef = React.useRef(saveProgress);

  React.useEffect(
    () => {
      saveProgressRef.current();
    },
    []
  );

  React.useEffect(
    () => {
      const state = data.filter(d => d.panelId > 0);
      setProgress({
        resource: 'workHistory',
        state
      });
    },
    [data, setProgress]
  );

  React.useEffect(
    () => {
      getWorkHistory(currentUser.profileId);
    },
    [currentUser.profileId, getWorkHistory]
  );

  React.useEffect(
    () => {
      if (!workHistory) return;
      const payload = workHistory.length
        ? workHistory.map(setObjectDefault).map((value, index) => ({
          ...value,
          panelId: index + 1
        }))
        : workHistory;
      dispatchWorkHistory({
        type: INIT_WORK_HISTORY,
        payload
      });
      setShouldShowIntroduction(!payload.length);
      getCountriesAndUsStates();

      async function getCountriesAndUsStates() {
        await Promise.all([requestCountries.get(), requestUsStates.get()]);
      }
    },
    [requestCountries, requestUsStates, workHistory]
  );

  React.useEffect(
    () => {
      if (!shouldSubmit) return;
      const payload = data.filter(d => d.panelId > 0);
      const modifiedPayloadObject = payload.map( item => {
        const { profileId: agentProfileId, ...rest } = item;
        return { agentProfileId,
          ...rest };
      });
      submit(modifiedPayloadObject);
    },
    [data, shouldSubmit, submit]
  );

  React.useEffect(
    () => {
      if (submitStatus.code === 200) {
        clearReviewAndSubmitError('WorkHistory');
        setProgress(null);
        dispatchSteps({
          type: SAVE_AND_NEXT_UPDATE_IN_PROGRESS,
          payload: 1
        });
        props.history.push('licensing-disclosures');
      }
    },
    [clearReviewAndSubmitError, dispatchSteps, props.history, setProgress, submitStatus.code, submitStatus.text]
  );

  const handleTypeSelectionNext = React.useCallback(
    () => {
      setShouldSetDrawerActiveKey(true);
      setSelectedType(null);
      const panelId = Math.max(...data.map(value => value.panelId)) + 1;
      dispatchWorkHistory({
        type: INIT_NEW_PERIOD,
        payload: {
          ...initialWorkHistory,
          profileId: currentUser.profileId,
          panelId: panelId,
          employerName: '',
          isCurrentEmployer: data.length === 1,
          workHistoryTypeId: parseInt(selectedType, 10)
        }
      });
    },
    [currentUser.profileId, data, selectedType]
  );

  const fetchingStatus = React.useMemo(
    () => [isFetchingWorkHistory, isFetchingCountries, isFetchingUsStates],
    [isFetchingCountries, isFetchingUsStates, isFetchingWorkHistory]
  );

  const value = React.useMemo(
    () => ({
      data,
      countries,
      usStates,
      dispatchWorkHistory,
      shouldShowIntroduction,
      setShouldShowIntroduction,
      shouldSetDrawerActiveKey,
      setShouldSetDrawerActiveKey,
      activeKey,
      setActiveKey,
      selectedType,
      setSelectedType,
      handleTypeSelectionNext,
      setShouldSubmit,
      submitStatus,
      fetchingStatus,
      isSubmitting
    }),
    [data, countries, usStates, shouldShowIntroduction, shouldSetDrawerActiveKey, activeKey, selectedType, handleTypeSelectionNext, submitStatus, fetchingStatus, isSubmitting]
  );

  return <WorkHistoryContext.Provider value={value} {...props} />;
}

WorkHistoryProvider.propTypes = {
  history: PropTypes.object.isRequired,
};
