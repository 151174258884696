import React from 'react';

import useReviewAndSubmitProvider from '../provider/useReviewAndSubmitProvider';
import { WORK_HISTORY_ROUTE } from '../../onboarding-steps';
import { StyledHorizontalLine } from '../../styles';
import { Typography, Button } from '../../common';
import { historyTypeHeader } from '../../work-history/forms/WorkHistoryForm';
import { TYPE_EMPLOYMENT, TYPE_EDUCATION } from '../../work-history/work-history-constants';
import Row from './Row';
import { formatDate } from './AgentProfileSection';

export default function WorkHistorySection() {
  const { history, workHistory, getCountry, getUsState, getProvince } = useReviewAndSubmitProvider();

  if (!workHistory.length) return null;
  return (
    <React.Fragment>
      <StyledHorizontalLine />
      <div className="row">
        <div className="col-6">
          <Typography variant="sectionHeader" text="5-Year History" />
        </div>
        <div className="col-6 right">
          <Button
            label="Edit section"
            isOutline
            onClick={() => history.push(WORK_HISTORY_ROUTE)}
            data-testid="edit-section"
          />
        </div>
      </div>
      {workHistory.map(state => {
        const isEmployment = state.workHistoryTypeId.toString() === TYPE_EMPLOYMENT;
        
        const usState = getUsState(state.stateCode);

        const config = {
          isEmployment,
          employerNameLabel: isEmployment ? 'Employer name:' : 'Name of educational institution:',
          currentlyCheckboxLabel: isEmployment
            ? 'This is my current employer:'
            : 'I am currently attending:'
        };

        return (
          <React.Fragment key={state.workHistoryId}>
            <div className="row">
              <div className="col-12">
                <Typography variant="sectionHeader" text={historyTypeHeader[state.workHistoryTypeId]} />
              </div>
            </div>
            {[TYPE_EMPLOYMENT, TYPE_EDUCATION].includes(state.workHistoryTypeId.toString()) ?
              <React.Fragment>
                <Row label={config.employerNameLabel} value={state.employerName} />
                {config.isEmployment && <Row label="Last position held:" value={state.positionHeld} />}
                <Row label="Start date:" value={formatDate(state.startDate)} />
                <Row label="End date:" value={state.isCurrentEmployer ? '' : formatDate(state.endDate)} />
                <Row label={config.currentlyCheckboxLabel} value={state.isCurrentEmployer ? 'Yes' : 'No'} />
                <Row label="Country:" value={getCountry(state.countryId).countryName} />
                {state.stateCode && usState && <Row label="State:" value={usState.stateName} />}
                {state.provinceId && <Row label="Province:" value={getProvince(state.countryId, state.provinceId).provinceName} />}
                <Row label="City:" value={state.city} />
              </React.Fragment> :
              <React.Fragment>
                <Row label="Start date:" value={formatDate(state.startDate)} />
                <Row label="End date:" value={state.isCurrentEmployer ? '' : formatDate(state.endDate)} />
                <Row label="I am currently unemployed:" value={state.isCurrentEmployer ? 'Yes' : 'No'} />
              </React.Fragment>
            }
            <br />
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
}
