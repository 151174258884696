import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Control, Label, DateInput as EsDateInput, AdditionalHelp } from 'es-components';

const StyledDateInput = styled(EsDateInput)`
  width: 100%;
`;

const MonthYearInput = React.memo(({
  label,
  name,
  onChange,
  onBlur,
  orientation = 'stacked',
  validationState = 'default',
  errorMessage,
  disabled = false,
  children,
  ...props
}) => {
  const handleOnChange = monthYear => {
    onChange({
      target: {
        name,
        value: monthYear.value
      }
    });
  };

  const handleOnBlur = () => {
    if (!onBlur || disabled) return;
    onBlur({ target: { name } });
  };

  return (
    <Control orientation={orientation} validationState={validationState}>
      <Label>{label}</Label>
      <StyledDateInput
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        {...props}
      >
        <EsDateInput.Month disabled={disabled} data-testid={`${name}-month-select`} />
        <EsDateInput.Year disabled={disabled} data-testid={`${name}-year-input`} />
      </StyledDateInput>
      {errorMessage && <AdditionalHelp>{errorMessage}</AdditionalHelp>}
      {children}
    </Control>
  );
});

MonthYearInput.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  orientation: PropTypes.string,
  validationState: PropTypes.string,
  errorMessage: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.node,
};

export default MonthYearInput;
