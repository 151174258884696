import PropTypes from 'prop-types';
import controlMap from './controlMap';
import useControlsProvider from './useControlsProvider';

const ControlResolver = ({ type, ...props }) => {
  const { handleBlur, validationResult, handleChange, dispatch } = useControlsProvider();
  const controlProps = {
    handleBlur,
    validationResult,
    dispatchDisclosureQuestions: dispatch,
    handleChange
  };
  return controlMap[type]({
    ...props,
    controlProps
  });
};

ControlResolver.propTypes = {
  type: PropTypes.string.isRequired
};

export default ControlResolver;
