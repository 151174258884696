import JwtDecode from 'jwt-decode';

export default function isUserAuthenticated(storage = window.localStorage, currentTime = (new Date().getTime()) / 1000) {
  const token = storage.getItem('bearer-token');
  if (token !== null) {
    const jwt = JwtDecode(token);
    return jwt.exp >= currentTime;
  }
  return false;
}
