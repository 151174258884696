import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, withRouter, Redirect } from 'react-router';

import useStepperProvider from './provider/useStepperProvider';
import { NOT_STARTED } from './stepper-constants';
import { UPDATE_SELECTED_STEP } from './provider/stepperReducers';
import { RenderWithAuthTimeout } from '../common';

const isNotFirstStep = index => index !== 0;

const StepperRoutes = ({ history, match }) => {
  const { steps, dispatchSteps, isSubmissionCompleted } = useStepperProvider();

  const routes = steps.map(({ id, status, route, Component }, index) => {
    if (status === NOT_STARTED && isNotFirstStep(index)) return null;
    return (
      <Route
        key={id}
        path={`${match.url}/${route}`}
        render={props =>
          <RenderWithAuthTimeout
            history={props.history}
            render={() => <Component {...props} />}
          />
        }
      />
    );
  });

  const previousRouteRef = React.useRef(null);  // `Flag` for initial load

  React.useEffect(
    () => {
      if (!steps.length) return;

      const getSelectedRoute = () => {
        // On initial load, get selected route by isSelected prop
        if (!previousRouteRef.current) return steps.find(step => step.isSelected);

        // On subsequent history.push, get selected route based on the url
        const splitPathnames = history.location.pathname.split('/');
        return steps.find(step => splitPathnames.includes(step.route));
      };

      const selectedRoute = getSelectedRoute();
      if (!previousRouteRef.current) {
        // On initial load, redirect to the selected route
        history.replace(`${match.url}/${selectedRoute.route}`);
      } else {
        const previousPathname = `${match.url}/${previousRouteRef.current}`;
        if (history.location.pathname !== previousPathname) {
          // On subsequent history.push, dispatch to change higlighted step
          dispatchSteps({
            type: UPDATE_SELECTED_STEP,
            payload: selectedRoute.text
          });
        }
      }

      // On every render, set selectedRouteRef to the selected route
      previousRouteRef.current = selectedRoute.route;
    },
    [dispatchSteps, history, match, steps]
  );

  if (isSubmissionCompleted) return null;
  return (
    <Switch>
      {routes}
      {routes.length ? <Redirect to={`${match.url}/${previousRouteRef.current}`} /> : null}
    </Switch>
  );
};

StepperRoutes.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

export default withRouter(StepperRoutes);
