import React from 'react';

import { WorkHistoryFormContext } from './WorkHistoryForm';
import {
  TextInput,
  MonthYearInput,
  Typography,
  Checkbox,
  Dropdown
} from '../../common';

export default React.memo(function EmploymentEducation() {
  const {
    state,
    countries,
    usStates,
    provinces,
    validationResult,
    handleChange,
    handleBlur,
    config,
    shouldDisplayUsStates
  } = React.useContext(WorkHistoryFormContext);

  const [endDateKey, setEndDateKey] = React.useState(0);

  const handleClearCurrentDate = event => {
    handleChange({
      target: {
        name: 'endDate',
        value: null
      }
    });
    handleChange(event);
    setEndDateKey(currentKey => currentKey + 1);
  };

  return (
    <div data-testid={`${config.isEmployment ? 'employment' : 'education'}-period`}>
      <div className="row">
        <div className="col-12">
          <TextInput
            data-testid="employer-name"
            label={config.employerNameLabel}
            name="employerName"
            value={state.employerName}
            onChange={handleChange}
            onBlur={handleBlur}
            validationState={validationResult.employerName.validationState}
            errorMessage={validationResult.employerName.errorMessage}
          />
        </div>
      </div>
      {config.isEmployment &&
        <div className="row">
          <div className="col-12">
            <TextInput
              data-testid="position-held"
              label="Last position held"
              name="positionHeld"
              value={state.positionHeld}
              onChange={handleChange}
              onBlur={handleBlur}
              validationState={validationResult.positionHeld.validationState}
              errorMessage={validationResult.positionHeld.errorMessage}
            />
          </div>
        </div>
      }
      <div className="row">
        <div className="col-5">
          <MonthYearInput
            label="Start date"
            name="startDate"
            defaultValue={state.startDate && new Date(state.startDate)}
            onChange={handleChange}
            onBlur={handleBlur}
            validationState={validationResult.startDate.validationState}
            errorMessage={validationResult.startDate.errorMessage}
          />
        </div>
        <div className="col-5">
          <MonthYearInput
            key={endDateKey}
            label="End date"
            name="endDate"
            defaultValue={state.endDate && new Date(state.endDate)}
            onChange={handleChange}
            onBlur={handleBlur}
            validationState={validationResult.endDate.validationState}
            errorMessage={validationResult.endDate.errorMessage}
            disabled={state.isCurrentEmployer}
          >
            <Checkbox
              name="isCurrentEmployer"
              text={config.currentlyCheckboxLabel}
              checked={state.isCurrentEmployer}
              onChange={handleClearCurrentDate}
            />
          </MonthYearInput>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <Typography variant="sectionHeader" text={config.locationTitle} />
        </div>
      </div>
      <div className="row">
        {countries &&
          <div className="col-4">
            <Dropdown
              data-testid="countries"
              label="Country"
              data={countries}
              dataTextField="countryName"
              dataValueField="countryId"
              name="countryId"
              value={state.countryId}
              onChange={handleChange}
              onBlur={handleBlur}
              validationState={validationResult.countryId.validationState}
              errorMessage={validationResult.countryId.errorMessage}
            />
          </div>
        }
        {shouldDisplayUsStates &&
          <div className="col-4">
            <Dropdown
              data-testid="us-states"
              label="State"
              data={usStates}
              dataTextField="stateName"
              dataValueField="stateCode"
              name="stateCode"
              value={state.stateCode}
              onChange={handleChange}
              onBlur={handleBlur}
              validationState={validationResult.stateCode.validationState}
              errorMessage={validationResult.stateCode.errorMessage}
            />
          </div>
        }
        {!!provinces.length &&
          <div className="col-4">
            <Dropdown
              data-testid="provinces"
              label="Province"
              data={provinces}
              dataTextField="provinceName"
              dataValueField="provinceId"
              name="provinceId"
              value={state.provinceId}
              onChange={handleChange}
              onBlur={handleBlur}
              validationState={validationResult.provinceId.validationState}
              errorMessage={validationResult.provinceId.errorMessage}
            />
          </div>
        }
        <div className="col-4">
          <TextInput
            data-testid="city"
            label="City"
            name="city"
            value={state.city}
            onChange={handleChange}
            onBlur={handleBlur}
            validationState={validationResult.city.validationState}
            errorMessage={validationResult.city.errorMessage}
          />
        </div>
      </div>
    </div>
  );
});
