import React, { useContext, useState } from 'react';
import styled from 'styled-components';

import { CurrentUserContext, Button, Typography, FetchIndicator, licensureEmail, Notification } from '../../common';
import { DashboardContext } from '../provider/DashboardProvider';
import { ContentHeader } from '../../header';
import { StyledFoamGreenDiv } from '../styles/dashboard-styles';
import { wtwWhite } from '../../styles';
import BackBar from '../BackBar';
import DisclosureQuestionTemplate from './DisclosureQuestionTemplate';
import { ControlsProvider } from '../../disclosure-questions/controls';
import useRefreshToDashboard from '../useRefreshToDashboard';
import { Checkbox } from 'es-components';

const documentationEmail = 'employee.licensure@wtwco.com';

const StyledContainer = styled.div`
  background-color: ${wtwWhite};
  margin-bottom: 4em;
`;
const StyledGreenDivWithPadding = styled(StyledFoamGreenDiv)`
  padding: .5em 0px;
`;
const StyledContentHeader = styled(ContentHeader)`
  background-color: #d9e6dc;
`;
const StyledBoldSpan = styled.span`
  font-weight: bold;
  overflow-wrap: break-word;
`;
const StyledDangerDiv = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  border: 3px solid #c1131f;
  background-color: #fae7e7;
  margin-bottom: 35px;
`;
const StyledDisclosureQuestionDiv = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  color: #4e4e4e;
  background-color: #ebebeb;
  border: 2px inset #d7d7d750;
  border-radius: 4px;
`;
const StyledThinGreyHr = styled.hr`
  border: 1px solid #979797;
  width: 100%;
`;
const StyledLastRow = styled.div`
  padding-bottom: .5em;
`;
const StyledWordWrapDiv = styled.div`
  overflow-wrap: break-word;
`;
const renderQuestion = (primaryQuestion, renderedTo = 'disclosureQuestion', startingIndex = -1) => {
  let disclosureQuestions = primaryQuestion.children.filter(q => q.whereToRender === renderedTo);
  if (primaryQuestion.whereToRender === renderedTo) disclosureQuestions = [primaryQuestion, ...disclosureQuestions];
  return disclosureQuestions.map((data, elementIndex) => {
    const parentIndex = ~startingIndex ? startingIndex : elementIndex;
    const sourceName = `${parentIndex}.${data.parentId ? elementIndex : ''}`;
    const controlProps = {
      key: elementIndex,
      sourceName,
      value: data.response,
      isSubmitted: true,
      ...data,
      parentIndex,
      disabled: true
    };
    return (
      <ControlsProvider
        key={sourceName}
        data={data}
        sourceName={sourceName}
        reviewAndSubmitError=""
      >
        <DisclosureQuestionTemplate {...controlProps} />
      </ControlsProvider>
    );
  });
};

export default function DisclosureQuestionDetail(props) {
  const { currentUser } = useContext(CurrentUserContext);
  const { disclosureQuestionDetail, fetchDisclosureQuestionDetailStatus, completeTask, requestDisclosurePostStatus } = useContext(DashboardContext);
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);

  useRefreshToDashboard(props);

  return (
    <StyledContainer className="container">
      <StyledContentHeader title="Provide Documentation" />
      <FetchIndicator
        status={fetchDisclosureQuestionDetailStatus}
        render={() => {
          if (!disclosureQuestionDetail) return null;
          const emailSubject = `${currentUser.firstName} ${currentUser.lastName}, ${disclosureQuestionDetail.questionType} ${disclosureQuestionDetail.displayNumber} `;
          const sectionHeaderText = `Provide documentation for ${disclosureQuestionDetail.questionType} ${disclosureQuestionDetail.displayNumber}`;

          return (
            <React.Fragment>
              <BackBar />
              <StyledGreenDivWithPadding className="row">
                <div className="col-7">
                  <Typography variant="sectionHeader"
                    text={sectionHeaderText}
                  />
                </div>
              </StyledGreenDivWithPadding>
              <div className="row">
                <div className="col-9">
                  Due to your response to a question, you must provide documentation explaining your answer.
                </div>
              </div>
              <div className="row">
                <div className="col-4">
                  <StyledBoldSpan>This is the question you answered:</StyledBoldSpan>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <StyledDisclosureQuestionDiv>
                    {renderQuestion(disclosureQuestionDetail)}
                  </StyledDisclosureQuestionDiv>
                </div>
              </div>
              <div className="row">
                <StyledWordWrapDiv className="col-12">
                  If this is an error, please contact <a href={`mailto:${licensureEmail}`}>{licensureEmail}</a>
                </StyledWordWrapDiv>
              </div>
              <div className="row">
                <div className="col-12">
                  <StyledThinGreyHr />
                  {renderQuestion(disclosureQuestionDetail, 'dashboard')}
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <StyledDangerDiv>
                    <div className="row">
                      <div className="col-12">
                        <StyledBoldSpan>
                          IMPORTANT: Email the requested documentation to <a href={`mailto:${documentationEmail}?subject=${emailSubject}`}>{documentationEmail}</a>
                        </StyledBoldSpan>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <StyledBoldSpan>
                          Use the following text for the email subject line:{` ${emailSubject}`}
                        </StyledBoldSpan>
                      </div>
                    </div>
                  </StyledDangerDiv>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <Checkbox
                    data-testid="enable-submit-chbx"
                    checked={!submitButtonDisabled}
                    onChange={() => { setSubmitButtonDisabled(!submitButtonDisabled); }}
                  >
                    I have sent the requested documents and information as prescribed above.
                  </Checkbox>
                </div>
              </div>
              {requestDisclosurePostStatus.code && requestDisclosurePostStatus.code !== 200 ?
                <div className="row">
                  <div className="col-12">
                    <Notification
                      isInline
                      includeIcon
                      type="danger"
                      header="Sorry, there was an error."
                      message="Please try again later."
                    />
                  </div>
                </div>
                : <></>
              }
              <StyledLastRow className="row">
                <div className="col-2" />
                <div className="col-10 right">
                  <Button
                    data-testid="submit-btn"
                    disabled={submitButtonDisabled}
                    styleType="primary"
                    label="Submit"
                    onClick={completeTask}
                  />
                </div>
              </StyledLastRow>
            </React.Fragment>
          );
        }}
      />
    </StyledContainer>
  );
}
