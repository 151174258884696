import React from 'react';
import { Switch, Route, Redirect } from 'react-router';

import { OnboardingSteps } from '../onboarding-steps';
import { DashboardRoutes } from '../dashboard';
import Login from '../login/Login';
import { Registration } from '../registration';
import { PasswordResetRequest, PasswordReset } from '../password-reset';
import AuthenticatedRoute from './AuthenticatedRoute';

const Routes = () => {
  return (
    <Switch>
      <Route
        exact
        path="/"
        render={props => <Login {...props} />}
      />
      <Route
        exact
        path="/verify/:token?"
        component={Login}
      />
      <Route
        path="/forgot-password"
        component={PasswordResetRequest}
      />
      <Route
        path="/update-password/:token"
        component={PasswordReset}
      />
      <AuthenticatedRoute
        path="/onboarding-steps"
        component={OnboardingSteps}
      />
      <AuthenticatedRoute
        path="/dashboard"
        component={DashboardRoutes}
      />
      <Route
        path="/registration"
        component={Registration}
      />
      <Redirect to="/" />
    </Switch>
  );
};

export default Routes;
