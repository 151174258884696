import React from 'react';
import PropTypes from 'prop-types';

import { defaultSubmissionValidation } from '../../common/validations';
import { useStepperProvider } from '../../onboarding-steps';
import { useFetch, FetchIndicator, CurrentUserContext } from '../../common';
import { unitedStatesCountryName, visaGovernmentIssuedIdNumberLabel, defaultGovernmentIssuedIdNumberLabel } from '../../agent-profile/provider/AgentProfileProvider';
import { useSaveProgressProvider } from '../../save-progress';

const initialSection = {
  agentProfile: {},
  workHistory: {},
  licensingQuestions: {},
  appointmentQuestions: {},
};
const initialLookup = [];

export const ReviewAndSubmitContext = React.createContext();

const ReviewAndSubmitProvider = props => {
  const { currentUser } = React.useContext(CurrentUserContext);
  const { setSubmissionValidation, shouldShowReviewAndSubmitIntroduction, setIsSubmissionCompleted } = useStepperProvider();

  const {
    data: submissionValidation,
    request: { get: getSubmissionValidation },
    isFetching: isFetchingSubmissionValidation,
    fetchStatus: fetchStatusSubmissionValidation
  } = useFetch('get', `assignedLacTasks/onboarding/validation/${currentUser.profileId}`, defaultSubmissionValidation, true, true);
  const {
    data: section,
    request: { get: getSection },
    isFetching: isFetchingSection,
    fetchStatus: fetchStatusSection
  } = useFetch('get', 'assignedLacTasks/onboarding', initialSection, true, true);
  const { fetchStatus: submitStatus, request: { put: submit }, isSubmitting: isSubmittingOnboardingData } = useFetch('put', 'assignedLacTasks/onboarding', null, true, true);
  const { data: countries, request: { get: getCountries }, isFetching: isFetchingCountries } = useFetch('get', 'countries', initialLookup, true, true);
  const { data: usStates, request: { get: getUsStates }, isFetching: isFetchingUsStates } = useFetch('get', 'usStates', initialLookup, true, true);
  const { data: genders, request: { get: getGenders }, isFetching: isFetchingGenders } = useFetch('get', 'genders', initialLookup, true, true);
  const { data: ethnicities, request: { get: getEthnicities }, isFetching: isFetchingEthnicity } = useFetch('get', 'ethnicities', initialLookup, true, true);
  const { data: governmentIssuedIdTypes, request: { get: getGovernmentIssuedIdTypes }, isFetching: isFetchingGovernmentIssuedIdTypes } = useFetch('get', 'governmentIssuedIdTypes', initialLookup, true, true);

  const [shouldShowErrorsFound, setShouldShowErrorsFound] = React.useState(false);
  const [shouldConfirmSubmission, setShouldConfirmSubmission] = React.useState(false);
  const { setProgress, forceSaveProgress, saveProgressFetchStatus, isSavingProgress } = useSaveProgressProvider();
  const forceSaveProgressRef = React.useRef(forceSaveProgress);

  React.useEffect(
    () => {
      forceSaveProgressRef.current();
      setProgress(null);
    },
    [setProgress]
  );

  React.useEffect(
    () => {
      if (!shouldShowReviewAndSubmitIntroduction && saveProgressFetchStatus.code) getSubmissionValidation();
    },
    [getSubmissionValidation, saveProgressFetchStatus.code, shouldShowReviewAndSubmitIntroduction]
  );

  React.useEffect(
    () => {
      if (fetchStatusSubmissionValidation.code === 200) {
        const hasErrors = submissionValidation.stepperFlags.length > 0;
        setSubmissionValidation(submissionValidation);
        setShouldShowErrorsFound(hasErrors);
        if (!hasErrors) fetchAll();
      }

      async function fetchAll() {
        await Promise.all([
          getSection(),
          // NOTE: We have to remove these calls once we refactor our API to select from the V_* to include lookup values
          getCountries(),
          getUsStates(),
          getGenders(),
          getEthnicities(),
          getGovernmentIssuedIdTypes()
        ]);
      }
    },
    [fetchStatusSubmissionValidation.code, fetchStatusSubmissionValidation.text, getCountries, getGenders, getEthnicities, getGovernmentIssuedIdTypes, getSection, getUsStates, setSubmissionValidation, submissionValidation]
  );

  React.useEffect(
    () => {
      if (submitStatus.code === 204) setIsSubmissionCompleted(true);
    },
    [setIsSubmissionCompleted, submitStatus.code]
  );

  const shouldShowSections = React.useMemo(
    () => !shouldShowErrorsFound && [fetchStatusSubmissionValidation, fetchStatusSection].every(status => status.code === 200),
    [fetchStatusSection, fetchStatusSubmissionValidation, shouldShowErrorsFound]
  );

  const isBornInTheUsa = React.useMemo(
    () => {
      const { countryId } = countries.find(c => c.countryName === unitedStatesCountryName) || {};
      return section.agentProfile.countryOfBirthId === countryId;
    },
    [countries, section.agentProfile.countryOfBirthId]
  );

  const governmentIssuedIdNumberLabel = React.useMemo(
    () => {
      const selectedID = governmentIssuedIdTypes.find(g => g.governmentIssuedIdTypeId === section.agentProfile.governmentIssuedIdTypeId);
      return selectedID?.governmentIssuedIdTypeName.toLowerCase().includes('visa')
        ? visaGovernmentIssuedIdNumberLabel
        : defaultGovernmentIssuedIdNumberLabel;
    },
    [governmentIssuedIdTypes, section.agentProfile.governmentIssuedIdTypeId]
  );

  const getCountry = React.useCallback(
    countryId => countries.find(c => c.countryId === countryId),
    [countries]
  );

  const governmentIssuedIdType = React.useMemo(
    () => governmentIssuedIdTypes.find(g => g.governmentIssuedIdTypeId === section.agentProfile.governmentIssuedIdTypeId),
    [governmentIssuedIdTypes, section.agentProfile.governmentIssuedIdTypeId]
  );

  const gender = React.useMemo(
    () => genders.find(g => g.genderId === section.agentProfile.genderId),
    [genders, section.agentProfile.genderId]
  );

  const ethnicity = React.useMemo(
    () => ethnicities.find(e => e.ethnicityId === section.agentProfile.ethnicityId),
    [ethnicities, section.agentProfile.ethnicityId]
  );

  const getUsState = React.useCallback(
    stateCode => usStates.find(u => u.stateCode === stateCode),
    [usStates]
  );

  const getProvince = React.useCallback(
    (countryId, provinceId) => {
      const { provinces } = countries.find(c => c.countryId === countryId);
      return provinces.find(p => p.provinceId === provinceId);
    },
    [countries]
  );

  const value = React.useMemo(
    () => {
      const { agentProfile, workHistory, licensingQuestions, appointmentQuestions } = section;
      return {
        history: props.history,
        shouldShowErrorsFound,
        shouldShowSections,
        agentProfile,
        workHistory,
        licensingQuestions,
        appointmentQuestions,
        submit,
        submitStatus,
        isSubmittingOnboardingData,
        shouldConfirmSubmission,
        setShouldConfirmSubmission,
        isBornInTheUsa,
        governmentIssuedIdNumberLabel,
        getCountry,
        governmentIssuedIdType,
        gender,
        ethnicity,
        getUsState,
        getProvince,
      };
    },
    [gender, ethnicity, getCountry, getProvince, getUsState, governmentIssuedIdNumberLabel, governmentIssuedIdType, isBornInTheUsa, isSubmittingOnboardingData, props.history, section, shouldConfirmSubmission, shouldShowErrorsFound, shouldShowSections, submit, submitStatus]
  );

  return (
    <FetchIndicator
      status={[
        isSavingProgress,
        isFetchingSubmissionValidation,
        isFetchingSection,
        isFetchingCountries,
        isFetchingUsStates,
        isFetchingGenders,
        isFetchingEthnicity,
        isFetchingGovernmentIssuedIdTypes
      ]}
      render={() => <ReviewAndSubmitContext.Provider value={value} {...props} />}
    />
  );
};

ReviewAndSubmitProvider.propTypes = {
  history: PropTypes.object.isRequired
};

export default ReviewAndSubmitProvider;
