import React from 'react';
import PropTypes from 'prop-types';

import * as Styled from './disclosure-questions-styles';
import ControlResolver from './ControlResolver';

const textBox = 'Textbox';
const header = 'Header';
const label = 'Label';

export const ThirdLevelQuestion = ({ ...props }) => {
  const { displayNumber, controlTypeName, requiresResponse } = props;
  return (
    <Styled.Div className="row" data-testid="third-level-question">
      {controlTypeName !== header &&
        <Styled.DisplayNumber className="col-1" style={{marginLeft: 40}}>
          {displayNumber}
        </Styled.DisplayNumber>
      }
      <div className="col-8">
        <div style={{marginLeft: 40}}>
          <ControlResolver type={requiresResponse ? label : controlTypeName} {...props} />
          {controlTypeName === textBox && <ControlResolver type={controlTypeName} {...props} />}
        </div>
      </div>
      {requiresResponse && controlTypeName !== textBox &&
        <Styled.ControlContainer className="col-3">
          <ControlResolver type={controlTypeName} {...props} />
        </Styled.ControlContainer>
      }
    </Styled.Div>
  );
};

ThirdLevelQuestion.propTypes = {
  displayNumber: PropTypes.string,
  controlTypeName: PropTypes.string.isRequired,
  requiresResponse: PropTypes.bool.isRequired,
  sourceName: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  parentIndex: PropTypes.number.isRequired,
};
