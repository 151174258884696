import React from 'react';
import styled from 'styled-components';

import { ContentHeader } from '../header';
import StepperProvider, { submissionCompleted } from './provider/StepperProvider';
import { SaveFinishLaterButton } from '../save-progress';
import Stepper from './Stepper';
import StepperRoutes from './StepperRoutes';
import SubmissionComplete from './SubmissionComplete';
import { CurrentUserContext } from '../common';

const StyledContainerDiv = styled.div`
  background-color: var(--wtwWhite);
`;

const StyledFormDiv = styled.div`
  padding: 18px;
`;

export default function OnboardingSteps() {
  const { initCurrentUser } = React.useContext(CurrentUserContext);
  const submissionCompletedStorage = localStorage.getItem(submissionCompleted);
  React.useEffect(
    () => {
      initCurrentUser();
    },
    [initCurrentUser]
  );

  return (
    <StyledContainerDiv className="container">
      <ContentHeader />
      <StepperProvider>
        {(submissionCompletedStorage === undefined || !submissionCompletedStorage) && <Stepper />}
        <StyledFormDiv>
          {(submissionCompletedStorage === undefined || !submissionCompletedStorage) && <SaveFinishLaterButton />}
          {(submissionCompletedStorage === undefined || !submissionCompletedStorage) && <StepperRoutes />}
          <SubmissionComplete />
        </StyledFormDiv>
      </StepperProvider>
    </StyledContainerDiv>
  );
}
