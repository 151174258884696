import React from 'react';
import styled from 'styled-components';

import { Button, CurrentUserContext, isUserAuthenticated } from '../common';
import { cpLightGrey } from '../styles';

const StyledDiv = styled.div`
  @media (max-width: 767px) {
    font-size: 12px;
  }
  @media (min-width: 768px) {
    display: inline-block;
    float: right;
  }
`;

const StyledSpan = styled.span`
  color: ${cpLightGrey};
`;

export default function Logout() {
  const { currentUser, removeCurrentUser, isSavingProgress, clearExpiredUser } = React.useContext(CurrentUserContext);
  if (currentUser.profileId === null) return null;

  if (!isUserAuthenticated()) {
    clearExpiredUser();
    return null;
  }

  return (
    <StyledDiv>
      <StyledSpan data-testid="user-info">
        Welcome, {currentUser.firstName} {currentUser.lastName}
      </StyledSpan>
      {' '}
      <Button
        data-testid="logout"
        label="Logout"
        isOutline
        onClick={removeCurrentUser}
        isSubmitting={isSavingProgress}
      />
    </StyledDiv>
  );
}
