import exists from './exists';

export default (value, phoneType = '', isRequired = true) => {
  const isInvalid = exists(value) && !(/\(\d{3}\) \d{3}-\d{4}/.test(value));

  if (isRequired && !exists(value) || isInvalid) {
    const typeMessage = phoneType ? phoneType + ' ' : '';
    const validMessage = isInvalid ? 'valid 10-digit ' : '';
    return `Please provide a ${validMessage}${typeMessage}phone number`;
  }
  return null;
};
