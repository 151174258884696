import React from 'react';
import styled from 'styled-components';
import { Icon } from 'es-components';
import wtwTheme from 'es-components-wtw-theme';

import {vbSoftwareGray6 } from '../../styles';
import { DashboardContext } from '../provider/DashboardProvider';
import { ContentHeader } from '../../header';
import { Button } from '../../common';
import BackBar from '../BackBar';
import useRefreshToDashboard from '../useRefreshToDashboard';

const StyledMainText = styled.div`
  text-align: center;
  color: ${wtwTheme.colors.primary};
  font-weight: bold;
  font-size: 1.2em;
`;

const StyledSubText = styled.div`
  text-align: center;
  color: #c110a0;
  font-size: 1em;
`;
const StyledDiv = styled.div`
  background-color: var(--wtwWhite);
`;

const StyledUndoDiv = styled.div`
  padding: 40px 0 30px 0;
`;

const StyledIcon = styled(Icon)`
  color: ${vbSoftwareGray6};
`;

export default function ExamInfoDismissed(props) {
  const { undoDismissExamInfoHandler } = React.useContext(DashboardContext);
  useRefreshToDashboard(props);

  return (
    <StyledDiv className="container">
      <ContentHeader title="Provide Documentation" />
      <BackBar />
      <StyledUndoDiv>
        <div className="col-12 center">
          <StyledIcon size={150} name="trash" />
        </div>
        <div className="row">
          <StyledMainText>You have deleted your exam information.</StyledMainText>
          <StyledSubText>If this was a mistake, you can undo this action.</StyledSubText>
        </div>
        <br/>
        <div className="col-12 center" >
          <Button
            label="UNDO"
            isOutline
            onClick={undoDismissExamInfoHandler}
          />
        </div>
      </StyledUndoDiv>
    </StyledDiv>
  );
}

