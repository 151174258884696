import styled from 'styled-components';
import wtwTheme from 'es-components-wtw-theme';

export const wtwLightBlue = '#c8d7df';
export const wtwBlack = '#000';
export const wtwWhite = '#fff';
export const vbSoftwareGray6 = '#979797';
export const wtwLightGrey = '#dddddd';
export const cpLightGrey = '#585858';
export const cpSectionBackground = '#f7f7ff';
export const wtwViolet = '#702082';

export const StyledLink = styled.a`
  color: ${wtwBlack};
`;

export const StyledFormContainer = styled.div`
  background-color: ${wtwWhite};
  margin-top: 30px;
  margin-bottom: 40px;
  padding: 20px 0px;
  @media only screen and (max-width: 425px): {
    width: 100%;
  }
`;

export const StyledHorizontalLine = styled.hr`
  border: none;
  background-color: ${wtwTheme.colors.gray5};
  height: 2px;
  margin-bottom: 25px;
`;
