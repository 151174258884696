import React from 'react';
import styled from 'styled-components';

import { wtwLightGrey } from '../styles';
import { licensureEmail } from '../common';

const StyledDiv = styled.div`
  background-color: ${wtwLightGrey};
  padding: 15px;
  margin-bottom: 15px;
  margin-top: 15px;
`;

const StyledSpan = styled.span`
  font-size: 18px;
  font-weight: bold;
`;

const NeedHelp = () =>
  <StyledDiv data-testid="need-help-section">
    <span>
      <StyledSpan>Need Help?</StyledSpan>
      <br /> <br />
      Email your questions to: <a href={`mailto:${licensureEmail}`}>{licensureEmail}</a>
    </span>
  </StyledDiv>;

export default NeedHelp;
