import { defaultValidationState, updateValidationResult, isRequired } from '../common/validations';

export default function workHistoryValidation(workHistoryError = {}) {
  const parseFieldError = fieldName => {
    if (Object.hasOwn(workHistoryError, fieldName)) {
      return {
        validationState: 'danger',
        errorMessage: workHistoryError[fieldName],
      };
    }
    return defaultValidationState;
  };

  const initialValidationResult = {
    employerName: parseFieldError('employerName'),
    positionHeld: parseFieldError('positionHeld'),
    startDate: parseFieldError('startDate'),
    endDate: parseFieldError('endDate'),
    countryId: parseFieldError('countryId'),
    city: parseFieldError('city'),
    stateCode: parseFieldError('stateCode'),
    provinceId: parseFieldError('provinceId'),
  };

  const update = updateValidationResult(initialValidationResult);

  function setValidation(isEmployment, isCurrentEmployer, startDate, endDate, shouldDisplayUsStates, provinces) {
    const validate = value => ({
      employerName: () => {
        const name = isEmployment ? 'an employer name' : 'name of educational institution';
        const errorMessage = isRequired(value)(name);
        return update(errorMessage, 'employerName');
      },
      positionHeld: () => {
        const errorMessage = isRequired(value)`a position held`;
        return update(errorMessage, 'positionHeld');
      },
      startDate: () => {
        let errorMessage = value ? null : 'Please provide a start date';
        if (!isCurrentEmployer && endDate && endDate < value) errorMessage = 'Start date should be before end date';
        return update(errorMessage, 'startDate');
      },
      endDate: () => {
        if (isCurrentEmployer) return update(null, 'endDate');
        let errorMessage = value ? null : 'Please provide an end date';
        if (startDate && startDate > value) errorMessage = 'End date should be after start date';
        return update(errorMessage, 'endDate');
      },
      isCurrentEmployer: () => {
        if (value) return update(null, 'endDate');
        const errorMessage = endDate ? null : 'Please provide an end date';
        return update(errorMessage, 'endDate');
      },
      countryId: () => {
        const errorMessage = isRequired(value)`a country`;
        return update(errorMessage, 'countryId');
      },
      city: () => {
        const errorMessage = isRequired(value)`a city`;
        return update(errorMessage, 'city');
      },
      stateCode: () => {
        const errorMessage = shouldDisplayUsStates ? isRequired(value)`a state` : null;
        return update(errorMessage, 'stateCode');
      },
      provinceId: () => {
        const errorMessage = provinces.length ? isRequired(value)`a province` : null;
        return update(errorMessage, 'provinceId');
      }
    });

    return validate;
  }

  return {
    initialValidationResult,
    validate: null,
    setValidation
  };
}
