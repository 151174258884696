export const bearerTokenKey = 'bearer-token';
export const refreshTokenKey = 'refresh-token';

export default function setTokens(authData, storage = window.localStorage) {
  const { bearerToken, refreshToken } = authData;
  if (bearerToken) {
    storage.setItem(bearerTokenKey, bearerToken);
    storage.setItem(refreshTokenKey, refreshToken);
  }
}
