const defaultSubmissionValidation = {
  stepperFlags: [],
  agentProfileError: { fieldError: {} },
  workHistoryError: {
    formErrors: [],
    fieldErrors: []
  },
  licensingDisclosureError: { fieldError: {} },
  appointmentDisclosureError: { fieldError: {} }
};

export default defaultSubmissionValidation;
