import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import styled from 'styled-components';
// import { useHistory } from 'react-router-dom';

import { IconButton } from '../common';
import { StyledFoamGreenDiv, StyledGreenHr } from './styles/dashboard-styles';

const StyledColumn = styled.div`
  margin-top: 10px !important;
  margin-bottom: 20px !important;
`;

// const history = useHistory();

export function BackBar({ history }) {
  return (
    <StyledFoamGreenDiv className="row">
      <StyledColumn className="col-12 left">
        <IconButton
          label="Back"
          iconLeft="chevron-left"
          styleType="primary"
          onClick={() => history.goBack()}
        />
      </StyledColumn>
      <StyledGreenHr />
    </StyledFoamGreenDiv>
  );
}

BackBar.propTypes = {
  history: PropTypes.object.isRequired
};

export default withRouter(BackBar);
