import React from 'react';
import PropTypes from 'prop-types';
import { Control, Label, AdditionalHelp, DatePicker as ESDatePicker } from 'es-components';
import styled from 'styled-components';

const StyledDatePicker = styled(ESDatePicker)`
  width: 100%;
`;

const DatePicker = React.memo(({
  label,
  name,
  onChange,
  onBlur,
  orientation = 'stacked',
  validationState = 'default',
  errorMessage,
  children,
  ...props
}) => {
  const handleOnChange = selectedDate => {
    onChange({
      target: {
        name,
        value: selectedDate,
        type: 'date-picker'
      }
    });
  };

  const handleOnBlur = () => {
    if (!onBlur) return;
    onBlur({ target: { name } });
  };

  return (
    <Control orientation={orientation} validationState={validationState}>
      <Label>{label}</Label>
      <StyledDatePicker
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        {...props}
      />
      {errorMessage && <AdditionalHelp>{errorMessage}</AdditionalHelp>}
      {children}
    </Control>
  );
});

DatePicker.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  orientation: PropTypes.string,
  validationState: PropTypes.oneOf(['default', 'success', 'warning', 'danger']),
  errorMessage: PropTypes.string,
  children: PropTypes.node
};

export default DatePicker;
