import React from 'react';
import PropTypes from 'prop-types';
import { Spinner as ESSpinner } from 'es-components';

const Spinner = React.memo(function Spinner({ title, description }) {
  return (
    <ESSpinner
      title={title}
      description={description}
    />
  );
});

Spinner.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string
};

Spinner.defaultProps = {
  title: 'Loading',
  description: 'Please wait'
};

export default Spinner;
