import React from 'react';
import styled, { keyframes } from 'styled-components';
import PropTypes from 'prop-types';
import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import {
  Fieldset,
  Incrementer,
  Label,
  Textbox,
  Icon,
  InlineMessage,
  Heading
} from 'es-components';
import wtwTheme from 'es-components-wtw-theme';
import { Button, DatePicker, Notification } from '../common';
import { DashboardContext } from '../dashboard/provider/DashboardProvider';
import { parseAgentCertificationStatus } from '../common/AgentCertificationStatus';

const FormStyled = styled.div`
  flex-grow: 1;
`;

const FieldSetStyled = styled(Fieldset)`
  max-width: 100%;
`;

const ScoreInputStyled = styled(Textbox)`
  max-width: 100px;
`;

const magenta = wtwTheme.brandColors.primary1;
const white = wtwTheme.colors.white;
const pink = wtwTheme.brandColors.primary3;

const UploadIconStyled = styled(Icon)`
  font-size: 64px;
  color: ${magenta};
`;

const UploadDivStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  line-height: 2rem;
  border: 2px dashed ${magenta};
  background-color: ${props => (props.isDragging ? magenta : 'rgba(90, 12, 111, 0.05)')};
  min-height: 150px;
  width: 50%;
`;

const uploadIconAnimation = keyframes`
  0% { margin-top: 25px; }
  100% { margin-top: 5px; }
`;

const AnimatedUploadIconStyled = styled(UploadIconStyled)`
  animation-name: ${uploadIconAnimation};
  animation-duration: 1s;
  animation-iteration-count: infinite;
  color: ${white};
  margin-top: 25px;
`;

const DragAndDropInfoStyled = styled.div`
  font-size: 16px;
  font-weight: 500;
`;

const DragHereMessageStyled = styled.div`
  color: ${magenta};
`;

const DropHereMessageStyled = styled.div`
  color: ${white};
`;

const FileNameStyled = styled.div`
  color: ${pink};
`;

const StyledSubmitButtonDiv = styled.div`
  text-align: right;
`;

const AGENT_CERT_REJECTED_STATUS = parseAgentCertificationStatus(6);

function ProofSection({ certificationDetail, usernamePasswordVerificationChecked }) {
  const { submitProof, isSubmittingProof, submitProofFetchStatus } = React.useContext(DashboardContext);

  const [finishedDate, setFinishedDate] = React.useState(
    certificationDetail.agentCertificationTaskStatusName === AGENT_CERT_REJECTED_STATUS ?
      new Date(certificationDetail.finishedDate) : null
  );
  const [numberOfAttempts, setNumberOfAttempts] = React.useState(1);
  const [score, setScore] = React.useState(
    certificationDetail.agentCertificationTaskStatusName === AGENT_CERT_REJECTED_STATUS ?
      certificationDetail.certificationScore : ''
  );
  const [file, setFile] = React.useState('');
  const [errorState, setErrorState] = React.useState(false);
  const [showSubmitResponseError, setShowSubmitResponseError] = React.useState(false);

  const oneToHundred = /^([0-9]|([1-9][0-9])|100)$/;

  const setScoreHandler = ({ target }) => {
    if (oneToHundred.exec(target.value)) {
      setScore(target.value);
    }
  };

  const dragAndDropErrorHandler = ({ unsupportedFileType, overMaxFileSize }) => {
    setErrorState(unsupportedFileType || overMaxFileSize);
  };

  const handleSubmit = () => {
    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append(
      'agentCertificationTaskId',
      certificationDetail.agentCertificationTaskId
    );
    formData.append('dateCompleted', finishedDate.toISOString());
    formData.append('numberOfAttempts', numberOfAttempts);
    formData.append('score', score);
    submitProof(formData);
  };

  React.useEffect(() => {
    if (!submitProofFetchStatus.code || submitProofFetchStatus.code === 200) return;
    setShowSubmitResponseError(true);
    submitProofFetchStatus.code = null;
  }, [submitProofFetchStatus, submitProofFetchStatus.code]);

  return (
    <>
      <Heading level={3}>Provide certification results and proof</Heading>
      <FormStyled>
        <FieldSetStyled>
          <DatePicker
            label="Date when you completed this certification"
            onChange={({ target }) => setFinishedDate(target.value)}
            filterDate={(date) => date <= new Date()}
            selectedDate={finishedDate}
          />
          <Label htmlFor="attempt-incrementer">Number of attempts</Label>
          <Incrementer
            className="row"
            id="attempt-incrementer"
            lowerThreshold={1}
            upperThreshold={10}
            useOutlineButton
            onValueUpdated={setNumberOfAttempts}
            startingValue={certificationDetail.agentCertificationTaskStatusName === AGENT_CERT_REJECTED_STATUS ? certificationDetail.numberOfAttempts : 1}
            disabled
          />
          <br />
          <Label htmlFor="attempt-score">Score</Label>
          <ScoreInputStyled
            id="attempt-score"
            value={score}
            onChange={setScoreHandler}
            type="number"
            min="0"
            max="100"
            placeholder="0-100"
          />
          <br />
          <Label htmlFor="proof-upload">Upload certification proof</Label>
          <DragAndDropArea
            file={file}
            setFile={setFile}
            supportedFileExtensions={['jpg', 'png', 'pdf']}
            maxFileSizeMB={50}
            errorStateHandler={dragAndDropErrorHandler}
          />
          {showSubmitResponseError &&
          <Notification
            header="Sorry, there was an error."
            message="Please try again later."
            isInline
            type="danger"
          />
          }
          <br />
          <StyledSubmitButtonDiv>
            <Button
              label="Submit proof"
              onClick={() => {
                handleSubmit();
              }}
              disabled={
                !finishedDate ||
              !numberOfAttempts ||
              !score ||
              !file ||
              errorState ||
              !usernamePasswordVerificationChecked ||
              !certificationDetail.username ||
              !certificationDetail.password
              }
              isSubmitting={isSubmittingProof}
              styleType="primary"
            />
          </StyledSubmitButtonDiv>

        </FieldSetStyled>
      </FormStyled>
    </>
  );
}

ProofSection.propTypes = {
  certificationDetail: PropTypes.object.isRequired,
  usernamePasswordVerificationChecked: PropTypes.bool.isRequired
};

function DragAndDropArea({
  file,
  setFile,
  supportedFileExtensions,
  maxFileSizeMB,
  errorStateHandler,
}) {
  const inputFileRef = React.useRef(null);
  function handleFileChange({ target }) {
    handleNewFileSelection(target.files[0]);
    target.value = '';
  }

  function handleNewFileSelection(selectedFile) {
    if (selectedFile) {
      setFile(selectedFile);
    }
  }

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: [NativeTypes.FILE],
    drop(item) {
      handleNewFileSelection(item?.files[0]);
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const [overMaxFileSize, setOverMaxFileSize] = React.useState(false);
  const [unsupportedFileType, setUnsupportedFileType] = React.useState(false);

  React.useEffect(() => {
    setOverMaxFileSize(file && file.size / 1000 / 1000 >= maxFileSizeMB);
  }, [file, maxFileSizeMB]);

  React.useEffect(() => {
    setUnsupportedFileType(
      file && supportedFileExtensions.indexOf(file.name.toLowerCase().split('.')
        .pop()) === -1
    );
  }, [file, supportedFileExtensions]);

  React.useEffect(() => {
    errorStateHandler({ unsupportedFileType,
      overMaxFileSize });
  }, [errorStateHandler, overMaxFileSize, unsupportedFileType]);

  const isDragging = canDrop && isOver;
  const dragAndDropInformation = supportedFileExtensions
    .map(val => `.${val}`)
    .join(' ')
    .concat(` | ${maxFileSizeMB} MB max`);

  return isDragging ? (
    <>
      <input
        data-testid="hidden-upload-button"
        accept={`${supportedFileExtensions.join(',')}`}
        type="file"
        ref={inputFileRef}
        hidden
        onChange={handleFileChange}
      />
      <UploadDivStyled isDragging={isDragging} ref={drop}
        id="proof-upload"
      >
        <AnimatedUploadIconStyled name="upload" />
        <DropHereMessageStyled>Drop files</DropHereMessageStyled>
      </UploadDivStyled>
    </>
  ) : (
    <>
      <input
        data-testid="hidden-upload-button"
        accept={`${supportedFileExtensions.join(',')}`}
        type="file"
        ref={inputFileRef}
        hidden
        onChange={handleFileChange}
      />
      <UploadDivStyled
        isDragging={false}
        ref={drop}
        id="proof-upload"
        onClick={() => inputFileRef.current.click()}
      >
        <UploadIconStyled name="upload" />
        <DragAndDropInfoStyled isDragging={isDragging}>
          {dragAndDropInformation}
        </DragAndDropInfoStyled>
        <DragHereMessageStyled isDragging={isDragging}>
          Drag files here to upload or <Button isOutline styleType="primary"
            label="Choose"
          />
        </DragHereMessageStyled>
      </UploadDivStyled>
      <br />
      {file && (
        <>
          <Label htmlFor="file-name">File name</Label>
          <FileNameStyled>{`${file.name}`}</FileNameStyled>
        </>
      )}
      <br />
      {unsupportedFileType && (
        <Notification
          type="danger"
          includeIcon
          message={
            <InlineMessage
              emphasizedText="Unaccepted file type!"
              text="Proofs must be a .jpg .png .pdf file."
            />
          }
        />
      )}
      {overMaxFileSize && (
        <Notification
          type="danger"
          includeIcon
          message={
            <InlineMessage emphasizedText="File too large!" text="Files must be 50mb or less." />
          }
        />
      )}
    </>
  );
}
DragAndDropArea.propTypes = {
  file: PropTypes.object.isRequired,
  setFile: PropTypes.func.isRequired,
  supportedFileExtensions: PropTypes.array,
  maxFileSizeMB: PropTypes.number.isRequired,
  errorStateHandler: PropTypes.func.isRequired,
};

export default ProofSection;
