import React from 'react';
import PropTypes from 'prop-types';

import {
  StyledDiv,
  StyledMobileBannerDiv,
  StyledTitleDiv,
  StyledFormTitle,
  StyledForm,
  StyledLoginButtonDiv,
  StyledLink,
  StyledLinkContainerDiv,
  StyledFetchErrorNotificationDiv,
} from './login-styles';
import {
  useFetch,
  FETCH_RESET,
  useForm,
  TextInput,
  Button,
  FetchErrorNotification,
  FetchIndicator,
  setTokens,
  Notification,
  CurrentUserContext,
  isUserAuthenticated,
} from '../common';
import validation from './login-validations';

const initialState = {
  userName: '',
  password: '',
};

export default function Login({ history, match, initialWorkEmailDomains, location }) {
  const isUserAuthenticatedRef = React.useRef(isUserAuthenticated());
  const registrationTokenRef = React.useRef(match.params.token);
  const [shouldShowResendEmailSuccess, setShouldShowResendEmailSuccess] = React.useState(false);
  const workEmailDomainsMemo = React.useMemo(
    () => initialWorkEmailDomains,
    [initialWorkEmailDomains]
  );
  const {
    data: workEmailDomains,
    request: { get: getWorkEmailDomains },
    fetchStatus: workEmailFetchStatus,
    isFetching,
  } = useFetch('get', 'workEmailDomain', workEmailDomainsMemo, false, true);
  const {
    data: authResult,
    request,
    fetchStatus: authFetchStatus,
    isSubmitting,
  } = useFetch('post', 'authentication', null, false, true);
  const {
    request: emailrequest,
    fetchStatus: reSendEmailFetchStatus,
    fetchDispatch: reSendEmailDispatch,
  } = useFetch('post', 'authentication/reSendEmail', null, false, true);
  const {
    data: registrationMessage,
    fetchStatus: regVerificationFetchStatus,
    request: regVerificationRequest,
    isSubmitting: isRegVerificationSubmitting,
  } = useFetch(
    'put',
    `registrationVerification/${registrationTokenRef.current}`,
    null,
    false,
    true
  );
  const loginValidation = React.useMemo(() => validation(workEmailDomains), [workEmailDomains]);
  const fetchStatus = React.useMemo(
    () => [workEmailFetchStatus, authFetchStatus],
    [workEmailFetchStatus, authFetchStatus]
  );
  const { state, handleChange, handleBlur, handleSubmit, validationResult } = useForm(
    initialState,
    login,
    undefined,
    loginValidation
  );
  const { initCurrentUser, currentUser } = React.useContext(CurrentUserContext);

  React.useEffect(() => {
    if (isUserAuthenticatedRef.current) {
      initCurrentUser();
    } else {
      getWorkEmailDomains();
    }
  }, [getWorkEmailDomains, initCurrentUser]);

  const loginMessage = React.useMemo(() => {
    const { state: { loginMessage } = {} } = location;
    return loginMessage;
  }, [location]);

  React.useEffect(() => {
    if (authFetchStatus.code === 200) {
      setTokens(authResult);
      initCurrentUser();
    } 
  }, [authFetchStatus.code, authResult, history, initCurrentUser]);

  React.useEffect(() => {
    if (reSendEmailFetchStatus.code === 200) {
      setShouldShowResendEmailSuccess(true);
      reSendEmailDispatch({ type: FETCH_RESET });
    }
  }, [
    reSendEmailFetchStatus.code,
    reSendEmailFetchStatus,
    reSendEmailDispatch,
    setShouldShowResendEmailSuccess,
  ]);

  React.useEffect(() => {
    if (currentUser.profileId === null) return;

    if (currentUser.isFinishedWithStepper && currentUser.isFinishedWithStepper === 'True') {
      history.replace('/dashboard');
    } else {
      history.replace('/onboarding-steps');
    }
  }, [currentUser.isFinishedWithStepper, currentUser.profileId, history]);

  React.useEffect(() => {
    if (registrationTokenRef.current) {
      regVerificationRequest.put({ token: registrationTokenRef.current });
    }
  }, [regVerificationRequest]);

  function handleResendEmail() {
    emailrequest.post(state);
  }

  function login() {
    request.post(state);
  }

  function handleForgotPassword() {
    history.replace({
      pathname: '/forgot-password',
      state: {
        initialEmailAddress: state.userName,
      },
    });
  }

  if (isUserAuthenticatedRef.current) return null;

  return (
    <FetchIndicator
      status={[isFetching, isRegVerificationSubmitting]}
      render={() => (
        <StyledDiv>
          <div className="container">
            <StyledTitleDiv>Individual Marketplace Colleague Portal</StyledTitleDiv>
            <StyledForm>
              <StyledFormTitle>Login</StyledFormTitle>
              {registrationMessage && regVerificationFetchStatus.code === 200 && (
                <Notification type="success" header="Success!"
                  message={registrationMessage}
                />
              )}
              {loginMessage && (
                <Notification
                  type={loginMessage.type}
                  header={loginMessage.header}
                  message={loginMessage.message}
                />
              )}
              <TextInput
                data-testid="email-address"
                label="Email address"
                validationState={validationResult.userName.validationState}
                errorMessage={validationResult.userName.errorMessage}
                name="userName"
                value={state.userName}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <TextInput
                data-testid="password"
                label="Password"
                validationState={validationResult.password.validationState}
                errorMessage={validationResult.password.errorMessage}
                name="password"
                type="password"
                value={state.password}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <StyledLinkContainerDiv type="forgot-password">
                  <StyledLink
                    id="forgot-password"
                    href="/#/forgot-password"
                    onClick={handleForgotPassword}
                  >
                    Forgot password?
                  </StyledLink>
                </StyledLinkContainerDiv>
              </TextInput>
              <StyledLoginButtonDiv>
                <Button
                  data-testid="login"
                  type="submit"
                  label="Login"
                  isOutline
                  onClick={handleSubmit}
                  isSubmitting={isSubmitting}
                />
              </StyledLoginButtonDiv>
              <StyledLinkContainerDiv type="register-here">
                <span>New user? </span>
                <StyledLink id="register-here" href="/#/registration">
                  Register here
                </StyledLink>
              </StyledLinkContainerDiv>
              <StyledFetchErrorNotificationDiv>
                <FetchErrorNotification status={fetchStatus} />
                {authFetchStatus.text ===
                'Your account has not yet been verified, Please Re-Send verification email by clicking the link below' ? (
                    <a href="#" onClick={handleResendEmail}>
                      Re-send verification email
                    </a>
                  ) : (
                    ' '
                  )}
                {shouldShowResendEmailSuccess ? (
                  <Notification type="success" header="Success!"
                    message={registrationMessage}
                  />
                ) : (
                  ' '
                )}
              </StyledFetchErrorNotificationDiv>
            </StyledForm>
          </div>
          <StyledMobileBannerDiv />
        </StyledDiv>
      )}
    />
  );
}

Login.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  initialWorkEmailDomains: PropTypes.array,
  isLazyWorkEmailDomains: PropTypes.bool,
  location: PropTypes.object.isRequired,
};
