import sentenceCase from '../sentenceCase';
import exists from './exists';
import exceeds from './exceeds';

export default (value, name, maxLen = 300, article = 'a') => {
  const textNotValidMessage = `Please provide ${article} ${name}`;
  const val = (value || '').toString().trim();

  if (!exists(val)) return textNotValidMessage;
  if (exceeds(val, maxLen)) return `${sentenceCase(name)} must be less than ${maxLen} characters`;

  return null;
};
