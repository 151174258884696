import styled from 'styled-components';

import { StyledFoamGreenDiv } from '../dashboard/styles/dashboard-styles';

export const StyledSectionHeader = styled.span`
color: #444444;
font-size: 1.7em;
font-weight: bold;
`;
export const StyledGreenDivWithPadding = styled(StyledFoamGreenDiv)`
  padding: .4em 0px;
  margin-bottom: 20px;
`;
export const StyledBoldTitleSpan = styled.span`  
  display: inline-block;
  font-size: 1.2em;
  font-weight: bold;
  overflow-wrap: break-word;
  margin-bottom: 12px;
`;
