import React from 'react';
import styled from 'styled-components';
import { Heading, Notification as ESNotification } from 'es-components';
import wtwTheme from 'es-components-wtw-theme';

import BackBar from '../dashboard/BackBar';
import { Table, IconButton, Notification, Button } from '../common';
import { ContentHeader } from '../header';
import { wtwWhite } from '../styles';
import { taskDetailColumnList } from '../certification-task/TaskDetailColumnList';
import { completedCertResultsColumnList } from './CompletedCertResultsColumnList';
import { parseAgentCertificationStatus } from '../common/AgentCertificationStatus';
import { closedCertResultsColumnList } from './ClosedCertResultsColumnList';
import AdjustCredentialsSlideout from '../dashboard/AdjustCredentialsSlideout';
import useRefreshToDashboard from '../dashboard/useRefreshToDashboard';
import { PastCertificationsContext } from './provider/PastCertificationsProvider';

const StyledContainer = styled.div`
  background-color: ${wtwWhite};
  margin-bottom: 4em;
`;

const StyledHeading = styled(Heading)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const StyledHeadingSpan = styled.span`
  margin-right: 20px;
`;

const StyledPageContentDiv = styled.div`
  padding: 20px;
`;

const NotificationContentFlexDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const AGENT_CERT_COMPLETED_STATUS = parseAgentCertificationStatus(5);
const AGENT_CERT_CLOSED_STATUS = parseAgentCertificationStatus(7);

export default function PastCertificationDetail(props) {
  const {
    pastCertAdjustCredentialsSlideoutObjs,
    selectedCertDetail,
  } = React.useContext(PastCertificationsContext);

  useRefreshToDashboard(props);
  if (!selectedCertDetail) return null;

  return (
    <StyledContainer className="container">
      <ContentHeader title="Certification Process" />
      <BackBar />
      <StyledPageContentDiv>
        <StyledHeading level={2} underlineColor={wtwTheme.colors.primary}>
          {
            <StyledHeadingSpan>
              {selectedCertDetail.certYear}{' '}
              {selectedCertDetail.carrierAlias
                ? selectedCertDetail.carrierAlias
                : selectedCertDetail.carrierName}{' '}
              certification
            </StyledHeadingSpan>
          }
          <IconButton
            styleType="primary"
            iconLeft="external-link"
            label="View instructions"
            disabled={!selectedCertDetail.instructionLink}
            onClick={() => {
              const instructionLink =
                selectedCertDetail.instructionLink.startsWith('http://') ||
                selectedCertDetail.instructionLink.startsWith('https://')
                  ? selectedCertDetail.instructionLink
                  : `http://${selectedCertDetail.instructionLink}`;
              window.open(instructionLink, '_blank');
            }}
            data-testid="instruction-link-button"
          />
        </StyledHeading>
        {selectedCertDetail.agentCertificationTaskStatusName === AGENT_CERT_CLOSED_STATUS && (
          <Notification
            isInline
            message="You cannot complete this certification. See reason below."
            type="warning"
            header="This certification is closed."
          />
        )}
        <Table
          data={[selectedCertDetail]}
          columnConfig={taskDetailColumnList}
          idKey="agentCertificationTaskId"
          hasAlternatingRowColors
        />
        <br />
        <ESNotification type="info" isLightNotification>
          <NotificationContentFlexDiv>
            You may update your username and/or password if needed.
            <Button
              label="Change username/password"
              isOutline
              size="sm"
              styleType="info"
              onClick={() =>
                pastCertAdjustCredentialsSlideoutObjs.openAdjustCredentialsSlideout(
                  selectedCertDetail.agentCertificationTaskId
                )
              }
            />
          </NotificationContentFlexDiv>
        </ESNotification>

        <br />
        {AGENT_CERT_COMPLETED_STATUS === selectedCertDetail.agentCertificationTaskStatusName && (
          <>
            <StyledHeading level={3}>Certification results</StyledHeading>
            <Table
              data={[selectedCertDetail]}
              columnConfig={completedCertResultsColumnList}
              idKey="agentCertificationTaskId"
              hasAlternatingRowColors
            />
          </>
        )}
        {AGENT_CERT_CLOSED_STATUS === selectedCertDetail.agentCertificationTaskStatusName && (
          <>
            <StyledHeading level={3}>Certification results</StyledHeading>
            <Table
              data={[selectedCertDetail]}
              columnConfig={closedCertResultsColumnList}
              idKey="agentCertificationTaskId"
              hasAlternatingRowColors
            />
          </>
        )}
      </StyledPageContentDiv>
      <AdjustCredentialsSlideout
        adjustCredentialsSlideoutObjs={pastCertAdjustCredentialsSlideoutObjs}
      />
    </StyledContainer>
  );
}
