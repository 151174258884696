import React from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { Drawer, Icon } from 'es-components';

import useWorkHistoryProvider from './provider/useWorkHistoryProvider';
import { useStepperProvider } from '../onboarding-steps';
import WorkHistoryForm from './forms/WorkHistoryForm';
import { ADD_NEW_PERIOD } from './provider/WorkHistoryProvider';
import TypeSelection from './forms/TypeSelection';
import { FetchIndicator, Button, FetchErrorNotification, Notification } from '../common';
import { historyTypes } from '../work-history/work-history-constants';

const StyledExclamationSign = styled(Icon)`
  color: red;
`;

const getPanelTitle = workHistoryError => item => {
  const hasFieldErrors = workHistoryError.fieldErrors.some(error => error.workHistoryId === item.workHistoryId.toString());
  const startMonthYear = item.startDate ? `${dayjs(item.startDate).format('MMM')} ${dayjs(item.startDate).format('YYYY')}` : '';
  let endMonthYear;

  if (item.isCurrentEmployer) {
    endMonthYear = 'Present';
  } else {
    endMonthYear = item.endDate ? `${dayjs(item.endDate).format('MMM')} ${dayjs(item.endDate).format('YYYY')}` : '';
  }

  const title = historyTypes.find(x => x.value === item.workHistoryTypeId.toString()).title;
  const employerName = item.employerName === '' ? title : item.employerName;

  return (
    <span>
      {hasFieldErrors ? <StyledExclamationSign size={24} name="exclamation-sign" /> : null}
      {`${startMonthYear} ${startMonthYear && '-'} ${endMonthYear} ${employerName}`}
    </span>
  );
};

export default function Accordion() {
  const {
    data,
    shouldSetDrawerActiveKey,
    activeKey,
    setActiveKey,
    shouldShowIntroduction,
    setShouldSetDrawerActiveKey,
    setShouldSubmit,
    submitStatus,
    fetchingStatus,
    isSubmitting,
  } = useWorkHistoryProvider();
  const { workHistoryError } = useStepperProvider();

  React.useEffect(
    () => {
      if (shouldSetDrawerActiveKey) {
        const value = data.length > 1 ? data[(data.length - 2)].panelId.toString() : '0';
        /*
          We need to wrap `value` in an array so it works with ES Components.
          This can be sent to ES Components as either a string or an array.
          If it's sent as a string, it appears to be setting the index of the
          "open" Drawer to the first position in the numerical string
        */
        setActiveKey([value]);
        setShouldSetDrawerActiveKey(false);
      }
    },
    [data, setActiveKey, setShouldSetDrawerActiveKey, shouldSetDrawerActiveKey]
  );

  const getPanelTitleFn = React.useMemo(
    () => getPanelTitle(workHistoryError),
    [workHistoryError]
  );

  if (shouldShowIntroduction === null || shouldShowIntroduction) return null;

  return (
    <FetchIndicator
      status={fetchingStatus}
      render={() =>
        <React.Fragment>
          <div className="row">
            <div className="col-12">
              Please provide a description of your <b>last five years.</b> You can't have gaps of more than 30 days.
            </div>
          </div>
          <br />
          {workHistoryError.formErrors.map((errorMessage, index) =>
            <Notification
              key={index}
              type="danger"
              message={errorMessage}
            />
          )}
          {workHistoryError.fieldErrors.length > 0 ?
            <Notification
              type="danger"
              message="Correct any errors in sections marked with a red triangle."
            />
            :
            null
          }
          <Drawer
            activeKeys={activeKey}
            onActiveKeysChanged={value => setActiveKey(value)}
            isAccordion
          >
            {data.map(value => {
              const key = value.panelId.toString();
              if (value.workHistoryTypeId.toString() === ADD_NEW_PERIOD) {
                return (
                  <Drawer.Panel key={key} title={data.length > 1 ? 'Add another event period' : ''}>
                    <TypeSelection />
                  </Drawer.Panel>
                );
              }

              const title = getPanelTitleFn(value);
              return (
                <Drawer.Panel key={key} title={title}>
                  <WorkHistoryForm initialState={value} />
                </Drawer.Panel>
              );
            })}
          </Drawer>
          <div className="row">
            <FetchErrorNotification status={[submitStatus]} />
          </div>
          <div className="row">
            <div className="col-12 right">
              <Button
                data-testid="done-adding-history"
                label="Done adding history"
                isOutline
                onClick={() => setShouldSubmit(true)}
                isSubmitting={isSubmitting}
              />
            </div>
          </div>
        </React.Fragment>
      }
    />
  );
}
