import dayjs from 'dayjs';

function formatDate(date) {
  return date ? dayjs(date).format('MM/DD/YYYY') : '-';
}

export const closedCertResultsColumnList = [
  {
    label: 'Closed date',
    dataKey: 'agentCertificationTaskStatusChangedDate',
    width: 300,
    cellRenderer: rowData => formatDate(rowData.agentCertificationTaskStatusChangedDate)
  },
  {
    label: 'Close reason',
    dataKey: 'agentCertificationTaskStatusChangedReason',
    width: 500,
    cellRenderer: rowData => rowData.agentCertificationTaskStatusChangedReason
  }
];
