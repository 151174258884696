import React from 'react';

import Sections from './Sections';
import ReviewAndSubmitProvider from './provider/ReviewAndSubmitProvider';
import Introduction from './Introduction';
import ErrorsFound from './ErrorsFound';

export default function ReviewAndSubmit(props) {
  return (
    <ReviewAndSubmitProvider {...props} >
      <Introduction />
      <ErrorsFound />
      <Sections />
    </ReviewAndSubmitProvider>
  );
}
