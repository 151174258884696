import React from 'react';
import PropType from 'prop-types';
import styled from 'styled-components';
import { Heading } from 'es-components';
import wtwTheme from 'es-components-wtw-theme';

import { Button, Notification } from '../../../common';
import { YearlyDisclosuresContext } from './YearlyDisclosuresProvider';
import YearlyDisclosuresTaskComplete from './YearlyDisclosuresTaskComplete';

const StyledDiv = styled.div`
  margin-right: 10px;
  margin-top: 10px;
`;

const StyledHeading = styled(Heading)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const YearlyDisclosureQuestions = ({ history }) => {
  const {
    yearlyDisclsosuresTaskItem,
    showSubmitSuccessMessage,
    renderedDisclosureQuestions,
    handleSubmitDisclosureQuestions,
    isSubmitting,
    responsesAreValidForSubmit,
    shouldShowSubmitErrorNotification
  } = React.useContext(YearlyDisclosuresContext);

  if (showSubmitSuccessMessage) return (<YearlyDisclosuresTaskComplete type={yearlyDisclsosuresTaskItem.type} />);
  return (
    <>
      <StyledHeading level={2} underlineColor={wtwTheme.colors.primary}>{`${yearlyDisclsosuresTaskItem.type} disclosure questions`}</StyledHeading>
      <Notification
        message="Verify or change as needed. Submit when finished."
        type="info"
        header="Questions are pre-filled with last year's answers."
        isInline
      />
      <form data-testid="disclosure-questions-section">
        {renderedDisclosureQuestions}
        <hr />
        {shouldShowSubmitErrorNotification &&
        <Notification
          type="danger"
          header="Sorry, there was an error."
          message="Please try again later."
          isInline
        />
        }
        <div className="row">
          <StyledDiv className="right">
            <Button
              data-testid="disclosure-questions-cancel"
              label="Cancel"
              onClick={() => history.goBack()}
            />
          &nbsp;&nbsp;&nbsp;&nbsp;
            <Button
              data-testid="disclosure-questions-submit"
              label="Submit"
              onClick={handleSubmitDisclosureQuestions}
              isSubmitting={isSubmitting}
              styleType="primary"
              disabled={!responsesAreValidForSubmit}
            />
          </StyledDiv>
        </div>
      </form>
    </>
  );
};

YearlyDisclosureQuestions.propTypes = {
  history: PropType.object.isRequired
};

export default YearlyDisclosureQuestions;
