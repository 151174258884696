import React from 'react';
import PropTypes from 'prop-types';
import { MultilineText } from '../../common';

export default function Row({ label, value, labelCol = 4, level = 0 }) {
  const lCol = `col-${labelCol}`;
  const vCol = `col-${12 - labelCol}`;
  return (
    <div className="row">
      <div className={lCol}>{}
        <div style={{marginLeft: 20 * level }}>
          <MultilineText value={label} />
        </div>
      </div>
      <div className={vCol}>{value}</div>
    </div>
  );
}

Row.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.any,
  labelCol: PropTypes.number,
  level: PropTypes.number,
};
