import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Control, Label, AdditionalHelp, Dropdown as ESDropdown } from 'es-components';

import toOptionShape from './toOptionShape';

const StyledDropdown = styled(ESDropdown)`
  width: 100%;
`;

const Dropdown = React.memo(({
  label,
  data,
  dataTextField,
  dataValueField,
  errorMessage,
  validationState = 'default',
  orientation = 'stacked',
  defaultOptionValue = '',
  defaultOptionText = '',
  children,
  ...props
}) => {
  if (!(data || []).length) return null;
  const options = data.map(toOptionShape(dataTextField, dataValueField));

  return (
    <Control orientation={orientation} validationState={validationState}>
      <Label>{label}</Label>
      <StyledDropdown
        {...props}
      >
        <option
          disabled
          value={defaultOptionValue}
        >
          {defaultOptionText}
        </option>
        {options.map(({ value, text }) =>
          <option
            key={value}
            value={value}
          >
            {text}
          </option>
        )}
      </StyledDropdown>
      {errorMessage && <AdditionalHelp data-testid="dropdown-additional-help">{errorMessage}</AdditionalHelp>}
      {children}
    </Control>
  );
});

Dropdown.propTypes = {
  label: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.object),
  dataTextField: PropTypes.string.isRequired,
  dataValueField: PropTypes.string.isRequired,
  errorMessage: PropTypes.string,
  validationState: PropTypes.string,
  orientation: PropTypes.string,
  defaultOptionValue: PropTypes.string,
  defaultOptionText: PropTypes.string,
  children: PropTypes.node,
};

export default Dropdown;
