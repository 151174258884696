import React from 'react';

import { MonthYearInput, Checkbox } from '../../common';
import { WorkHistoryFormContext } from './WorkHistoryForm';

export default React.memo(function Unemployment() {
  const { state, handleChange, handleBlur, validationResult } = React.useContext(WorkHistoryFormContext);

  const [endDateKey, setEndDateKey] = React.useState(0);

  const handleClearCurrentDate = event => {
    handleChange({
      target: {
        name: 'endDate',
        value: null
      }
    });
    handleChange(event);
    setEndDateKey(currentKey => currentKey + 1);
  };

  return (
    <div data-testid="unemployment-period">
      <div className="row">
        <div className="col-5">
          <MonthYearInput
            label="Start date"
            name="startDate"
            defaultValue={state.startDate && new Date(state.startDate)}
            onChange={handleChange}
            onBlur={handleBlur}
            validationState={validationResult.startDate.validationState}
            errorMessage={validationResult.startDate.errorMessage}
          />
        </div>
        <div className="col-5">
          <MonthYearInput
            key={endDateKey}
            label="End date"
            name="endDate"
            defaultValue={state.endDate && new Date(state.endDate)}
            onChange={handleChange}
            onBlur={handleBlur}
            validationState={validationResult.endDate.validationState}
            errorMessage={validationResult.endDate.errorMessage}
            disabled={state.isCurrentEmployer}
          >
            <Checkbox
              name="isCurrentEmployer"
              text="I am currently unemployed"
              checked={state.isCurrentEmployer}
              onChange={handleClearCurrentDate}
            />
          </MonthYearInput>
        </div>
      </div>
    </div>
  );
});
