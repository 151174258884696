import React from 'react';
import styled from 'styled-components';

import { AgentProfileContext } from '../provider/AgentProfileProvider';
import { StyledHorizontalLine } from '../../styles';
import {
  TextInput,
  DateInput,
  Dropdown,
  Typography
} from '../../common';

const StyledFlexbox = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: ${props => props.shouldDisplayForUSCitizen ? 'column' : 'row'};
`;

export default React.memo(function BirthInfo() {
  const {
    state,
    genders,
    ethnicities,
    countries,
    usStates,
    validationResult,
    handleChange,
    handleBlur,
    isBornInTheUsa,
  } = React.useContext(AgentProfileContext);

  if (!countries) return null;
  return (
    <React.Fragment>
      <div className="row">
        <div className="col-12">
          <StyledHorizontalLine />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <Typography variant="sectionHeader" text="Birth information" />
        </div>
      </div>
      <StyledFlexbox shouldDisplayForUSCitizen={state.isUSCitizen} className="row">
        <div className="col-3">
          <Dropdown
            data-testid="gender-dropdown"
            label="Gender"
            data={genders}
            dataTextField="genderName"
            dataValueField="genderId"
            name="genderId"
            value={state.genderId}
            onChange={handleChange}
            onBlur={handleBlur}
            validationState={validationResult.genderId.validationState}
            errorMessage={validationResult.genderId.errorMessage}
          />
        </div>
        <div className="col-5">
          <Dropdown
            data-testid="ethnicity-dropdown"
            label="Ethnicity"
            data={ethnicities}
            dataTextField="ethnicityName"
            dataValueField="ethnicityId"
            name="ethnicityId"
            value={state.ethnicityId}
            onChange={handleChange}
            onBlur={handleBlur}
            validationState={validationResult.ethnicityId.validationState}
            errorMessage={validationResult.ethnicityId.errorMessage}
          />
        </div>
        <div className="col-5">
          <DateInput
            data-testid="dob-date-input"
            showYearDropdown
            name="dateOfBirth"
            label="Date of birth"
            defaultValue={state.dateOfBirth && new Date(state.dateOfBirth)}
            onChange={handleChange}
            onBlur={handleBlur}
            validationState={validationResult.dateOfBirth.validationState}
            errorMessage={validationResult.dateOfBirth.errorMessage}
          />
        </div>
      </StyledFlexbox>
      {state.isUSCitizen &&
        <React.Fragment>
          <div className="row">
            <div className="col-4">
              <Dropdown
                data-testid="birth-country-dropdown"
                label="Country of birth"
                data={countries}
                dataTextField="countryName"
                dataValueField="countryId"
                name="countryOfBirthId"
                value={state.countryOfBirthId}
                onChange={handleChange}
                onBlur={handleBlur}
                validationState={validationResult.countryOfBirthId.validationState}
                errorMessage={validationResult.countryOfBirthId.errorMessage}
              />
            </div>
          </div>
          {isBornInTheUsa &&
            <React.Fragment>
              <div className="row">
                <div className="col-4">
                  <Dropdown
                    data-testid="birth-state-dropdown"
                    label="State of birth"
                    data={usStates}
                    dataTextField="stateName"
                    dataValueField="stateCode"
                    name="stateOfBirth"
                    value={state.stateOfBirth}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    validationState={validationResult.stateOfBirth.validationState}
                    errorMessage={validationResult.stateOfBirth.errorMessage}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-4">
                  <TextInput
                    data-testid="birth-county-input"
                    name="countyOfBirth"
                    label="County of birth"
                    value={state.countyOfBirth}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    validationState={validationResult.countyOfBirth.validationState}
                    errorMessage={validationResult.countyOfBirth.errorMessage}
                  />
                </div>
              </div>
            </React.Fragment>
          }
        </React.Fragment>
      }
    </React.Fragment>
  );
});
