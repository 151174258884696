import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledUl = styled.ul`
  ${props => props.shouldAddPadding ? 'padding: 15px;' : ''}
`;

const StyledSpan = styled.span`
  font-weight: bold;
`;

export default function PasswordGuidelines({shouldAddPadding = true}) {
  return (
    <StyledUl shouldAddPadding={shouldAddPadding}>
      <li>at least 8 characters</li>
      <li>at least one <StyledSpan>lowercase</StyledSpan> and one <StyledSpan>uppercase</StyledSpan> letter</li>
      <li>at least one <StyledSpan>number</StyledSpan></li>
    </StyledUl>
  );
}

PasswordGuidelines.propTypes = {
  shouldAddPadding: PropTypes.bool
};
