import styled from 'styled-components';

import { PURPLE, DARK_GRAY, LIGHT_GRAY, NOT_STARTED, COMPLETE, IN_PROGRESS } from './stepper-constants';

const circleColorSelector = (isSelected, status) => {
  if (isSelected) return PURPLE;
  if (status === IN_PROGRESS || status === COMPLETE) return DARK_GRAY;
  return LIGHT_GRAY;
};

const lineColorSelector = (status, rightSide, nextStatus) => {
  if (rightSide) {
    if ((status === IN_PROGRESS || status === COMPLETE) && nextStatus !== NOT_STARTED) return DARK_GRAY;
    return LIGHT_GRAY;
  }
  if (status === IN_PROGRESS || status === COMPLETE) return DARK_GRAY;
  return LIGHT_GRAY;
};

export const StyledCircle = styled.button`
  color: ${props => circleColorSelector(props.isSelected, props.status)};
  border-style: solid;
  border-width: ${props => props.status === NOT_STARTED ? '3px' : '5px'}
  border-color: ${props => circleColorSelector(props.isSelected, props.status)};
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  background-color: white;
  margin: 1px ${props => props.isHovering && !props.isSelected && props.status !== NOT_STARTED ? '0px' : '3px'};
  position: relative;
  box-shadow: ${props => props.isHovering && !props.isSelected && props.status !== NOT_STARTED ? '0px 0px 5px rgba(0, 0, 0, 1)' : null};
  font-size: 20px;
  font-weight: bold;
  pointer-events:none;
  width: 40px;
  height: 40px;
  flex-basis: 1;
  @media (max-width: 767px) {
    ${props => !props.isSelected ? `
    font-size: 12px;
    width: 20px;
    height: 20px;
    border-width: 1px;
    margin-top: 10px;
    ` : ''}
  }
`;

export const StyledStepperLine = styled.hr`
  height: 3px;
  background-color: ${props => lineColorSelector(props.status, props.rightSide, props.nextStatus)};
  margin-top: 20px;
  border: none;
  right: ${props => props.rightSide ? 0 : null};
  visibility: ${props => props.hide ? 'hidden' : 'visible'};
  flex-grow: 1;
`;

export const StyledStepContainer = styled.div`
  text-align: center;
  flex-basis: 0;
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  margin-top:2%;
  margin-bottom:2%;
`;

export const StyledStepText = styled.div`
  text-align: center;
  pointer-events: none;
  font-weight: bold;
  text-decoration: ${props => props.isHovering && !props.isSelected && props.status !== NOT_STARTED ? 'underline' : null};
  color: ${props => circleColorSelector(props.isSelected, props.status)};
  flex-basis: 100%;
  height: 50%;
  margin-top: 5px;
  @media (max-width: 767px) {
    ${props => !props.isSelected ? `
    display: none;
    ` : ''}
  }
`;
