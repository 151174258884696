import sentenceCase from '../sentenceCase';
import exists from './exists';
import exceeds from './exceeds';

const maxLength = 300;
const escapeSpecialCharacters = string => string.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');

export const emailNotValidMessage = 'Please provide a valid';
export const emailMustBePersonal = 'Please provide a personal email address';
export const exceedsEmailLengthMessage = `cannot exceed ${maxLength} characters`;

export default (email, workEmailDomains, name = 'email address') => {
  if (!exists(email) || !email.match(/^([\w\.\-]+)@([\w\-]+)((\.(\w){2,4})+)$/i)) {
    return `${emailNotValidMessage} ${name}`;
  }

  if (exceeds(email, maxLength)) {
    return `${sentenceCase(name)} ${exceedsEmailLengthMessage}`;
  }

  const isEmailWorkDomain = workEmailDomains.find(workEmailDomain => {
    const regex = new RegExp(escapeSpecialCharacters(workEmailDomain.emailDomain), 'i');
    return regex.test(email);
  });

  return isEmailWorkDomain ? emailMustBePersonal : null;
};
