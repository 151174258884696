import React from 'react';
import PropTypes from 'prop-types';

import { StyledHorizontalLine } from '../../styles';
import { Typography, Button } from '../../common';
import Row from './Row';

export default function DisclosureQuestionsSection(props) {
  return (
    <React.Fragment>
      <StyledHorizontalLine />
      <div className="row">
        <div className="col-6">
          <Typography variant="sectionHeader" text={props.headerText} />
        </div>
        <div className="col-6 right">
          <Button
            label="Edit section"
            isOutline
            onClick={() => props.history.push(props.route)}
            data-testid="edit-section"
          />
        </div>
      </div>
      {props.data.map((question, index) => {
        const response = question.text.startsWith('Please read') && question.response === 'true'
          ? '[CONFIRMED]'
          : question.response;

        return (
          <React.Fragment key={index}>
            <Row
              label={`${question.displayNumber}. ${question.text}`}
              value={response}
              labelCol={10}
            />
            {question.response.toLowerCase() === 'yes' && question.children
              .filter(childQuestion => childQuestion.requiresResponse)
              .map((childQuestion, childIndex) => {
                return (
                  <React.Fragment key={childIndex}>
                    <Row
                      key={childIndex}
                      label={`${childQuestion.displayNumber}. ${childQuestion.text}`}
                      value={childQuestion.response}
                      labelCol={10}
                      level={1}
                    />
                    
                    {childQuestion.response.toLowerCase() === 'yes' && childQuestion.children
                      .filter(thirdLevelQuestion => thirdLevelQuestion.requiresResponse)
                      .map((thirdLevelQuestion, thirdLevelIndex) => {
                        return (
                          <Row
                            key={thirdLevelIndex}
                            label={`${thirdLevelQuestion.displayNumber}. ${thirdLevelQuestion.text}`}
                            value={thirdLevelQuestion.response}
                            labelCol={10}
                            level={2}
                          />
                        );
                      })
                    }
                  </React.Fragment>
                );
              })}
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
}

DisclosureQuestionsSection.propTypes = {
  history: PropTypes.object.isRequired,
  route: PropTypes.string.isRequired,
  headerText: PropTypes.string.isRequired,
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired
};
