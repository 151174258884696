export const INITIALIZE_DISCLOSURE_QUESTIONS = 'INITIALIZE_DISCLOSURE_QUESTIONS';
export const UPDATE_DISCLOSURE_QUESTION = 'UPDATE_DISCLOSURE_QUESTION';

const getParentChildIndex = sourceName => {
  const [parent, child, thirdChild] = sourceName.split('.');
  const parentIndex = parseInt(parent, 10);
  const childIndex = parseInt(child || '-1', 10);
  const thirdChildIndex = parseInt(thirdChild || '-1', 10);
  return [parentIndex, childIndex, thirdChildIndex];
};

const updateResponse = ({ sourceName, value }, state) => {
  const [parentIndex, childIndex, thirdChildIndex] = getParentChildIndex(sourceName);

  const updatedDisclosureQuestions = [...state];
  const response = value.toString();

  if(~thirdChildIndex) {
    updatedDisclosureQuestions[parentIndex].children[childIndex].children[thirdChildIndex] = {
      ...updatedDisclosureQuestions[parentIndex].children[childIndex].children[thirdChildIndex],
      response
    };
  } else if (~childIndex) {
    updatedDisclosureQuestions[parentIndex].children[childIndex] = {
      ...updatedDisclosureQuestions[parentIndex].children[childIndex],
      response
    };
  } else {
    updatedDisclosureQuestions[parentIndex] = {
      ...updatedDisclosureQuestions[parentIndex],
      response
    };
  }

  return updatedDisclosureQuestions;
};

export default function yearlyDisclosureQuestionsReducer(state, action) {
  switch (action.type) {
    case INITIALIZE_DISCLOSURE_QUESTIONS:
      return action.payload;

    case UPDATE_DISCLOSURE_QUESTION:
      return updateResponse(action.payload, state);

    default:
      return state;
  }
}
