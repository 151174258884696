import React from 'react';
import { createRoot } from 'react-dom/client';
import { createHashHistory } from 'history';
import styled, { ThemeProvider } from 'styled-components';
import viaTheme from 'es-components-via-theme';
import { Router } from 'react-router';

import './styles/simple-grid.css';
import './styles/styles.css';
import Routes from './routes';
import { Footer, CurrentUserProvider } from './common';
import { Header } from './header';
import { SaveProgressProvider } from './save-progress';
import { AppInsightsContext, ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ClickAnalyticsPlugin } from '@microsoft/applicationinsights-clickanalytics-js';
// import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const history = createHashHistory();

const StyledDiv = styled.div({
  flex: '1 0 auto'
});

var reactPlugin = new ReactPlugin();
// *** Add the Click Analytics plug-in. ***
var clickPluginInstance = new ClickAnalyticsPlugin();
var clickPluginConfig = {
  autoCapture: true
};

const appInsights = new ApplicationInsights({
  config: {
    connectionString: `${process.env.REACT_APP_APPLICATION_INSIGHTS_CONNECTION_STRING}`,
    enableAutoRouteTracking: true,    
    // *** Add the Click Analytics plug-in. ***
    extensions: [reactPlugin, clickPluginInstance],
    extensionConfig: {
      [reactPlugin.identifier]: { history: history },
      // *** Add the Click Analytics plug-in. ***
      [clickPluginInstance.identifier]: clickPluginConfig
    }
  }
});

appInsights.loadAppInsights();

const App = () => (
  <AppInsightsContext.Provider value={reactPlugin}>
    <ThemeProvider theme={viaTheme}>
      <React.Fragment>
        <Router history={history}>
          <StyledDiv>
            <SaveProgressProvider>
              <CurrentUserProvider>
                <Header />
                <Routes />
              </CurrentUserProvider>
            </SaveProgressProvider>
          </StyledDiv>
        </Router>
        <Footer />
      </React.Fragment>
    </ThemeProvider>
  </AppInsightsContext.Provider>
);

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(<App />);
// serviceWorkerRegistration.unregister();
